import React, { useState } from "react"
import { useNavigate } from "react-router"
import "./index.css"

function Dialer() {
	const [contact, setContact] = useState("")
	const [verification, setVerification] = useState(true)
	const [token] = useState(
		"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiN2JjNThjNTUtMWFmZC00YjVhLTlhNzEtN2QxMWQ2MTFhZDQxIiwidXNlcm5hbWUiOiJncm91cGFkbWluMkBjYXJlZ2l2ZXIuY29tIiwic3RhdHVzIjoiQUNUSVZFIiwidHlwZSI6Imdyb3VwLWFkbWluIiwicm9sZXMiOlsiR1JPVVBfTUFOQUdFTUVOVF9BRE1JTiJdLCJwZXJtaXNzaW9ucyI6W119LCJpYXQiOjE2MDU3NjIwMjIsImV4cCI6MTYwODM1NDAyMn0.SBfLq-aPMco3gdqM4JAyJ8xMc_0cxv1-JZ9mgyXhFx0"
	)
	const [url] = useState(
		`https://api.baobabcircle.com/care-giver/patients/search`
	)
	const navigate = useNavigate()
	const handleSubmit = (e) => {
		e.preventDefault()
		fetch(url, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({
				msisdn: contact,
			}),
		})
			.then((response) => response.json())
			.then((result) => {
				if (result.message === "RESULTS FOUND.") {
					if (result.data.meta.count === 1) {
						navigate(`/call/${contact}`)
					}
				} else {
					setVerification(false)
					throw new Error(result.message)
				}
			})
	}
	return (
		<>
			<div className="dialer">
				<div>
					<div className="icon">
						<svg
							viewBox="0 0 128 101"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M12.7624 97.8149H5.65605L4.78589 100.385H0L6.42953 83.3809H11.9647L18.4184 100.385H13.6325L12.7624 97.8149ZM6.81627 94.1163H11.6263L9.20921 87.1995L6.81627 94.1163Z"
								fill="#F48633"
							/>
							<path
								d="M21.7295 83.4053H34.2743V87.1038H26.1528V89.9378H34.1051V93.6604H26.1528V100.385H21.7537V83.4053H21.7295Z"
								fill="#F48633"
							/>
							<path
								d="M43.7013 93.5403L37.1992 83.4053H42.1785L45.9008 89.6736L49.5748 83.4053H54.5783L48.1246 93.5403V100.385H43.7013V93.5403Z"
								fill="#F48633"
							/>
							<path
								d="M67.2201 97.8149H60.1138L59.2437 100.385H54.4336L60.8631 83.3809H66.3983L72.9004 100.385H68.0903L67.2201 97.8149ZM61.274 94.1163H66.0599L63.6428 87.1995L61.274 94.1163Z"
								fill="#F48633"
							/>
							<path
								d="M79.9482 83.4385H87.4413C91.0186 83.4385 93.0248 85.8882 93.0248 88.7462C93.0248 91.6042 90.9945 94.0539 87.4413 94.0539H82.9455V100.394H79.9482V83.4385ZM87.0546 86.0563H82.9696V91.4601H87.0546C88.5532 91.5561 89.8584 90.4514 89.9551 88.9623C90.0518 87.4733 88.9399 86.1764 87.4413 86.0803C87.2963 86.0323 87.1754 86.0323 87.0546 86.0563Z"
								fill="#003C5B"
							/>
							<path
								d="M106.488 97.152H98.3909L97.134 100.394H93.8467L100.566 83.4385H104.313L111.008 100.394H107.721L106.488 97.152ZM99.2368 94.5102H105.642L102.452 85.9362L99.2368 94.5102Z"
								fill="#003C5B"
							/>
							<path
								d="M114.924 83.4385H122.417C125.994 83.4385 128 85.8882 128 88.7462C128 91.6042 125.97 94.0539 122.417 94.0539H117.921V100.394H114.924V83.4385ZM122.03 86.0563H117.945V91.4601H122.03C123.529 91.5561 124.834 90.4514 124.931 88.9623C125.027 87.4733 123.916 86.1764 122.417 86.0803C122.272 86.0323 122.151 86.0323 122.03 86.0563Z"
								fill="#003C5B"
							/>
							<g filter="url(#filter0_d_308_9234)">
								<path
									d="M53.6037 56.3381C53.6037 56.3381 79.7869 60.9914 94.6169 47.5957C109.447 34.2 107.318 7.90209 107.318 7.90209C107.318 7.90209 81.0641 3.24885 66.305 16.6446C51.5459 30.0403 53.6037 56.3381 53.6037 56.3381Z"
									fill="white"
								/>
							</g>
							<g filter="url(#filter1_d_308_9234)">
								<path
									d="M74.8204 56.3381C74.8204 56.3381 48.6373 60.9914 33.8072 47.5957C18.9772 34.2 21.1059 7.90209 21.1059 7.90209C21.1059 7.90209 47.2891 3.24885 62.1191 16.6446C76.9491 30.0403 74.8204 56.3381 74.8204 56.3381Z"
									fill="#F48633"
								/>
							</g>
							<path
								opacity="0.52"
								d="M53.6037 56.3381C53.6037 56.3381 79.7869 60.9914 94.6169 47.5957C109.447 34.2 107.318 7.90209 107.318 7.90209C107.318 7.90209 81.0641 3.24885 66.305 16.6446C51.5459 30.0403 53.6037 56.3381 53.6037 56.3381Z"
								fill="#003C5B"
							/>
							<defs>
								<filter
									id="filter0_d_308_9234"
									x="46.5059"
									y="0.120117"
									width="67.918"
									height="64"
									filterUnits="userSpaceOnUse"
									colorInterpolationFilters="sRGB"
								>
									<feFlood
										floodOpacity="0"
										result="BackgroundImageFix"
									/>
									<feColorMatrix
										in="SourceAlpha"
										type="matrix"
										values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
										result="hardAlpha"
									/>
									<feOffset />
									<feGaussianBlur stdDeviation="3.5" />
									<feColorMatrix
										type="matrix"
										values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
									/>
									<feBlend
										mode="normal"
										in2="BackgroundImageFix"
										result="effect1_dropShadow_308_9234"
									/>
									<feBlend
										mode="normal"
										in="SourceGraphic"
										in2="effect1_dropShadow_308_9234"
										result="shape"
									/>
								</filter>
								<filter
									id="filter1_d_308_9234"
									x="17"
									y="3.12012"
									width="61.9268"
									height="58"
									filterUnits="userSpaceOnUse"
									colorInterpolationFilters="sRGB"
								>
									<feFlood
										floodOpacity="0"
										result="BackgroundImageFix"
									/>
									<feColorMatrix
										in="SourceAlpha"
										type="matrix"
										values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
										result="hardAlpha"
									/>
									<feOffset />
									<feGaussianBlur stdDeviation="2" />
									<feColorMatrix
										type="matrix"
										values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
									/>
									<feBlend
										mode="normal"
										in2="BackgroundImageFix"
										result="effect1_dropShadow_308_9234"
									/>
									<feBlend
										mode="normal"
										in="SourceGraphic"
										in2="effect1_dropShadow_308_9234"
										result="shape"
									/>
								</filter>
							</defs>
						</svg>
					</div>
				</div>
				<form onSubmit={handleSubmit} className="form">
					<div className="inputs_container">
						<label className="input_label" htmlFor="contact">
							Enter Phone Number
						</label>
						<input
							className="input_field"
							type="tel"
							value={contact}
							id="contact"
							placeholder="254717700443"
							onChange={(e) => setContact(e.target.value)}
						/>
					</div>
					{!verification && (
						<p className={verification ? "helper" : "error"}>
							{verification
								? "Enter registered patient phone number"
								: "Number is not registered on our system"}
						</p>
					)}
					{contact.length > 9 && (
						<div className="dial_controls">
							<button type="submit" className="button">
								Call Patient
							</button>
						</div>
					)}
				</form>
			</div>
			<div className="background_texture">
				<div className="texture_overlay"></div>
				<svg
					className="texture_graphic"
					viewBox="0 0 710 1040"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g opacity="0.15">
						<path
							d="M21.2501 19.7311C21.2501 19.7311 11.8726 21.4191 6.62122 16.5428C1.36983 11.6665 2.02626 2.28902 2.02626 2.28902C2.02626 2.28902 11.4037 0.601072 16.6551 5.38359C21.9065 10.1661 21.2501 19.7311 21.2501 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 19.7311C13.6542 19.7311 23.0317 21.4191 28.2831 16.5428C33.5345 11.6665 32.7843 2.28902 32.7843 2.28902C32.7843 2.28902 23.4068 0.601072 18.1554 5.38359C12.904 10.1661 13.6542 19.7311 13.6542 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 19.7311C66.2618 19.7311 56.8843 21.4191 51.6329 16.5428C46.3816 11.6665 47.038 2.28902 47.038 2.28902C47.038 2.28902 56.4155 0.601072 61.6668 5.38359C66.9182 10.1661 66.2618 19.7311 66.2618 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 19.7311C58.666 19.7311 68.0434 21.4191 73.2948 16.5428C78.5462 11.6665 77.796 2.28902 77.796 2.28902C77.796 2.28902 68.4185 0.601072 63.1671 5.38359C57.9158 10.1661 58.666 19.7311 58.666 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 19.7311C111.274 19.7311 101.896 21.4191 96.6447 16.5428C91.3933 11.6665 92.0497 2.28902 92.0497 2.28902C92.0497 2.28902 101.427 0.601072 106.679 5.38359C111.93 10.1661 111.274 19.7311 111.274 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 19.7311C103.678 19.7311 113.055 21.4191 118.307 16.5428C123.558 11.6665 122.808 2.28902 122.808 2.28902C122.808 2.28902 113.43 0.601072 108.179 5.38359C102.927 10.1661 103.678 19.7311 103.678 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 19.7311C156.285 19.7311 146.908 21.4191 141.656 16.5428C136.405 11.6665 137.061 2.28902 137.061 2.28902C137.061 2.28902 146.439 0.601072 151.69 5.38359C156.942 10.1661 156.285 19.7311 156.285 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 19.7311C148.689 19.7311 158.067 21.4191 163.318 16.5428C168.57 11.6665 167.819 2.28902 167.819 2.28902C167.819 2.28902 158.442 0.601072 153.191 5.38359C147.939 10.1661 148.689 19.7311 148.689 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 19.7311C201.297 19.7311 191.919 21.4191 186.668 16.5428C181.417 11.6665 182.073 2.28902 182.073 2.28902C182.073 2.28902 191.451 0.601072 196.702 5.38359C201.953 10.1661 201.297 19.7311 201.297 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 19.7311C193.701 19.7311 203.079 21.4191 208.33 16.5428C213.581 11.6665 212.831 2.28902 212.831 2.28902C212.831 2.28902 203.454 0.601072 198.202 5.38359C192.951 10.1661 193.701 19.7311 193.701 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 19.7311C246.309 19.7311 236.931 21.4191 231.68 16.5428C226.428 11.6665 227.085 2.28902 227.085 2.28902C227.085 2.28902 236.462 0.601072 241.714 5.38359C246.965 10.1661 246.309 19.7311 246.309 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 19.7311C238.713 19.7311 248.09 21.4191 253.342 16.5428C258.593 11.6665 257.843 2.28902 257.843 2.28902C257.843 2.28902 248.465 0.601072 243.214 5.38359C237.963 10.1661 238.713 19.7311 238.713 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 19.7311C291.322 19.7311 281.945 21.4191 276.693 16.5428C271.442 11.6665 272.099 2.28902 272.099 2.28902C272.099 2.28902 281.476 0.601072 286.727 5.38359C291.979 10.1661 291.322 19.7311 291.322 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 19.7311C283.726 19.7311 293.104 21.4191 298.355 16.5428C303.607 11.6665 302.857 2.28902 302.857 2.28902C302.857 2.28902 293.479 0.601072 288.228 5.38359C282.976 10.1661 283.726 19.7311 283.726 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 19.7311C336.334 19.7311 326.957 21.4191 321.705 16.5428C316.454 11.6665 317.11 2.28902 317.11 2.28902C317.11 2.28902 326.488 0.601072 331.739 5.38359C336.99 10.1661 336.334 19.7311 336.334 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 19.7311C328.738 19.7311 338.116 21.4191 343.367 16.5428C348.618 11.6665 347.868 2.28902 347.868 2.28902C347.868 2.28902 338.491 0.601072 333.239 5.38359C327.988 10.1661 328.738 19.7311 328.738 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 19.7311C381.346 19.7311 371.968 21.4191 366.717 16.5428C361.466 11.6665 362.122 2.28902 362.122 2.28902C362.122 2.28902 371.499 0.601072 376.751 5.38359C382.002 10.1661 381.346 19.7311 381.346 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 19.7311C373.75 19.7311 383.127 21.4191 388.379 16.5428C393.63 11.6665 392.88 2.28902 392.88 2.28902C392.88 2.28902 383.503 0.601072 378.251 5.38359C373 10.1661 373.75 19.7311 373.75 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 19.7311C426.358 19.7311 416.98 21.4191 411.729 16.5428C406.477 11.6665 407.134 2.28902 407.134 2.28902C407.134 2.28902 416.511 0.601072 421.763 5.38359C427.014 10.1661 426.358 19.7311 426.358 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 19.7311C418.762 19.7311 428.139 21.4191 433.391 16.5428C438.642 11.6665 437.892 2.28902 437.892 2.28902C437.892 2.28902 428.514 0.601072 423.263 5.38359C418.011 10.1661 418.762 19.7311 418.762 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 19.7311C471.369 19.7311 461.992 21.4191 456.74 16.5428C451.489 11.6665 452.145 2.28902 452.145 2.28902C452.145 2.28902 461.523 0.601072 466.774 5.38359C472.026 10.1661 471.369 19.7311 471.369 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 19.7311C463.773 19.7311 473.151 21.4191 478.402 16.5428C483.654 11.6665 482.903 2.28902 482.903 2.28902C482.903 2.28902 473.526 0.601072 468.275 5.38359C463.023 10.1661 463.773 19.7311 463.773 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 19.7311C516.381 19.7311 507.003 21.4191 501.752 16.5428C496.501 11.6665 497.157 2.28902 497.157 2.28902C497.157 2.28902 506.535 0.601072 511.786 5.38359C517.037 10.1661 516.381 19.7311 516.381 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 19.7311C508.785 19.7311 518.163 21.4191 523.414 16.5428C528.665 11.6665 527.915 2.28902 527.915 2.28902C527.915 2.28902 518.538 0.601072 513.286 5.38359C508.035 10.1661 508.785 19.7311 508.785 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 19.7311C561.393 19.7311 552.015 21.4191 546.764 16.5428C541.512 11.6665 542.169 2.28902 542.169 2.28902C542.169 2.28902 551.546 0.601072 556.798 5.38359C562.049 10.1661 561.393 19.7311 561.393 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 19.7311C553.797 19.7311 563.174 21.4191 568.426 16.5428C573.677 11.6665 572.927 2.28902 572.927 2.28902C572.927 2.28902 563.549 0.601072 558.298 5.38359C553.047 10.1661 553.797 19.7311 553.797 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 19.7311C606.404 19.7311 597.027 21.4191 591.776 16.5428C586.524 11.6665 587.181 2.28902 587.181 2.28902C587.181 2.28902 596.558 0.601072 601.809 5.38359C607.061 10.1661 606.404 19.7311 606.404 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 19.7311C598.809 19.7311 608.186 21.4191 613.437 16.5428C618.689 11.6665 617.939 2.28902 617.939 2.28902C617.939 2.28902 608.561 0.601072 603.31 5.38359C598.058 10.1661 598.809 19.7311 598.809 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 19.7311C651.416 19.7311 642.039 21.4191 636.787 16.5428C631.536 11.6665 632.192 2.28902 632.192 2.28902C632.192 2.28902 641.57 0.601072 646.821 5.38359C652.073 10.1661 651.416 19.7311 651.416 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 19.7311C643.82 19.7311 653.198 21.4191 658.449 16.5428C663.701 11.6665 662.95 2.28902 662.95 2.28902C662.95 2.28902 653.573 0.601072 648.321 5.38359C643.07 10.1661 643.82 19.7311 643.82 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 19.7311C696.428 19.7311 687.05 21.4191 681.799 16.5428C676.548 11.6665 677.204 2.28902 677.204 2.28902C677.204 2.28902 686.581 0.601072 691.833 5.38359C697.084 10.1661 696.428 19.7311 696.428 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 19.7311C688.832 19.7311 698.209 21.4191 703.461 16.5428C708.712 11.6665 707.962 2.28902 707.962 2.28902C707.962 2.28902 698.585 0.601072 693.333 5.38359C688.082 10.1661 688.832 19.7311 688.832 19.7311Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 52.5524C21.2501 52.5524 11.8726 54.2404 6.62122 49.3641C1.36983 44.4878 2.02626 35.1103 2.02626 35.1103C2.02626 35.1103 11.4037 33.4224 16.6551 38.2049C21.9065 42.9874 21.2501 52.5524 21.2501 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 52.5524C13.6542 52.5524 23.0317 54.2404 28.2831 49.3641C33.5345 44.4878 32.7843 35.1103 32.7843 35.1103C32.7843 35.1103 23.4068 33.4224 18.1554 38.2049C12.904 42.9874 13.6542 52.5524 13.6542 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 52.5524C66.2618 52.5524 56.8843 54.2404 51.6329 49.3641C46.3816 44.4878 47.038 35.1103 47.038 35.1103C47.038 35.1103 56.4155 33.4224 61.6668 38.2049C66.9182 42.9874 66.2618 52.5524 66.2618 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 52.5524C58.666 52.5524 68.0434 54.2404 73.2948 49.3641C78.5462 44.4878 77.796 35.1103 77.796 35.1103C77.796 35.1103 68.4185 33.4224 63.1671 38.2049C57.9158 42.9874 58.666 52.5524 58.666 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 52.5524C111.274 52.5524 101.896 54.2404 96.6447 49.3641C91.3933 44.4878 92.0497 35.1103 92.0497 35.1103C92.0497 35.1103 101.427 33.4224 106.679 38.2049C111.93 42.9874 111.274 52.5524 111.274 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 52.5524C103.678 52.5524 113.055 54.2404 118.307 49.3641C123.558 44.4878 122.808 35.1103 122.808 35.1103C122.808 35.1103 113.43 33.4224 108.179 38.2049C102.927 42.9874 103.678 52.5524 103.678 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 52.5524C156.285 52.5524 146.908 54.2404 141.656 49.3641C136.405 44.4878 137.061 35.1103 137.061 35.1103C137.061 35.1103 146.439 33.4224 151.69 38.2049C156.942 42.9874 156.285 52.5524 156.285 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 52.5524C148.689 52.5524 158.067 54.2404 163.318 49.3641C168.57 44.4878 167.819 35.1103 167.819 35.1103C167.819 35.1103 158.442 33.4224 153.191 38.2049C147.939 42.9874 148.689 52.5524 148.689 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 52.5524C201.297 52.5524 191.919 54.2404 186.668 49.3641C181.417 44.4878 182.073 35.1103 182.073 35.1103C182.073 35.1103 191.451 33.4224 196.702 38.2049C201.953 42.9874 201.297 52.5524 201.297 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 52.5524C193.701 52.5524 203.079 54.2404 208.33 49.3641C213.581 44.4878 212.831 35.1103 212.831 35.1103C212.831 35.1103 203.454 33.4224 198.202 38.2049C192.951 42.9874 193.701 52.5524 193.701 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 52.5524C246.309 52.5524 236.931 54.2404 231.68 49.3641C226.428 44.4878 227.085 35.1103 227.085 35.1103C227.085 35.1103 236.462 33.4224 241.714 38.2049C246.965 42.9874 246.309 52.5524 246.309 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 52.5524C238.713 52.5524 248.09 54.2404 253.342 49.3641C258.593 44.4878 257.843 35.1103 257.843 35.1103C257.843 35.1103 248.465 33.4224 243.214 38.2049C237.963 42.9874 238.713 52.5524 238.713 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 52.5524C291.322 52.5524 281.945 54.2404 276.693 49.3641C271.442 44.4878 272.099 35.1103 272.099 35.1103C272.099 35.1103 281.476 33.4224 286.727 38.2049C291.979 42.9874 291.322 52.5524 291.322 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 52.5524C283.726 52.5524 293.104 54.2404 298.355 49.3641C303.607 44.4878 302.857 35.1103 302.857 35.1103C302.857 35.1103 293.479 33.4224 288.228 38.2049C282.976 42.9874 283.726 52.5524 283.726 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 52.5524C336.334 52.5524 326.957 54.2404 321.705 49.3641C316.454 44.4878 317.11 35.1103 317.11 35.1103C317.11 35.1103 326.488 33.4224 331.739 38.2049C336.99 42.9874 336.334 52.5524 336.334 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 52.5524C328.738 52.5524 338.116 54.2404 343.367 49.3641C348.618 44.4878 347.868 35.1103 347.868 35.1103C347.868 35.1103 338.491 33.4224 333.239 38.2049C327.988 42.9874 328.738 52.5524 328.738 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 52.5524C381.346 52.5524 371.968 54.2404 366.717 49.3641C361.466 44.4878 362.122 35.1103 362.122 35.1103C362.122 35.1103 371.499 33.4224 376.751 38.2049C382.002 42.9874 381.346 52.5524 381.346 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 52.5524C373.75 52.5524 383.127 54.2404 388.379 49.3641C393.63 44.4878 392.88 35.1103 392.88 35.1103C392.88 35.1103 383.503 33.4224 378.251 38.2049C373 42.9874 373.75 52.5524 373.75 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 52.5524C426.358 52.5524 416.98 54.2404 411.729 49.3641C406.477 44.4878 407.134 35.1103 407.134 35.1103C407.134 35.1103 416.511 33.4224 421.763 38.2049C427.014 42.9874 426.358 52.5524 426.358 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 52.5524C418.762 52.5524 428.139 54.2404 433.391 49.3641C438.642 44.4878 437.892 35.1103 437.892 35.1103C437.892 35.1103 428.514 33.4224 423.263 38.2049C418.011 42.9874 418.762 52.5524 418.762 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 52.5524C471.369 52.5524 461.992 54.2404 456.74 49.3641C451.489 44.4878 452.145 35.1103 452.145 35.1103C452.145 35.1103 461.523 33.4224 466.774 38.2049C472.026 42.9874 471.369 52.5524 471.369 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 52.5524C463.773 52.5524 473.151 54.2404 478.402 49.3641C483.654 44.4878 482.903 35.1103 482.903 35.1103C482.903 35.1103 473.526 33.4224 468.275 38.2049C463.023 42.9874 463.773 52.5524 463.773 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 52.5524C516.381 52.5524 507.003 54.2404 501.752 49.3641C496.501 44.4878 497.157 35.1103 497.157 35.1103C497.157 35.1103 506.535 33.4224 511.786 38.2049C517.037 42.9874 516.381 52.5524 516.381 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 52.5524C508.785 52.5524 518.163 54.2404 523.414 49.3641C528.665 44.4878 527.915 35.1103 527.915 35.1103C527.915 35.1103 518.538 33.4224 513.286 38.2049C508.035 42.9874 508.785 52.5524 508.785 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 52.5524C561.393 52.5524 552.015 54.2404 546.764 49.3641C541.512 44.4878 542.169 35.1103 542.169 35.1103C542.169 35.1103 551.546 33.4224 556.798 38.2049C562.049 42.9874 561.393 52.5524 561.393 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 52.5524C553.797 52.5524 563.174 54.2404 568.426 49.3641C573.677 44.4878 572.927 35.1103 572.927 35.1103C572.927 35.1103 563.549 33.4224 558.298 38.2049C553.047 42.9874 553.797 52.5524 553.797 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 52.5524C606.404 52.5524 597.027 54.2404 591.776 49.3641C586.524 44.4878 587.181 35.1103 587.181 35.1103C587.181 35.1103 596.558 33.4224 601.809 38.2049C607.061 42.9874 606.404 52.5524 606.404 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 52.5524C598.809 52.5524 608.186 54.2404 613.437 49.3641C618.689 44.4878 617.939 35.1103 617.939 35.1103C617.939 35.1103 608.561 33.4224 603.31 38.2049C598.058 42.9874 598.809 52.5524 598.809 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 52.5524C651.416 52.5524 642.039 54.2404 636.787 49.3641C631.536 44.4878 632.192 35.1103 632.192 35.1103C632.192 35.1103 641.57 33.4224 646.821 38.2049C652.073 42.9874 651.416 52.5524 651.416 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 52.5524C643.82 52.5524 653.198 54.2404 658.449 49.3641C663.701 44.4878 662.95 35.1103 662.95 35.1103C662.95 35.1103 653.573 33.4224 648.321 38.2049C643.07 42.9874 643.82 52.5524 643.82 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 52.5524C696.428 52.5524 687.05 54.2404 681.799 49.3641C676.548 44.4878 677.204 35.1103 677.204 35.1103C677.204 35.1103 686.581 33.4224 691.833 38.2049C697.084 42.9874 696.428 52.5524 696.428 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 52.5524C688.832 52.5524 698.209 54.2404 703.461 49.3641C708.712 44.4878 707.962 35.1103 707.962 35.1103C707.962 35.1103 698.585 33.4224 693.333 38.2049C688.082 42.9874 688.832 52.5524 688.832 52.5524Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 85.3737C21.2501 85.3737 11.8726 87.0617 6.62122 82.1854C1.36983 77.3091 2.02626 67.9316 2.02626 67.9316C2.02626 67.9316 11.4037 66.2436 16.6551 71.0262C21.9065 75.8087 21.2501 85.3737 21.2501 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 85.3737C13.6542 85.3737 23.0317 87.0617 28.2831 82.1854C33.5345 77.3091 32.7843 67.9316 32.7843 67.9316C32.7843 67.9316 23.4068 66.2436 18.1554 71.0262C12.904 75.8087 13.6542 85.3737 13.6542 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 85.3737C66.2618 85.3737 56.8843 87.0617 51.6329 82.1854C46.3816 77.3091 47.038 67.9316 47.038 67.9316C47.038 67.9316 56.4155 66.2436 61.6668 71.0262C66.9182 75.8087 66.2618 85.3737 66.2618 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 85.3737C58.666 85.3737 68.0434 87.0617 73.2948 82.1854C78.5462 77.3091 77.796 67.9316 77.796 67.9316C77.796 67.9316 68.4185 66.2436 63.1671 71.0262C57.9158 75.8087 58.666 85.3737 58.666 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 85.3737C111.274 85.3737 101.896 87.0617 96.6447 82.1854C91.3933 77.3091 92.0497 67.9316 92.0497 67.9316C92.0497 67.9316 101.427 66.2436 106.679 71.0262C111.93 75.8087 111.274 85.3737 111.274 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 85.3737C103.678 85.3737 113.055 87.0617 118.307 82.1854C123.558 77.3091 122.808 67.9316 122.808 67.9316C122.808 67.9316 113.43 66.2436 108.179 71.0262C102.927 75.8087 103.678 85.3737 103.678 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 85.3737C156.285 85.3737 146.908 87.0617 141.656 82.1854C136.405 77.3091 137.061 67.9316 137.061 67.9316C137.061 67.9316 146.439 66.2436 151.69 71.0262C156.942 75.8087 156.285 85.3737 156.285 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 85.3737C148.689 85.3737 158.067 87.0617 163.318 82.1854C168.57 77.3091 167.819 67.9316 167.819 67.9316C167.819 67.9316 158.442 66.2436 153.191 71.0262C147.939 75.8087 148.689 85.3737 148.689 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 85.3737C201.297 85.3737 191.919 87.0617 186.668 82.1854C181.417 77.3091 182.073 67.9316 182.073 67.9316C182.073 67.9316 191.451 66.2436 196.702 71.0262C201.953 75.8087 201.297 85.3737 201.297 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 85.3737C193.701 85.3737 203.079 87.0617 208.33 82.1854C213.581 77.3091 212.831 67.9316 212.831 67.9316C212.831 67.9316 203.454 66.2436 198.202 71.0262C192.951 75.8087 193.701 85.3737 193.701 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 85.3737C246.309 85.3737 236.931 87.0617 231.68 82.1854C226.428 77.3091 227.085 67.9316 227.085 67.9316C227.085 67.9316 236.462 66.2436 241.714 71.0262C246.965 75.8087 246.309 85.3737 246.309 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 85.3737C238.713 85.3737 248.09 87.0617 253.342 82.1854C258.593 77.3091 257.843 67.9316 257.843 67.9316C257.843 67.9316 248.465 66.2436 243.214 71.0262C237.963 75.8087 238.713 85.3737 238.713 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 85.3737C291.322 85.3737 281.945 87.0617 276.693 82.1854C271.442 77.3091 272.099 67.9316 272.099 67.9316C272.099 67.9316 281.476 66.2436 286.727 71.0262C291.979 75.8087 291.322 85.3737 291.322 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 85.3737C283.726 85.3737 293.104 87.0617 298.355 82.1854C303.607 77.3091 302.857 67.9316 302.857 67.9316C302.857 67.9316 293.479 66.2436 288.228 71.0262C282.976 75.8087 283.726 85.3737 283.726 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 85.3737C336.334 85.3737 326.957 87.0617 321.705 82.1854C316.454 77.3091 317.11 67.9316 317.11 67.9316C317.11 67.9316 326.488 66.2436 331.739 71.0262C336.99 75.8087 336.334 85.3737 336.334 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 85.3737C328.738 85.3737 338.116 87.0617 343.367 82.1854C348.618 77.3091 347.868 67.9316 347.868 67.9316C347.868 67.9316 338.491 66.2436 333.239 71.0262C327.988 75.8087 328.738 85.3737 328.738 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 85.3737C381.346 85.3737 371.968 87.0617 366.717 82.1854C361.466 77.3091 362.122 67.9316 362.122 67.9316C362.122 67.9316 371.499 66.2436 376.751 71.0262C382.002 75.8087 381.346 85.3737 381.346 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 85.3737C373.75 85.3737 383.127 87.0617 388.379 82.1854C393.63 77.3091 392.88 67.9316 392.88 67.9316C392.88 67.9316 383.503 66.2436 378.251 71.0262C373 75.8087 373.75 85.3737 373.75 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 85.3737C426.358 85.3737 416.98 87.0617 411.729 82.1854C406.477 77.3091 407.134 67.9316 407.134 67.9316C407.134 67.9316 416.511 66.2436 421.763 71.0262C427.014 75.8087 426.358 85.3737 426.358 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 85.3737C418.762 85.3737 428.139 87.0617 433.391 82.1854C438.642 77.3091 437.892 67.9316 437.892 67.9316C437.892 67.9316 428.514 66.2436 423.263 71.0262C418.011 75.8087 418.762 85.3737 418.762 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 85.3737C471.369 85.3737 461.992 87.0617 456.74 82.1854C451.489 77.3091 452.145 67.9316 452.145 67.9316C452.145 67.9316 461.523 66.2436 466.774 71.0262C472.026 75.8087 471.369 85.3737 471.369 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 85.3737C463.773 85.3737 473.151 87.0617 478.402 82.1854C483.654 77.3091 482.903 67.9316 482.903 67.9316C482.903 67.9316 473.526 66.2436 468.275 71.0262C463.023 75.8087 463.773 85.3737 463.773 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 85.3737C516.381 85.3737 507.003 87.0617 501.752 82.1854C496.501 77.3091 497.157 67.9316 497.157 67.9316C497.157 67.9316 506.535 66.2436 511.786 71.0262C517.037 75.8087 516.381 85.3737 516.381 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 85.3737C508.785 85.3737 518.163 87.0617 523.414 82.1854C528.665 77.3091 527.915 67.9316 527.915 67.9316C527.915 67.9316 518.538 66.2436 513.286 71.0262C508.035 75.8087 508.785 85.3737 508.785 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 85.3737C561.393 85.3737 552.015 87.0617 546.764 82.1854C541.512 77.3091 542.169 67.9316 542.169 67.9316C542.169 67.9316 551.546 66.2436 556.798 71.0262C562.049 75.8087 561.393 85.3737 561.393 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 85.3737C553.797 85.3737 563.174 87.0617 568.426 82.1854C573.677 77.3091 572.927 67.9316 572.927 67.9316C572.927 67.9316 563.549 66.2436 558.298 71.0262C553.047 75.8087 553.797 85.3737 553.797 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 85.3737C606.404 85.3737 597.027 87.0617 591.776 82.1854C586.524 77.3091 587.181 67.9316 587.181 67.9316C587.181 67.9316 596.558 66.2436 601.809 71.0262C607.061 75.8087 606.404 85.3737 606.404 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 85.3737C598.809 85.3737 608.186 87.0617 613.437 82.1854C618.689 77.3091 617.939 67.9316 617.939 67.9316C617.939 67.9316 608.561 66.2436 603.31 71.0262C598.058 75.8087 598.809 85.3737 598.809 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 85.3737C651.416 85.3737 642.039 87.0617 636.787 82.1854C631.536 77.3091 632.192 67.9316 632.192 67.9316C632.192 67.9316 641.57 66.2436 646.821 71.0262C652.073 75.8087 651.416 85.3737 651.416 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 85.3737C643.82 85.3737 653.198 87.0617 658.449 82.1854C663.701 77.3091 662.95 67.9316 662.95 67.9316C662.95 67.9316 653.573 66.2436 648.321 71.0262C643.07 75.8087 643.82 85.3737 643.82 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 85.3737C696.428 85.3737 687.05 87.0617 681.799 82.1854C676.548 77.3091 677.204 67.9316 677.204 67.9316C677.204 67.9316 686.581 66.2436 691.833 71.0262C697.084 75.8087 696.428 85.3737 696.428 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 85.3737C688.832 85.3737 698.209 87.0617 703.461 82.1854C708.712 77.3091 707.962 67.9316 707.962 67.9316C707.962 67.9316 698.585 66.2436 693.333 71.0262C688.082 75.8087 688.832 85.3737 688.832 85.3737Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 118.195C21.2501 118.195 11.8726 119.883 6.62122 115.007C1.36983 110.13 2.02626 100.753 2.02626 100.753C2.02626 100.753 11.4037 99.0649 16.6551 103.847C21.9065 108.63 21.2501 118.195 21.2501 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 118.195C13.6542 118.195 23.0317 119.883 28.2831 115.007C33.5345 110.13 32.7843 100.753 32.7843 100.753C32.7843 100.753 23.4068 99.0649 18.1554 103.847C12.904 108.63 13.6542 118.195 13.6542 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 118.195C66.2618 118.195 56.8843 119.883 51.6329 115.007C46.3816 110.13 47.038 100.753 47.038 100.753C47.038 100.753 56.4155 99.0649 61.6668 103.847C66.9182 108.63 66.2618 118.195 66.2618 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 118.195C58.666 118.195 68.0434 119.883 73.2948 115.007C78.5462 110.13 77.796 100.753 77.796 100.753C77.796 100.753 68.4185 99.0649 63.1671 103.847C57.9158 108.63 58.666 118.195 58.666 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 118.195C111.274 118.195 101.896 119.883 96.6447 115.007C91.3933 110.13 92.0497 100.753 92.0497 100.753C92.0497 100.753 101.427 99.0649 106.679 103.847C111.93 108.63 111.274 118.195 111.274 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 118.195C103.678 118.195 113.055 119.883 118.307 115.007C123.558 110.13 122.808 100.753 122.808 100.753C122.808 100.753 113.43 99.0649 108.179 103.847C102.927 108.63 103.678 118.195 103.678 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 118.195C156.285 118.195 146.908 119.883 141.656 115.007C136.405 110.13 137.061 100.753 137.061 100.753C137.061 100.753 146.439 99.0649 151.69 103.847C156.942 108.63 156.285 118.195 156.285 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 118.195C148.689 118.195 158.067 119.883 163.318 115.007C168.57 110.13 167.819 100.753 167.819 100.753C167.819 100.753 158.442 99.0649 153.191 103.847C147.939 108.63 148.689 118.195 148.689 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 118.195C201.297 118.195 191.919 119.883 186.668 115.007C181.417 110.13 182.073 100.753 182.073 100.753C182.073 100.753 191.451 99.0649 196.702 103.847C201.953 108.63 201.297 118.195 201.297 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 118.195C193.701 118.195 203.079 119.883 208.33 115.007C213.581 110.13 212.831 100.753 212.831 100.753C212.831 100.753 203.454 99.0649 198.202 103.847C192.951 108.63 193.701 118.195 193.701 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 118.195C246.309 118.195 236.931 119.883 231.68 115.007C226.428 110.13 227.085 100.753 227.085 100.753C227.085 100.753 236.462 99.0649 241.714 103.847C246.965 108.63 246.309 118.195 246.309 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 118.195C238.713 118.195 248.09 119.883 253.342 115.007C258.593 110.13 257.843 100.753 257.843 100.753C257.843 100.753 248.465 99.0649 243.214 103.847C237.963 108.63 238.713 118.195 238.713 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 118.195C291.322 118.195 281.945 119.883 276.693 115.007C271.442 110.13 272.099 100.753 272.099 100.753C272.099 100.753 281.476 99.0649 286.727 103.847C291.979 108.63 291.322 118.195 291.322 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 118.195C283.726 118.195 293.104 119.883 298.355 115.007C303.607 110.13 302.857 100.753 302.857 100.753C302.857 100.753 293.479 99.0649 288.228 103.847C282.976 108.63 283.726 118.195 283.726 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 118.195C336.334 118.195 326.957 119.883 321.705 115.007C316.454 110.13 317.11 100.753 317.11 100.753C317.11 100.753 326.488 99.0649 331.739 103.847C336.99 108.63 336.334 118.195 336.334 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 118.195C328.738 118.195 338.116 119.883 343.367 115.007C348.618 110.13 347.868 100.753 347.868 100.753C347.868 100.753 338.491 99.0649 333.239 103.847C327.988 108.63 328.738 118.195 328.738 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 118.195C381.346 118.195 371.968 119.883 366.717 115.007C361.466 110.13 362.122 100.753 362.122 100.753C362.122 100.753 371.499 99.0649 376.751 103.847C382.002 108.63 381.346 118.195 381.346 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 118.195C373.75 118.195 383.127 119.883 388.379 115.007C393.63 110.13 392.88 100.753 392.88 100.753C392.88 100.753 383.503 99.0649 378.251 103.847C373 108.63 373.75 118.195 373.75 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 118.195C426.358 118.195 416.98 119.883 411.729 115.007C406.477 110.13 407.134 100.753 407.134 100.753C407.134 100.753 416.511 99.0649 421.763 103.847C427.014 108.63 426.358 118.195 426.358 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 118.195C418.762 118.195 428.139 119.883 433.391 115.007C438.642 110.13 437.892 100.753 437.892 100.753C437.892 100.753 428.514 99.0649 423.263 103.847C418.011 108.63 418.762 118.195 418.762 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 118.195C471.369 118.195 461.992 119.883 456.74 115.007C451.489 110.13 452.145 100.753 452.145 100.753C452.145 100.753 461.523 99.0649 466.774 103.847C472.026 108.63 471.369 118.195 471.369 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 118.195C463.773 118.195 473.151 119.883 478.402 115.007C483.654 110.13 482.903 100.753 482.903 100.753C482.903 100.753 473.526 99.0649 468.275 103.847C463.023 108.63 463.773 118.195 463.773 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 118.195C516.381 118.195 507.003 119.883 501.752 115.007C496.501 110.13 497.157 100.753 497.157 100.753C497.157 100.753 506.535 99.0649 511.786 103.847C517.037 108.63 516.381 118.195 516.381 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 118.195C508.785 118.195 518.163 119.883 523.414 115.007C528.665 110.13 527.915 100.753 527.915 100.753C527.915 100.753 518.538 99.0649 513.286 103.847C508.035 108.63 508.785 118.195 508.785 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 118.195C561.393 118.195 552.015 119.883 546.764 115.007C541.512 110.13 542.169 100.753 542.169 100.753C542.169 100.753 551.546 99.0649 556.798 103.847C562.049 108.63 561.393 118.195 561.393 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 118.195C553.797 118.195 563.174 119.883 568.426 115.007C573.677 110.13 572.927 100.753 572.927 100.753C572.927 100.753 563.549 99.0649 558.298 103.847C553.047 108.63 553.797 118.195 553.797 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 118.195C606.404 118.195 597.027 119.883 591.776 115.007C586.524 110.13 587.181 100.753 587.181 100.753C587.181 100.753 596.558 99.0649 601.809 103.847C607.061 108.63 606.404 118.195 606.404 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 118.195C598.809 118.195 608.186 119.883 613.437 115.007C618.689 110.13 617.939 100.753 617.939 100.753C617.939 100.753 608.561 99.0649 603.31 103.847C598.058 108.63 598.809 118.195 598.809 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 118.195C651.416 118.195 642.039 119.883 636.787 115.007C631.536 110.13 632.192 100.753 632.192 100.753C632.192 100.753 641.57 99.0649 646.821 103.847C652.073 108.63 651.416 118.195 651.416 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 118.195C643.82 118.195 653.198 119.883 658.449 115.007C663.701 110.13 662.95 100.753 662.95 100.753C662.95 100.753 653.573 99.0649 648.321 103.847C643.07 108.63 643.82 118.195 643.82 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 118.195C696.428 118.195 687.05 119.883 681.799 115.007C676.548 110.13 677.204 100.753 677.204 100.753C677.204 100.753 686.581 99.0649 691.833 103.847C697.084 108.63 696.428 118.195 696.428 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 118.195C688.832 118.195 698.209 119.883 703.461 115.007C708.712 110.13 707.962 100.753 707.962 100.753C707.962 100.753 698.585 99.0649 693.333 103.847C688.082 108.63 688.832 118.195 688.832 118.195Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 151.016C21.2501 151.016 11.8726 152.704 6.62122 147.828C1.36983 142.952 2.02626 133.574 2.02626 133.574C2.02626 133.574 11.4037 131.886 16.6551 136.669C21.9065 141.451 21.2501 151.016 21.2501 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 151.016C13.6542 151.016 23.0317 152.704 28.2831 147.828C33.5345 142.952 32.7843 133.574 32.7843 133.574C32.7843 133.574 23.4068 131.886 18.1554 136.669C12.904 141.451 13.6542 151.016 13.6542 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 151.016C66.2618 151.016 56.8843 152.704 51.6329 147.828C46.3816 142.952 47.038 133.574 47.038 133.574C47.038 133.574 56.4155 131.886 61.6668 136.669C66.9182 141.451 66.2618 151.016 66.2618 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 151.016C58.666 151.016 68.0434 152.704 73.2948 147.828C78.5462 142.952 77.796 133.574 77.796 133.574C77.796 133.574 68.4185 131.886 63.1671 136.669C57.9158 141.451 58.666 151.016 58.666 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 151.016C111.274 151.016 101.896 152.704 96.6447 147.828C91.3933 142.952 92.0497 133.574 92.0497 133.574C92.0497 133.574 101.427 131.886 106.679 136.669C111.93 141.451 111.274 151.016 111.274 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 151.016C103.678 151.016 113.055 152.704 118.307 147.828C123.558 142.952 122.808 133.574 122.808 133.574C122.808 133.574 113.43 131.886 108.179 136.669C102.927 141.451 103.678 151.016 103.678 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 151.016C156.285 151.016 146.908 152.704 141.656 147.828C136.405 142.952 137.061 133.574 137.061 133.574C137.061 133.574 146.439 131.886 151.69 136.669C156.942 141.451 156.285 151.016 156.285 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 151.016C148.689 151.016 158.067 152.704 163.318 147.828C168.57 142.952 167.819 133.574 167.819 133.574C167.819 133.574 158.442 131.886 153.191 136.669C147.939 141.451 148.689 151.016 148.689 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 151.016C201.297 151.016 191.919 152.704 186.668 147.828C181.417 142.952 182.073 133.574 182.073 133.574C182.073 133.574 191.451 131.886 196.702 136.669C201.953 141.451 201.297 151.016 201.297 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 151.016C193.701 151.016 203.079 152.704 208.33 147.828C213.581 142.952 212.831 133.574 212.831 133.574C212.831 133.574 203.454 131.886 198.202 136.669C192.951 141.451 193.701 151.016 193.701 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 151.016C246.309 151.016 236.931 152.704 231.68 147.828C226.428 142.952 227.085 133.574 227.085 133.574C227.085 133.574 236.462 131.886 241.714 136.669C246.965 141.451 246.309 151.016 246.309 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 151.016C238.713 151.016 248.09 152.704 253.342 147.828C258.593 142.952 257.843 133.574 257.843 133.574C257.843 133.574 248.465 131.886 243.214 136.669C237.963 141.451 238.713 151.016 238.713 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 151.016C291.322 151.016 281.945 152.704 276.693 147.828C271.442 142.952 272.099 133.574 272.099 133.574C272.099 133.574 281.476 131.886 286.727 136.669C291.979 141.451 291.322 151.016 291.322 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 151.016C283.726 151.016 293.104 152.704 298.355 147.828C303.607 142.952 302.857 133.574 302.857 133.574C302.857 133.574 293.479 131.886 288.228 136.669C282.976 141.451 283.726 151.016 283.726 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 151.016C336.334 151.016 326.957 152.704 321.705 147.828C316.454 142.952 317.11 133.574 317.11 133.574C317.11 133.574 326.488 131.886 331.739 136.669C336.99 141.451 336.334 151.016 336.334 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 151.016C328.738 151.016 338.116 152.704 343.367 147.828C348.618 142.952 347.868 133.574 347.868 133.574C347.868 133.574 338.491 131.886 333.239 136.669C327.988 141.451 328.738 151.016 328.738 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 151.016C381.346 151.016 371.968 152.704 366.717 147.828C361.466 142.952 362.122 133.574 362.122 133.574C362.122 133.574 371.499 131.886 376.751 136.669C382.002 141.451 381.346 151.016 381.346 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 151.016C373.75 151.016 383.127 152.704 388.379 147.828C393.63 142.952 392.88 133.574 392.88 133.574C392.88 133.574 383.503 131.886 378.251 136.669C373 141.451 373.75 151.016 373.75 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 151.016C426.358 151.016 416.98 152.704 411.729 147.828C406.477 142.952 407.134 133.574 407.134 133.574C407.134 133.574 416.511 131.886 421.763 136.669C427.014 141.451 426.358 151.016 426.358 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 151.016C418.762 151.016 428.139 152.704 433.391 147.828C438.642 142.952 437.892 133.574 437.892 133.574C437.892 133.574 428.514 131.886 423.263 136.669C418.011 141.451 418.762 151.016 418.762 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 151.016C471.369 151.016 461.992 152.704 456.74 147.828C451.489 142.952 452.145 133.574 452.145 133.574C452.145 133.574 461.523 131.886 466.774 136.669C472.026 141.451 471.369 151.016 471.369 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 151.016C463.773 151.016 473.151 152.704 478.402 147.828C483.654 142.952 482.903 133.574 482.903 133.574C482.903 133.574 473.526 131.886 468.275 136.669C463.023 141.451 463.773 151.016 463.773 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 151.016C516.381 151.016 507.003 152.704 501.752 147.828C496.501 142.952 497.157 133.574 497.157 133.574C497.157 133.574 506.535 131.886 511.786 136.669C517.037 141.451 516.381 151.016 516.381 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 151.016C508.785 151.016 518.163 152.704 523.414 147.828C528.665 142.952 527.915 133.574 527.915 133.574C527.915 133.574 518.538 131.886 513.286 136.669C508.035 141.451 508.785 151.016 508.785 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 151.016C561.393 151.016 552.015 152.704 546.764 147.828C541.512 142.952 542.169 133.574 542.169 133.574C542.169 133.574 551.546 131.886 556.798 136.669C562.049 141.451 561.393 151.016 561.393 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 151.016C553.797 151.016 563.174 152.704 568.426 147.828C573.677 142.952 572.927 133.574 572.927 133.574C572.927 133.574 563.549 131.886 558.298 136.669C553.047 141.451 553.797 151.016 553.797 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 151.016C606.404 151.016 597.027 152.704 591.776 147.828C586.524 142.952 587.181 133.574 587.181 133.574C587.181 133.574 596.558 131.886 601.809 136.669C607.061 141.451 606.404 151.016 606.404 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 151.016C598.809 151.016 608.186 152.704 613.437 147.828C618.689 142.952 617.939 133.574 617.939 133.574C617.939 133.574 608.561 131.886 603.31 136.669C598.058 141.451 598.809 151.016 598.809 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 151.016C651.416 151.016 642.039 152.704 636.787 147.828C631.536 142.952 632.192 133.574 632.192 133.574C632.192 133.574 641.57 131.886 646.821 136.669C652.073 141.451 651.416 151.016 651.416 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 151.016C643.82 151.016 653.198 152.704 658.449 147.828C663.701 142.952 662.95 133.574 662.95 133.574C662.95 133.574 653.573 131.886 648.321 136.669C643.07 141.451 643.82 151.016 643.82 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 151.016C696.428 151.016 687.05 152.704 681.799 147.828C676.548 142.952 677.204 133.574 677.204 133.574C677.204 133.574 686.581 131.886 691.833 136.669C697.084 141.451 696.428 151.016 696.428 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 151.016C688.832 151.016 698.209 152.704 703.461 147.828C708.712 142.952 707.962 133.574 707.962 133.574C707.962 133.574 698.585 131.886 693.333 136.669C688.082 141.451 688.832 151.016 688.832 151.016Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 183.837C21.2501 183.837 11.8726 185.525 6.62122 180.648C1.36983 175.772 2.02626 166.394 2.02626 166.394C2.02626 166.394 11.4037 164.707 16.6551 169.489C21.9065 174.272 21.2501 183.837 21.2501 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 183.837C13.6542 183.837 23.0317 185.525 28.2831 180.648C33.5345 175.772 32.7843 166.394 32.7843 166.394C32.7843 166.394 23.4068 164.707 18.1554 169.489C12.904 174.272 13.6542 183.837 13.6542 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 183.837C66.2618 183.837 56.8843 185.525 51.6329 180.648C46.3816 175.772 47.038 166.394 47.038 166.394C47.038 166.394 56.4155 164.707 61.6668 169.489C66.9182 174.272 66.2618 183.837 66.2618 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 183.837C58.666 183.837 68.0434 185.525 73.2948 180.648C78.5462 175.772 77.796 166.394 77.796 166.394C77.796 166.394 68.4185 164.707 63.1671 169.489C57.9158 174.272 58.666 183.837 58.666 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 183.837C111.274 183.837 101.896 185.525 96.6447 180.648C91.3933 175.772 92.0497 166.394 92.0497 166.394C92.0497 166.394 101.427 164.707 106.679 169.489C111.93 174.272 111.274 183.837 111.274 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 183.837C103.678 183.837 113.055 185.525 118.307 180.648C123.558 175.772 122.808 166.394 122.808 166.394C122.808 166.394 113.43 164.707 108.179 169.489C102.927 174.272 103.678 183.837 103.678 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 183.837C156.285 183.837 146.908 185.525 141.656 180.648C136.405 175.772 137.061 166.394 137.061 166.394C137.061 166.394 146.439 164.707 151.69 169.489C156.942 174.272 156.285 183.837 156.285 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 183.837C148.689 183.837 158.067 185.525 163.318 180.648C168.57 175.772 167.819 166.394 167.819 166.394C167.819 166.394 158.442 164.707 153.191 169.489C147.939 174.272 148.689 183.837 148.689 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 183.837C201.297 183.837 191.919 185.525 186.668 180.648C181.417 175.772 182.073 166.394 182.073 166.394C182.073 166.394 191.451 164.707 196.702 169.489C201.953 174.272 201.297 183.837 201.297 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 183.837C193.701 183.837 203.079 185.525 208.33 180.648C213.581 175.772 212.831 166.394 212.831 166.394C212.831 166.394 203.454 164.707 198.202 169.489C192.951 174.272 193.701 183.837 193.701 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 183.837C246.309 183.837 236.931 185.525 231.68 180.648C226.428 175.772 227.085 166.394 227.085 166.394C227.085 166.394 236.462 164.707 241.714 169.489C246.965 174.272 246.309 183.837 246.309 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 183.837C238.713 183.837 248.09 185.525 253.342 180.648C258.593 175.772 257.843 166.394 257.843 166.394C257.843 166.394 248.465 164.707 243.214 169.489C237.963 174.272 238.713 183.837 238.713 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 183.837C291.322 183.837 281.945 185.525 276.693 180.648C271.442 175.772 272.099 166.394 272.099 166.394C272.099 166.394 281.476 164.707 286.727 169.489C291.979 174.272 291.322 183.837 291.322 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 183.837C283.726 183.837 293.104 185.525 298.355 180.648C303.607 175.772 302.857 166.394 302.857 166.394C302.857 166.394 293.479 164.707 288.228 169.489C282.976 174.272 283.726 183.837 283.726 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 183.837C336.334 183.837 326.957 185.525 321.705 180.648C316.454 175.772 317.11 166.394 317.11 166.394C317.11 166.394 326.488 164.707 331.739 169.489C336.99 174.272 336.334 183.837 336.334 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 183.837C328.738 183.837 338.116 185.525 343.367 180.648C348.618 175.772 347.868 166.394 347.868 166.394C347.868 166.394 338.491 164.707 333.239 169.489C327.988 174.272 328.738 183.837 328.738 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 183.837C381.346 183.837 371.968 185.525 366.717 180.648C361.466 175.772 362.122 166.394 362.122 166.394C362.122 166.394 371.499 164.707 376.751 169.489C382.002 174.272 381.346 183.837 381.346 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 183.837C373.75 183.837 383.127 185.525 388.379 180.648C393.63 175.772 392.88 166.394 392.88 166.394C392.88 166.394 383.503 164.707 378.251 169.489C373 174.272 373.75 183.837 373.75 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 183.837C426.358 183.837 416.98 185.525 411.729 180.648C406.477 175.772 407.134 166.394 407.134 166.394C407.134 166.394 416.511 164.707 421.763 169.489C427.014 174.272 426.358 183.837 426.358 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 183.837C418.762 183.837 428.139 185.525 433.391 180.648C438.642 175.772 437.892 166.394 437.892 166.394C437.892 166.394 428.514 164.707 423.263 169.489C418.011 174.272 418.762 183.837 418.762 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 183.837C471.369 183.837 461.992 185.525 456.74 180.648C451.489 175.772 452.145 166.394 452.145 166.394C452.145 166.394 461.523 164.707 466.774 169.489C472.026 174.272 471.369 183.837 471.369 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 183.837C463.773 183.837 473.151 185.525 478.402 180.648C483.654 175.772 482.903 166.394 482.903 166.394C482.903 166.394 473.526 164.707 468.275 169.489C463.023 174.272 463.773 183.837 463.773 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 183.837C516.381 183.837 507.003 185.525 501.752 180.648C496.501 175.772 497.157 166.394 497.157 166.394C497.157 166.394 506.535 164.707 511.786 169.489C517.037 174.272 516.381 183.837 516.381 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 183.837C508.785 183.837 518.163 185.525 523.414 180.648C528.665 175.772 527.915 166.394 527.915 166.394C527.915 166.394 518.538 164.707 513.286 169.489C508.035 174.272 508.785 183.837 508.785 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 183.837C561.393 183.837 552.015 185.525 546.764 180.648C541.512 175.772 542.169 166.394 542.169 166.394C542.169 166.394 551.546 164.707 556.798 169.489C562.049 174.272 561.393 183.837 561.393 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 183.837C553.797 183.837 563.174 185.525 568.426 180.648C573.677 175.772 572.927 166.394 572.927 166.394C572.927 166.394 563.549 164.707 558.298 169.489C553.047 174.272 553.797 183.837 553.797 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 183.837C606.404 183.837 597.027 185.525 591.776 180.648C586.524 175.772 587.181 166.394 587.181 166.394C587.181 166.394 596.558 164.707 601.809 169.489C607.061 174.272 606.404 183.837 606.404 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 183.837C598.809 183.837 608.186 185.525 613.437 180.648C618.689 175.772 617.939 166.394 617.939 166.394C617.939 166.394 608.561 164.707 603.31 169.489C598.058 174.272 598.809 183.837 598.809 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 183.837C651.416 183.837 642.039 185.525 636.787 180.648C631.536 175.772 632.192 166.394 632.192 166.394C632.192 166.394 641.57 164.707 646.821 169.489C652.073 174.272 651.416 183.837 651.416 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 183.837C643.82 183.837 653.198 185.525 658.449 180.648C663.701 175.772 662.95 166.394 662.95 166.394C662.95 166.394 653.573 164.707 648.321 169.489C643.07 174.272 643.82 183.837 643.82 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 183.837C696.428 183.837 687.05 185.525 681.799 180.648C676.548 175.772 677.204 166.394 677.204 166.394C677.204 166.394 686.581 164.707 691.833 169.489C697.084 174.272 696.428 183.837 696.428 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 183.837C688.832 183.837 698.209 185.525 703.461 180.648C708.712 175.772 707.962 166.394 707.962 166.394C707.962 166.394 698.585 164.707 693.333 169.489C688.082 174.272 688.832 183.837 688.832 183.837Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 216.658C21.2501 216.658 11.8726 218.346 6.62122 213.47C1.36983 208.593 2.02626 199.216 2.02626 199.216C2.02626 199.216 11.4037 197.528 16.6551 202.31C21.9065 207.093 21.2501 216.658 21.2501 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 216.658C13.6542 216.658 23.0317 218.346 28.2831 213.47C33.5345 208.593 32.7843 199.216 32.7843 199.216C32.7843 199.216 23.4068 197.528 18.1554 202.31C12.904 207.093 13.6542 216.658 13.6542 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 216.658C66.2618 216.658 56.8843 218.346 51.6329 213.47C46.3816 208.593 47.038 199.216 47.038 199.216C47.038 199.216 56.4155 197.528 61.6668 202.31C66.9182 207.093 66.2618 216.658 66.2618 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 216.658C58.666 216.658 68.0434 218.346 73.2948 213.47C78.5462 208.593 77.796 199.216 77.796 199.216C77.796 199.216 68.4185 197.528 63.1671 202.31C57.9158 207.093 58.666 216.658 58.666 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 216.658C111.274 216.658 101.896 218.346 96.6447 213.47C91.3933 208.593 92.0497 199.216 92.0497 199.216C92.0497 199.216 101.427 197.528 106.679 202.31C111.93 207.093 111.274 216.658 111.274 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 216.658C103.678 216.658 113.055 218.346 118.307 213.47C123.558 208.593 122.808 199.216 122.808 199.216C122.808 199.216 113.43 197.528 108.179 202.31C102.927 207.093 103.678 216.658 103.678 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 216.658C156.285 216.658 146.908 218.346 141.656 213.47C136.405 208.593 137.061 199.216 137.061 199.216C137.061 199.216 146.439 197.528 151.69 202.31C156.942 207.093 156.285 216.658 156.285 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 216.658C148.689 216.658 158.067 218.346 163.318 213.47C168.57 208.593 167.819 199.216 167.819 199.216C167.819 199.216 158.442 197.528 153.191 202.31C147.939 207.093 148.689 216.658 148.689 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 216.658C201.297 216.658 191.919 218.346 186.668 213.47C181.417 208.593 182.073 199.216 182.073 199.216C182.073 199.216 191.451 197.528 196.702 202.31C201.953 207.093 201.297 216.658 201.297 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 216.658C193.701 216.658 203.079 218.346 208.33 213.47C213.581 208.593 212.831 199.216 212.831 199.216C212.831 199.216 203.454 197.528 198.202 202.31C192.951 207.093 193.701 216.658 193.701 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 216.658C246.309 216.658 236.931 218.346 231.68 213.47C226.428 208.593 227.085 199.216 227.085 199.216C227.085 199.216 236.462 197.528 241.714 202.31C246.965 207.093 246.309 216.658 246.309 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 216.658C238.713 216.658 248.09 218.346 253.342 213.47C258.593 208.593 257.843 199.216 257.843 199.216C257.843 199.216 248.465 197.528 243.214 202.31C237.963 207.093 238.713 216.658 238.713 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 216.658C291.322 216.658 281.945 218.346 276.693 213.47C271.442 208.593 272.099 199.216 272.099 199.216C272.099 199.216 281.476 197.528 286.727 202.31C291.979 207.093 291.322 216.658 291.322 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 216.658C283.726 216.658 293.104 218.346 298.355 213.47C303.607 208.593 302.857 199.216 302.857 199.216C302.857 199.216 293.479 197.528 288.228 202.31C282.976 207.093 283.726 216.658 283.726 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 216.658C336.334 216.658 326.957 218.346 321.705 213.47C316.454 208.593 317.11 199.216 317.11 199.216C317.11 199.216 326.488 197.528 331.739 202.31C336.99 207.093 336.334 216.658 336.334 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 216.658C328.738 216.658 338.116 218.346 343.367 213.47C348.618 208.593 347.868 199.216 347.868 199.216C347.868 199.216 338.491 197.528 333.239 202.31C327.988 207.093 328.738 216.658 328.738 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 216.658C381.346 216.658 371.968 218.346 366.717 213.47C361.466 208.593 362.122 199.216 362.122 199.216C362.122 199.216 371.499 197.528 376.751 202.31C382.002 207.093 381.346 216.658 381.346 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 216.658C373.75 216.658 383.127 218.346 388.379 213.47C393.63 208.593 392.88 199.216 392.88 199.216C392.88 199.216 383.503 197.528 378.251 202.31C373 207.093 373.75 216.658 373.75 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 216.658C426.358 216.658 416.98 218.346 411.729 213.47C406.477 208.593 407.134 199.216 407.134 199.216C407.134 199.216 416.511 197.528 421.763 202.31C427.014 207.093 426.358 216.658 426.358 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 216.658C418.762 216.658 428.139 218.346 433.391 213.47C438.642 208.593 437.892 199.216 437.892 199.216C437.892 199.216 428.514 197.528 423.263 202.31C418.011 207.093 418.762 216.658 418.762 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 216.658C471.369 216.658 461.992 218.346 456.74 213.47C451.489 208.593 452.145 199.216 452.145 199.216C452.145 199.216 461.523 197.528 466.774 202.31C472.026 207.093 471.369 216.658 471.369 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 216.658C463.773 216.658 473.151 218.346 478.402 213.47C483.654 208.593 482.903 199.216 482.903 199.216C482.903 199.216 473.526 197.528 468.275 202.31C463.023 207.093 463.773 216.658 463.773 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 216.658C516.381 216.658 507.003 218.346 501.752 213.47C496.501 208.593 497.157 199.216 497.157 199.216C497.157 199.216 506.535 197.528 511.786 202.31C517.037 207.093 516.381 216.658 516.381 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 216.658C508.785 216.658 518.163 218.346 523.414 213.47C528.665 208.593 527.915 199.216 527.915 199.216C527.915 199.216 518.538 197.528 513.286 202.31C508.035 207.093 508.785 216.658 508.785 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 216.658C561.393 216.658 552.015 218.346 546.764 213.47C541.512 208.593 542.169 199.216 542.169 199.216C542.169 199.216 551.546 197.528 556.798 202.31C562.049 207.093 561.393 216.658 561.393 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 216.658C553.797 216.658 563.174 218.346 568.426 213.47C573.677 208.593 572.927 199.216 572.927 199.216C572.927 199.216 563.549 197.528 558.298 202.31C553.047 207.093 553.797 216.658 553.797 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 216.658C606.404 216.658 597.027 218.346 591.776 213.47C586.524 208.593 587.181 199.216 587.181 199.216C587.181 199.216 596.558 197.528 601.809 202.31C607.061 207.093 606.404 216.658 606.404 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 216.658C598.809 216.658 608.186 218.346 613.437 213.47C618.689 208.593 617.939 199.216 617.939 199.216C617.939 199.216 608.561 197.528 603.31 202.31C598.058 207.093 598.809 216.658 598.809 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 216.658C651.416 216.658 642.039 218.346 636.787 213.47C631.536 208.593 632.192 199.216 632.192 199.216C632.192 199.216 641.57 197.528 646.821 202.31C652.073 207.093 651.416 216.658 651.416 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 216.658C643.82 216.658 653.198 218.346 658.449 213.47C663.701 208.593 662.95 199.216 662.95 199.216C662.95 199.216 653.573 197.528 648.321 202.31C643.07 207.093 643.82 216.658 643.82 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 216.658C696.428 216.658 687.05 218.346 681.799 213.47C676.548 208.593 677.204 199.216 677.204 199.216C677.204 199.216 686.581 197.528 691.833 202.31C697.084 207.093 696.428 216.658 696.428 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 216.658C688.832 216.658 698.209 218.346 703.461 213.47C708.712 208.593 707.962 199.216 707.962 199.216C707.962 199.216 698.585 197.528 693.333 202.31C688.082 207.093 688.832 216.658 688.832 216.658Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 249.479C21.2501 249.479 11.8726 251.167 6.62122 246.291C1.36983 241.415 2.02626 232.037 2.02626 232.037C2.02626 232.037 11.4037 230.349 16.6551 235.132C21.9065 239.914 21.2501 249.479 21.2501 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 249.479C13.6542 249.479 23.0317 251.167 28.2831 246.291C33.5345 241.415 32.7843 232.037 32.7843 232.037C32.7843 232.037 23.4068 230.349 18.1554 235.132C12.904 239.914 13.6542 249.479 13.6542 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 249.479C66.2618 249.479 56.8843 251.167 51.6329 246.291C46.3816 241.415 47.038 232.037 47.038 232.037C47.038 232.037 56.4155 230.349 61.6668 235.132C66.9182 239.914 66.2618 249.479 66.2618 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 249.479C58.666 249.479 68.0434 251.167 73.2948 246.291C78.5462 241.415 77.796 232.037 77.796 232.037C77.796 232.037 68.4185 230.349 63.1671 235.132C57.9158 239.914 58.666 249.479 58.666 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 249.479C111.274 249.479 101.896 251.167 96.6447 246.291C91.3933 241.415 92.0497 232.037 92.0497 232.037C92.0497 232.037 101.427 230.349 106.679 235.132C111.93 239.914 111.274 249.479 111.274 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 249.479C103.678 249.479 113.055 251.167 118.307 246.291C123.558 241.415 122.808 232.037 122.808 232.037C122.808 232.037 113.43 230.349 108.179 235.132C102.927 239.914 103.678 249.479 103.678 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 249.479C156.285 249.479 146.908 251.167 141.656 246.291C136.405 241.415 137.061 232.037 137.061 232.037C137.061 232.037 146.439 230.349 151.69 235.132C156.942 239.914 156.285 249.479 156.285 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 249.479C148.689 249.479 158.067 251.167 163.318 246.291C168.57 241.415 167.819 232.037 167.819 232.037C167.819 232.037 158.442 230.349 153.191 235.132C147.939 239.914 148.689 249.479 148.689 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 249.479C201.297 249.479 191.919 251.167 186.668 246.291C181.417 241.415 182.073 232.037 182.073 232.037C182.073 232.037 191.451 230.349 196.702 235.132C201.953 239.914 201.297 249.479 201.297 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 249.479C193.701 249.479 203.079 251.167 208.33 246.291C213.581 241.415 212.831 232.037 212.831 232.037C212.831 232.037 203.454 230.349 198.202 235.132C192.951 239.914 193.701 249.479 193.701 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 249.479C246.309 249.479 236.931 251.167 231.68 246.291C226.428 241.415 227.085 232.037 227.085 232.037C227.085 232.037 236.462 230.349 241.714 235.132C246.965 239.914 246.309 249.479 246.309 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 249.479C238.713 249.479 248.09 251.167 253.342 246.291C258.593 241.415 257.843 232.037 257.843 232.037C257.843 232.037 248.465 230.349 243.214 235.132C237.963 239.914 238.713 249.479 238.713 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 249.479C291.322 249.479 281.945 251.167 276.693 246.291C271.442 241.415 272.099 232.037 272.099 232.037C272.099 232.037 281.476 230.349 286.727 235.132C291.979 239.914 291.322 249.479 291.322 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 249.479C283.726 249.479 293.104 251.167 298.355 246.291C303.607 241.415 302.857 232.037 302.857 232.037C302.857 232.037 293.479 230.349 288.228 235.132C282.976 239.914 283.726 249.479 283.726 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 249.479C336.334 249.479 326.957 251.167 321.705 246.291C316.454 241.415 317.11 232.037 317.11 232.037C317.11 232.037 326.488 230.349 331.739 235.132C336.99 239.914 336.334 249.479 336.334 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 249.479C328.738 249.479 338.116 251.167 343.367 246.291C348.618 241.415 347.868 232.037 347.868 232.037C347.868 232.037 338.491 230.349 333.239 235.132C327.988 239.914 328.738 249.479 328.738 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 249.479C381.346 249.479 371.968 251.167 366.717 246.291C361.466 241.415 362.122 232.037 362.122 232.037C362.122 232.037 371.499 230.349 376.751 235.132C382.002 239.914 381.346 249.479 381.346 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 249.479C373.75 249.479 383.127 251.167 388.379 246.291C393.63 241.415 392.88 232.037 392.88 232.037C392.88 232.037 383.503 230.349 378.251 235.132C373 239.914 373.75 249.479 373.75 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 249.479C426.358 249.479 416.98 251.167 411.729 246.291C406.477 241.415 407.134 232.037 407.134 232.037C407.134 232.037 416.511 230.349 421.763 235.132C427.014 239.914 426.358 249.479 426.358 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 249.479C418.762 249.479 428.139 251.167 433.391 246.291C438.642 241.415 437.892 232.037 437.892 232.037C437.892 232.037 428.514 230.349 423.263 235.132C418.011 239.914 418.762 249.479 418.762 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 249.479C471.369 249.479 461.992 251.167 456.74 246.291C451.489 241.415 452.145 232.037 452.145 232.037C452.145 232.037 461.523 230.349 466.774 235.132C472.026 239.914 471.369 249.479 471.369 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 249.479C463.773 249.479 473.151 251.167 478.402 246.291C483.654 241.415 482.903 232.037 482.903 232.037C482.903 232.037 473.526 230.349 468.275 235.132C463.023 239.914 463.773 249.479 463.773 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 249.479C516.381 249.479 507.003 251.167 501.752 246.291C496.501 241.415 497.157 232.037 497.157 232.037C497.157 232.037 506.535 230.349 511.786 235.132C517.037 239.914 516.381 249.479 516.381 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 249.479C508.785 249.479 518.163 251.167 523.414 246.291C528.665 241.415 527.915 232.037 527.915 232.037C527.915 232.037 518.538 230.349 513.286 235.132C508.035 239.914 508.785 249.479 508.785 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 249.479C561.393 249.479 552.015 251.167 546.764 246.291C541.512 241.415 542.169 232.037 542.169 232.037C542.169 232.037 551.546 230.349 556.798 235.132C562.049 239.914 561.393 249.479 561.393 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 249.479C553.797 249.479 563.174 251.167 568.426 246.291C573.677 241.415 572.927 232.037 572.927 232.037C572.927 232.037 563.549 230.349 558.298 235.132C553.047 239.914 553.797 249.479 553.797 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 249.479C606.404 249.479 597.027 251.167 591.776 246.291C586.524 241.415 587.181 232.037 587.181 232.037C587.181 232.037 596.558 230.349 601.809 235.132C607.061 239.914 606.404 249.479 606.404 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 249.479C598.809 249.479 608.186 251.167 613.437 246.291C618.689 241.415 617.939 232.037 617.939 232.037C617.939 232.037 608.561 230.349 603.31 235.132C598.058 239.914 598.809 249.479 598.809 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 249.479C651.416 249.479 642.039 251.167 636.787 246.291C631.536 241.415 632.192 232.037 632.192 232.037C632.192 232.037 641.57 230.349 646.821 235.132C652.073 239.914 651.416 249.479 651.416 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 249.479C643.82 249.479 653.198 251.167 658.449 246.291C663.701 241.415 662.95 232.037 662.95 232.037C662.95 232.037 653.573 230.349 648.321 235.132C643.07 239.914 643.82 249.479 643.82 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 249.479C696.428 249.479 687.05 251.167 681.799 246.291C676.548 241.415 677.204 232.037 677.204 232.037C677.204 232.037 686.581 230.349 691.833 235.132C697.084 239.914 696.428 249.479 696.428 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 249.479C688.832 249.479 698.209 251.167 703.461 246.291C708.712 241.415 707.962 232.037 707.962 232.037C707.962 232.037 698.585 230.349 693.333 235.132C688.082 239.914 688.832 249.479 688.832 249.479Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 282.3C21.2501 282.3 11.8726 283.988 6.62122 279.112C1.36983 274.236 2.02626 264.858 2.02626 264.858C2.02626 264.858 11.4037 263.17 16.6551 267.953C21.9065 272.735 21.2501 282.3 21.2501 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 282.3C13.6542 282.3 23.0317 283.988 28.2831 279.112C33.5345 274.236 32.7843 264.858 32.7843 264.858C32.7843 264.858 23.4068 263.17 18.1554 267.953C12.904 272.735 13.6542 282.3 13.6542 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 282.3C66.2618 282.3 56.8843 283.988 51.6329 279.112C46.3816 274.236 47.038 264.858 47.038 264.858C47.038 264.858 56.4155 263.17 61.6668 267.953C66.9182 272.735 66.2618 282.3 66.2618 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 282.3C58.666 282.3 68.0434 283.988 73.2948 279.112C78.5462 274.236 77.796 264.858 77.796 264.858C77.796 264.858 68.4185 263.17 63.1671 267.953C57.9158 272.735 58.666 282.3 58.666 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 282.3C111.274 282.3 101.896 283.988 96.6447 279.112C91.3933 274.236 92.0497 264.858 92.0497 264.858C92.0497 264.858 101.427 263.17 106.679 267.953C111.93 272.735 111.274 282.3 111.274 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 282.3C103.678 282.3 113.055 283.988 118.307 279.112C123.558 274.236 122.808 264.858 122.808 264.858C122.808 264.858 113.43 263.17 108.179 267.953C102.927 272.735 103.678 282.3 103.678 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 282.3C156.285 282.3 146.908 283.988 141.656 279.112C136.405 274.236 137.061 264.858 137.061 264.858C137.061 264.858 146.439 263.17 151.69 267.953C156.942 272.735 156.285 282.3 156.285 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 282.3C148.689 282.3 158.067 283.988 163.318 279.112C168.57 274.236 167.819 264.858 167.819 264.858C167.819 264.858 158.442 263.17 153.191 267.953C147.939 272.735 148.689 282.3 148.689 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 282.3C201.297 282.3 191.919 283.988 186.668 279.112C181.417 274.236 182.073 264.858 182.073 264.858C182.073 264.858 191.451 263.17 196.702 267.953C201.953 272.735 201.297 282.3 201.297 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 282.3C193.701 282.3 203.079 283.988 208.33 279.112C213.581 274.236 212.831 264.858 212.831 264.858C212.831 264.858 203.454 263.17 198.202 267.953C192.951 272.735 193.701 282.3 193.701 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 282.3C246.309 282.3 236.931 283.988 231.68 279.112C226.428 274.236 227.085 264.858 227.085 264.858C227.085 264.858 236.462 263.17 241.714 267.953C246.965 272.735 246.309 282.3 246.309 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 282.3C238.713 282.3 248.09 283.988 253.342 279.112C258.593 274.236 257.843 264.858 257.843 264.858C257.843 264.858 248.465 263.17 243.214 267.953C237.963 272.735 238.713 282.3 238.713 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 282.3C291.322 282.3 281.945 283.988 276.693 279.112C271.442 274.236 272.099 264.858 272.099 264.858C272.099 264.858 281.476 263.17 286.727 267.953C291.979 272.735 291.322 282.3 291.322 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 282.3C283.726 282.3 293.104 283.988 298.355 279.112C303.607 274.236 302.857 264.858 302.857 264.858C302.857 264.858 293.479 263.17 288.228 267.953C282.976 272.735 283.726 282.3 283.726 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 282.3C336.334 282.3 326.957 283.988 321.705 279.112C316.454 274.236 317.11 264.858 317.11 264.858C317.11 264.858 326.488 263.17 331.739 267.953C336.99 272.735 336.334 282.3 336.334 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 282.3C328.738 282.3 338.116 283.988 343.367 279.112C348.618 274.236 347.868 264.858 347.868 264.858C347.868 264.858 338.491 263.17 333.239 267.953C327.988 272.735 328.738 282.3 328.738 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 282.3C381.346 282.3 371.968 283.988 366.717 279.112C361.466 274.236 362.122 264.858 362.122 264.858C362.122 264.858 371.499 263.17 376.751 267.953C382.002 272.735 381.346 282.3 381.346 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 282.3C373.75 282.3 383.127 283.988 388.379 279.112C393.63 274.236 392.88 264.858 392.88 264.858C392.88 264.858 383.503 263.17 378.251 267.953C373 272.735 373.75 282.3 373.75 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 282.3C426.358 282.3 416.98 283.988 411.729 279.112C406.477 274.236 407.134 264.858 407.134 264.858C407.134 264.858 416.511 263.17 421.763 267.953C427.014 272.735 426.358 282.3 426.358 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 282.3C418.762 282.3 428.139 283.988 433.391 279.112C438.642 274.236 437.892 264.858 437.892 264.858C437.892 264.858 428.514 263.17 423.263 267.953C418.011 272.735 418.762 282.3 418.762 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 282.3C471.369 282.3 461.992 283.988 456.74 279.112C451.489 274.236 452.145 264.858 452.145 264.858C452.145 264.858 461.523 263.17 466.774 267.953C472.026 272.735 471.369 282.3 471.369 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 282.3C463.773 282.3 473.151 283.988 478.402 279.112C483.654 274.236 482.903 264.858 482.903 264.858C482.903 264.858 473.526 263.17 468.275 267.953C463.023 272.735 463.773 282.3 463.773 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 282.3C516.381 282.3 507.003 283.988 501.752 279.112C496.501 274.236 497.157 264.858 497.157 264.858C497.157 264.858 506.535 263.17 511.786 267.953C517.037 272.735 516.381 282.3 516.381 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 282.3C508.785 282.3 518.163 283.988 523.414 279.112C528.665 274.236 527.915 264.858 527.915 264.858C527.915 264.858 518.538 263.17 513.286 267.953C508.035 272.735 508.785 282.3 508.785 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 282.3C561.393 282.3 552.015 283.988 546.764 279.112C541.512 274.236 542.169 264.858 542.169 264.858C542.169 264.858 551.546 263.17 556.798 267.953C562.049 272.735 561.393 282.3 561.393 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 282.3C553.797 282.3 563.174 283.988 568.426 279.112C573.677 274.236 572.927 264.858 572.927 264.858C572.927 264.858 563.549 263.17 558.298 267.953C553.047 272.735 553.797 282.3 553.797 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 282.3C606.404 282.3 597.027 283.988 591.776 279.112C586.524 274.236 587.181 264.858 587.181 264.858C587.181 264.858 596.558 263.17 601.809 267.953C607.061 272.735 606.404 282.3 606.404 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 282.3C598.809 282.3 608.186 283.988 613.437 279.112C618.689 274.236 617.939 264.858 617.939 264.858C617.939 264.858 608.561 263.17 603.31 267.953C598.058 272.735 598.809 282.3 598.809 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 282.3C651.416 282.3 642.039 283.988 636.787 279.112C631.536 274.236 632.192 264.858 632.192 264.858C632.192 264.858 641.57 263.17 646.821 267.953C652.073 272.735 651.416 282.3 651.416 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 282.3C643.82 282.3 653.198 283.988 658.449 279.112C663.701 274.236 662.95 264.858 662.95 264.858C662.95 264.858 653.573 263.17 648.321 267.953C643.07 272.735 643.82 282.3 643.82 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 282.3C696.428 282.3 687.05 283.988 681.799 279.112C676.548 274.236 677.204 264.858 677.204 264.858C677.204 264.858 686.581 263.17 691.833 267.953C697.084 272.735 696.428 282.3 696.428 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 282.3C688.832 282.3 698.209 283.988 703.461 279.112C708.712 274.236 707.962 264.858 707.962 264.858C707.962 264.858 698.585 263.17 693.333 267.953C688.082 272.735 688.832 282.3 688.832 282.3Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 315.122C21.2501 315.122 11.8726 316.81 6.62122 311.933C1.36983 307.057 2.02626 297.68 2.02626 297.68C2.02626 297.68 11.4037 295.992 16.6551 300.774C21.9065 305.557 21.2501 315.122 21.2501 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 315.122C13.6542 315.122 23.0317 316.81 28.2831 311.933C33.5345 307.057 32.7843 297.68 32.7843 297.68C32.7843 297.68 23.4068 295.992 18.1554 300.774C12.904 305.557 13.6542 315.122 13.6542 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 315.122C66.2618 315.122 56.8843 316.81 51.6329 311.933C46.3816 307.057 47.038 297.68 47.038 297.68C47.038 297.68 56.4155 295.992 61.6668 300.774C66.9182 305.557 66.2618 315.122 66.2618 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 315.122C58.666 315.122 68.0434 316.81 73.2948 311.933C78.5462 307.057 77.796 297.68 77.796 297.68C77.796 297.68 68.4185 295.992 63.1671 300.774C57.9158 305.557 58.666 315.122 58.666 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 315.122C111.274 315.122 101.896 316.81 96.6447 311.933C91.3933 307.057 92.0497 297.68 92.0497 297.68C92.0497 297.68 101.427 295.992 106.679 300.774C111.93 305.557 111.274 315.122 111.274 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 315.122C103.678 315.122 113.055 316.81 118.307 311.933C123.558 307.057 122.808 297.68 122.808 297.68C122.808 297.68 113.43 295.992 108.179 300.774C102.927 305.557 103.678 315.122 103.678 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 315.122C156.285 315.122 146.908 316.81 141.656 311.933C136.405 307.057 137.061 297.68 137.061 297.68C137.061 297.68 146.439 295.992 151.69 300.774C156.942 305.557 156.285 315.122 156.285 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 315.122C148.689 315.122 158.067 316.81 163.318 311.933C168.57 307.057 167.819 297.68 167.819 297.68C167.819 297.68 158.442 295.992 153.191 300.774C147.939 305.557 148.689 315.122 148.689 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 315.122C201.297 315.122 191.919 316.81 186.668 311.933C181.417 307.057 182.073 297.68 182.073 297.68C182.073 297.68 191.451 295.992 196.702 300.774C201.953 305.557 201.297 315.122 201.297 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 315.122C193.701 315.122 203.079 316.81 208.33 311.933C213.581 307.057 212.831 297.68 212.831 297.68C212.831 297.68 203.454 295.992 198.202 300.774C192.951 305.557 193.701 315.122 193.701 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 315.122C246.309 315.122 236.931 316.81 231.68 311.933C226.428 307.057 227.085 297.68 227.085 297.68C227.085 297.68 236.462 295.992 241.714 300.774C246.965 305.557 246.309 315.122 246.309 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 315.122C238.713 315.122 248.09 316.81 253.342 311.933C258.593 307.057 257.843 297.68 257.843 297.68C257.843 297.68 248.465 295.992 243.214 300.774C237.963 305.557 238.713 315.122 238.713 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 315.122C291.322 315.122 281.945 316.81 276.693 311.933C271.442 307.057 272.099 297.68 272.099 297.68C272.099 297.68 281.476 295.992 286.727 300.774C291.979 305.557 291.322 315.122 291.322 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 315.122C283.726 315.122 293.104 316.81 298.355 311.933C303.607 307.057 302.857 297.68 302.857 297.68C302.857 297.68 293.479 295.992 288.228 300.774C282.976 305.557 283.726 315.122 283.726 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 315.122C336.334 315.122 326.957 316.81 321.705 311.933C316.454 307.057 317.11 297.68 317.11 297.68C317.11 297.68 326.488 295.992 331.739 300.774C336.99 305.557 336.334 315.122 336.334 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 315.122C328.738 315.122 338.116 316.81 343.367 311.933C348.618 307.057 347.868 297.68 347.868 297.68C347.868 297.68 338.491 295.992 333.239 300.774C327.988 305.557 328.738 315.122 328.738 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 315.122C381.346 315.122 371.968 316.81 366.717 311.933C361.466 307.057 362.122 297.68 362.122 297.68C362.122 297.68 371.499 295.992 376.751 300.774C382.002 305.557 381.346 315.122 381.346 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 315.122C373.75 315.122 383.127 316.81 388.379 311.933C393.63 307.057 392.88 297.68 392.88 297.68C392.88 297.68 383.503 295.992 378.251 300.774C373 305.557 373.75 315.122 373.75 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 315.122C426.358 315.122 416.98 316.81 411.729 311.933C406.477 307.057 407.134 297.68 407.134 297.68C407.134 297.68 416.511 295.992 421.763 300.774C427.014 305.557 426.358 315.122 426.358 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 315.122C418.762 315.122 428.139 316.81 433.391 311.933C438.642 307.057 437.892 297.68 437.892 297.68C437.892 297.68 428.514 295.992 423.263 300.774C418.011 305.557 418.762 315.122 418.762 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 315.122C471.369 315.122 461.992 316.81 456.74 311.933C451.489 307.057 452.145 297.68 452.145 297.68C452.145 297.68 461.523 295.992 466.774 300.774C472.026 305.557 471.369 315.122 471.369 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 315.122C463.773 315.122 473.151 316.81 478.402 311.933C483.654 307.057 482.903 297.68 482.903 297.68C482.903 297.68 473.526 295.992 468.275 300.774C463.023 305.557 463.773 315.122 463.773 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 315.122C516.381 315.122 507.003 316.81 501.752 311.933C496.501 307.057 497.157 297.68 497.157 297.68C497.157 297.68 506.535 295.992 511.786 300.774C517.037 305.557 516.381 315.122 516.381 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 315.122C508.785 315.122 518.163 316.81 523.414 311.933C528.665 307.057 527.915 297.68 527.915 297.68C527.915 297.68 518.538 295.992 513.286 300.774C508.035 305.557 508.785 315.122 508.785 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 315.122C561.393 315.122 552.015 316.81 546.764 311.933C541.512 307.057 542.169 297.68 542.169 297.68C542.169 297.68 551.546 295.992 556.798 300.774C562.049 305.557 561.393 315.122 561.393 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 315.122C553.797 315.122 563.174 316.81 568.426 311.933C573.677 307.057 572.927 297.68 572.927 297.68C572.927 297.68 563.549 295.992 558.298 300.774C553.047 305.557 553.797 315.122 553.797 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 315.122C606.404 315.122 597.027 316.81 591.776 311.933C586.524 307.057 587.181 297.68 587.181 297.68C587.181 297.68 596.558 295.992 601.809 300.774C607.061 305.557 606.404 315.122 606.404 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 315.122C598.809 315.122 608.186 316.81 613.437 311.933C618.689 307.057 617.939 297.68 617.939 297.68C617.939 297.68 608.561 295.992 603.31 300.774C598.058 305.557 598.809 315.122 598.809 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 315.122C651.416 315.122 642.039 316.81 636.787 311.933C631.536 307.057 632.192 297.68 632.192 297.68C632.192 297.68 641.57 295.992 646.821 300.774C652.073 305.557 651.416 315.122 651.416 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 315.122C643.82 315.122 653.198 316.81 658.449 311.933C663.701 307.057 662.95 297.68 662.95 297.68C662.95 297.68 653.573 295.992 648.321 300.774C643.07 305.557 643.82 315.122 643.82 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 315.122C696.428 315.122 687.05 316.81 681.799 311.933C676.548 307.057 677.204 297.68 677.204 297.68C677.204 297.68 686.581 295.992 691.833 300.774C697.084 305.557 696.428 315.122 696.428 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 315.122C688.832 315.122 698.209 316.81 703.461 311.933C708.712 307.057 707.962 297.68 707.962 297.68C707.962 297.68 698.585 295.992 693.333 300.774C688.082 305.557 688.832 315.122 688.832 315.122Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 347.943C21.2501 347.943 11.8726 349.631 6.62122 344.755C1.36983 339.878 2.02626 330.501 2.02626 330.501C2.02626 330.501 11.4037 328.813 16.6551 333.596C21.9065 338.378 21.2501 347.943 21.2501 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 347.943C13.6542 347.943 23.0317 349.631 28.2831 344.755C33.5345 339.878 32.7843 330.501 32.7843 330.501C32.7843 330.501 23.4068 328.813 18.1554 333.596C12.904 338.378 13.6542 347.943 13.6542 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 347.943C66.2618 347.943 56.8843 349.631 51.6329 344.755C46.3816 339.878 47.038 330.501 47.038 330.501C47.038 330.501 56.4155 328.813 61.6668 333.596C66.9182 338.378 66.2618 347.943 66.2618 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 347.943C58.666 347.943 68.0434 349.631 73.2948 344.755C78.5462 339.878 77.796 330.501 77.796 330.501C77.796 330.501 68.4185 328.813 63.1671 333.596C57.9158 338.378 58.666 347.943 58.666 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 347.943C111.274 347.943 101.896 349.631 96.6447 344.755C91.3933 339.878 92.0497 330.501 92.0497 330.501C92.0497 330.501 101.427 328.813 106.679 333.596C111.93 338.378 111.274 347.943 111.274 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 347.943C103.678 347.943 113.055 349.631 118.307 344.755C123.558 339.878 122.808 330.501 122.808 330.501C122.808 330.501 113.43 328.813 108.179 333.596C102.927 338.378 103.678 347.943 103.678 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 347.943C156.285 347.943 146.908 349.631 141.656 344.755C136.405 339.878 137.061 330.501 137.061 330.501C137.061 330.501 146.439 328.813 151.69 333.596C156.942 338.378 156.285 347.943 156.285 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 347.943C148.689 347.943 158.067 349.631 163.318 344.755C168.57 339.878 167.819 330.501 167.819 330.501C167.819 330.501 158.442 328.813 153.191 333.596C147.939 338.378 148.689 347.943 148.689 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 347.943C201.297 347.943 191.919 349.631 186.668 344.755C181.417 339.878 182.073 330.501 182.073 330.501C182.073 330.501 191.451 328.813 196.702 333.596C201.953 338.378 201.297 347.943 201.297 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 347.943C193.701 347.943 203.079 349.631 208.33 344.755C213.581 339.878 212.831 330.501 212.831 330.501C212.831 330.501 203.454 328.813 198.202 333.596C192.951 338.378 193.701 347.943 193.701 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 347.943C246.309 347.943 236.931 349.631 231.68 344.755C226.428 339.878 227.085 330.501 227.085 330.501C227.085 330.501 236.462 328.813 241.714 333.596C246.965 338.378 246.309 347.943 246.309 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 347.943C238.713 347.943 248.09 349.631 253.342 344.755C258.593 339.878 257.843 330.501 257.843 330.501C257.843 330.501 248.465 328.813 243.214 333.596C237.963 338.378 238.713 347.943 238.713 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 347.943C291.322 347.943 281.945 349.631 276.693 344.755C271.442 339.878 272.099 330.501 272.099 330.501C272.099 330.501 281.476 328.813 286.727 333.596C291.979 338.378 291.322 347.943 291.322 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 347.943C283.726 347.943 293.104 349.631 298.355 344.755C303.607 339.878 302.857 330.501 302.857 330.501C302.857 330.501 293.479 328.813 288.228 333.596C282.976 338.378 283.726 347.943 283.726 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 347.943C336.334 347.943 326.957 349.631 321.705 344.755C316.454 339.878 317.11 330.501 317.11 330.501C317.11 330.501 326.488 328.813 331.739 333.596C336.99 338.378 336.334 347.943 336.334 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 347.943C328.738 347.943 338.116 349.631 343.367 344.755C348.618 339.878 347.868 330.501 347.868 330.501C347.868 330.501 338.491 328.813 333.239 333.596C327.988 338.378 328.738 347.943 328.738 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 347.943C381.346 347.943 371.968 349.631 366.717 344.755C361.466 339.878 362.122 330.501 362.122 330.501C362.122 330.501 371.499 328.813 376.751 333.596C382.002 338.378 381.346 347.943 381.346 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 347.943C373.75 347.943 383.127 349.631 388.379 344.755C393.63 339.878 392.88 330.501 392.88 330.501C392.88 330.501 383.503 328.813 378.251 333.596C373 338.378 373.75 347.943 373.75 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 347.943C426.358 347.943 416.98 349.631 411.729 344.755C406.477 339.878 407.134 330.501 407.134 330.501C407.134 330.501 416.511 328.813 421.763 333.596C427.014 338.378 426.358 347.943 426.358 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 347.943C418.762 347.943 428.139 349.631 433.391 344.755C438.642 339.878 437.892 330.501 437.892 330.501C437.892 330.501 428.514 328.813 423.263 333.596C418.011 338.378 418.762 347.943 418.762 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 347.943C471.369 347.943 461.992 349.631 456.74 344.755C451.489 339.878 452.145 330.501 452.145 330.501C452.145 330.501 461.523 328.813 466.774 333.596C472.026 338.378 471.369 347.943 471.369 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 347.943C463.773 347.943 473.151 349.631 478.402 344.755C483.654 339.878 482.903 330.501 482.903 330.501C482.903 330.501 473.526 328.813 468.275 333.596C463.023 338.378 463.773 347.943 463.773 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 347.943C516.381 347.943 507.003 349.631 501.752 344.755C496.501 339.878 497.157 330.501 497.157 330.501C497.157 330.501 506.535 328.813 511.786 333.596C517.037 338.378 516.381 347.943 516.381 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 347.943C508.785 347.943 518.163 349.631 523.414 344.755C528.665 339.878 527.915 330.501 527.915 330.501C527.915 330.501 518.538 328.813 513.286 333.596C508.035 338.378 508.785 347.943 508.785 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 347.943C561.393 347.943 552.015 349.631 546.764 344.755C541.512 339.878 542.169 330.501 542.169 330.501C542.169 330.501 551.546 328.813 556.798 333.596C562.049 338.378 561.393 347.943 561.393 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 347.943C553.797 347.943 563.174 349.631 568.426 344.755C573.677 339.878 572.927 330.501 572.927 330.501C572.927 330.501 563.549 328.813 558.298 333.596C553.047 338.378 553.797 347.943 553.797 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 347.943C606.404 347.943 597.027 349.631 591.776 344.755C586.524 339.878 587.181 330.501 587.181 330.501C587.181 330.501 596.558 328.813 601.809 333.596C607.061 338.378 606.404 347.943 606.404 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 347.943C598.809 347.943 608.186 349.631 613.437 344.755C618.689 339.878 617.939 330.501 617.939 330.501C617.939 330.501 608.561 328.813 603.31 333.596C598.058 338.378 598.809 347.943 598.809 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 347.943C651.416 347.943 642.039 349.631 636.787 344.755C631.536 339.878 632.192 330.501 632.192 330.501C632.192 330.501 641.57 328.813 646.821 333.596C652.073 338.378 651.416 347.943 651.416 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 347.943C643.82 347.943 653.198 349.631 658.449 344.755C663.701 339.878 662.95 330.501 662.95 330.501C662.95 330.501 653.573 328.813 648.321 333.596C643.07 338.378 643.82 347.943 643.82 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 347.943C696.428 347.943 687.05 349.631 681.799 344.755C676.548 339.878 677.204 330.501 677.204 330.501C677.204 330.501 686.581 328.813 691.833 333.596C697.084 338.378 696.428 347.943 696.428 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 347.943C688.832 347.943 698.209 349.631 703.461 344.755C708.712 339.878 707.962 330.501 707.962 330.501C707.962 330.501 698.585 328.813 693.333 333.596C688.082 338.378 688.832 347.943 688.832 347.943Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 380.764C21.2501 380.764 11.8726 382.452 6.62122 377.576C1.36983 372.7 2.02626 363.322 2.02626 363.322C2.02626 363.322 11.4037 361.634 16.6551 366.417C21.9065 371.199 21.2501 380.764 21.2501 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 380.764C13.6542 380.764 23.0317 382.452 28.2831 377.576C33.5345 372.7 32.7843 363.322 32.7843 363.322C32.7843 363.322 23.4068 361.634 18.1554 366.417C12.904 371.199 13.6542 380.764 13.6542 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 380.764C66.2618 380.764 56.8843 382.452 51.6329 377.576C46.3816 372.7 47.038 363.322 47.038 363.322C47.038 363.322 56.4155 361.634 61.6668 366.417C66.9182 371.199 66.2618 380.764 66.2618 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 380.764C58.666 380.764 68.0434 382.452 73.2948 377.576C78.5462 372.7 77.796 363.322 77.796 363.322C77.796 363.322 68.4185 361.634 63.1671 366.417C57.9158 371.199 58.666 380.764 58.666 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 380.764C111.274 380.764 101.896 382.452 96.6447 377.576C91.3933 372.7 92.0497 363.322 92.0497 363.322C92.0497 363.322 101.427 361.634 106.679 366.417C111.93 371.199 111.274 380.764 111.274 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 380.764C103.678 380.764 113.055 382.452 118.307 377.576C123.558 372.7 122.808 363.322 122.808 363.322C122.808 363.322 113.43 361.634 108.179 366.417C102.927 371.199 103.678 380.764 103.678 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 380.764C156.285 380.764 146.908 382.452 141.656 377.576C136.405 372.7 137.061 363.322 137.061 363.322C137.061 363.322 146.439 361.634 151.69 366.417C156.942 371.199 156.285 380.764 156.285 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 380.764C148.689 380.764 158.067 382.452 163.318 377.576C168.57 372.7 167.819 363.322 167.819 363.322C167.819 363.322 158.442 361.634 153.191 366.417C147.939 371.199 148.689 380.764 148.689 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 380.764C201.297 380.764 191.919 382.452 186.668 377.576C181.417 372.7 182.073 363.322 182.073 363.322C182.073 363.322 191.451 361.634 196.702 366.417C201.953 371.199 201.297 380.764 201.297 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 380.764C193.701 380.764 203.079 382.452 208.33 377.576C213.581 372.7 212.831 363.322 212.831 363.322C212.831 363.322 203.454 361.634 198.202 366.417C192.951 371.199 193.701 380.764 193.701 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 380.764C246.309 380.764 236.931 382.452 231.68 377.576C226.428 372.7 227.085 363.322 227.085 363.322C227.085 363.322 236.462 361.634 241.714 366.417C246.965 371.199 246.309 380.764 246.309 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 380.764C238.713 380.764 248.09 382.452 253.342 377.576C258.593 372.7 257.843 363.322 257.843 363.322C257.843 363.322 248.465 361.634 243.214 366.417C237.963 371.199 238.713 380.764 238.713 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 380.764C291.322 380.764 281.945 382.452 276.693 377.576C271.442 372.7 272.099 363.322 272.099 363.322C272.099 363.322 281.476 361.634 286.727 366.417C291.979 371.199 291.322 380.764 291.322 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 380.764C283.726 380.764 293.104 382.452 298.355 377.576C303.607 372.7 302.857 363.322 302.857 363.322C302.857 363.322 293.479 361.634 288.228 366.417C282.976 371.199 283.726 380.764 283.726 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 380.764C336.334 380.764 326.957 382.452 321.705 377.576C316.454 372.7 317.11 363.322 317.11 363.322C317.11 363.322 326.488 361.634 331.739 366.417C336.99 371.199 336.334 380.764 336.334 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 380.764C328.738 380.764 338.116 382.452 343.367 377.576C348.618 372.7 347.868 363.322 347.868 363.322C347.868 363.322 338.491 361.634 333.239 366.417C327.988 371.199 328.738 380.764 328.738 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 380.764C381.346 380.764 371.968 382.452 366.717 377.576C361.466 372.7 362.122 363.322 362.122 363.322C362.122 363.322 371.499 361.634 376.751 366.417C382.002 371.199 381.346 380.764 381.346 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 380.764C373.75 380.764 383.127 382.452 388.379 377.576C393.63 372.7 392.88 363.322 392.88 363.322C392.88 363.322 383.503 361.634 378.251 366.417C373 371.199 373.75 380.764 373.75 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 380.764C426.358 380.764 416.98 382.452 411.729 377.576C406.477 372.7 407.134 363.322 407.134 363.322C407.134 363.322 416.511 361.634 421.763 366.417C427.014 371.199 426.358 380.764 426.358 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 380.764C418.762 380.764 428.139 382.452 433.391 377.576C438.642 372.7 437.892 363.322 437.892 363.322C437.892 363.322 428.514 361.634 423.263 366.417C418.011 371.199 418.762 380.764 418.762 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 380.764C471.369 380.764 461.992 382.452 456.74 377.576C451.489 372.7 452.145 363.322 452.145 363.322C452.145 363.322 461.523 361.634 466.774 366.417C472.026 371.199 471.369 380.764 471.369 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 380.764C463.773 380.764 473.151 382.452 478.402 377.576C483.654 372.7 482.903 363.322 482.903 363.322C482.903 363.322 473.526 361.634 468.275 366.417C463.023 371.199 463.773 380.764 463.773 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 380.764C516.381 380.764 507.003 382.452 501.752 377.576C496.501 372.7 497.157 363.322 497.157 363.322C497.157 363.322 506.535 361.634 511.786 366.417C517.037 371.199 516.381 380.764 516.381 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 380.764C508.785 380.764 518.163 382.452 523.414 377.576C528.665 372.7 527.915 363.322 527.915 363.322C527.915 363.322 518.538 361.634 513.286 366.417C508.035 371.199 508.785 380.764 508.785 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 380.764C561.393 380.764 552.015 382.452 546.764 377.576C541.512 372.7 542.169 363.322 542.169 363.322C542.169 363.322 551.546 361.634 556.798 366.417C562.049 371.199 561.393 380.764 561.393 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 380.764C553.797 380.764 563.174 382.452 568.426 377.576C573.677 372.7 572.927 363.322 572.927 363.322C572.927 363.322 563.549 361.634 558.298 366.417C553.047 371.199 553.797 380.764 553.797 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 380.764C606.404 380.764 597.027 382.452 591.776 377.576C586.524 372.7 587.181 363.322 587.181 363.322C587.181 363.322 596.558 361.634 601.809 366.417C607.061 371.199 606.404 380.764 606.404 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 380.764C598.809 380.764 608.186 382.452 613.437 377.576C618.689 372.7 617.939 363.322 617.939 363.322C617.939 363.322 608.561 361.634 603.31 366.417C598.058 371.199 598.809 380.764 598.809 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 380.764C651.416 380.764 642.039 382.452 636.787 377.576C631.536 372.7 632.192 363.322 632.192 363.322C632.192 363.322 641.57 361.634 646.821 366.417C652.073 371.199 651.416 380.764 651.416 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 380.764C643.82 380.764 653.198 382.452 658.449 377.576C663.701 372.7 662.95 363.322 662.95 363.322C662.95 363.322 653.573 361.634 648.321 366.417C643.07 371.199 643.82 380.764 643.82 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 380.764C696.428 380.764 687.05 382.452 681.799 377.576C676.548 372.7 677.204 363.322 677.204 363.322C677.204 363.322 686.581 361.634 691.833 366.417C697.084 371.199 696.428 380.764 696.428 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 380.764C688.832 380.764 698.209 382.452 703.461 377.576C708.712 372.7 707.962 363.322 707.962 363.322C707.962 363.322 698.585 361.634 693.333 366.417C688.082 371.199 688.832 380.764 688.832 380.764Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 413.586C21.2501 413.586 11.8726 415.274 6.62122 410.397C1.36983 405.521 2.02626 396.144 2.02626 396.144C2.02626 396.144 11.4037 394.456 16.6551 399.238C21.9065 404.021 21.2501 413.586 21.2501 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 413.586C13.6542 413.586 23.0317 415.274 28.2831 410.397C33.5345 405.521 32.7843 396.144 32.7843 396.144C32.7843 396.144 23.4068 394.456 18.1554 399.238C12.904 404.021 13.6542 413.586 13.6542 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 413.586C66.2618 413.586 56.8843 415.274 51.6329 410.397C46.3816 405.521 47.038 396.144 47.038 396.144C47.038 396.144 56.4155 394.456 61.6668 399.238C66.9182 404.021 66.2618 413.586 66.2618 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 413.586C58.666 413.586 68.0434 415.274 73.2948 410.397C78.5462 405.521 77.796 396.144 77.796 396.144C77.796 396.144 68.4185 394.456 63.1671 399.238C57.9158 404.021 58.666 413.586 58.666 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 413.586C111.274 413.586 101.896 415.274 96.6447 410.397C91.3933 405.521 92.0497 396.144 92.0497 396.144C92.0497 396.144 101.427 394.456 106.679 399.238C111.93 404.021 111.274 413.586 111.274 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 413.586C103.678 413.586 113.055 415.274 118.307 410.397C123.558 405.521 122.808 396.144 122.808 396.144C122.808 396.144 113.43 394.456 108.179 399.238C102.927 404.021 103.678 413.586 103.678 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 413.586C156.285 413.586 146.908 415.274 141.656 410.397C136.405 405.521 137.061 396.144 137.061 396.144C137.061 396.144 146.439 394.456 151.69 399.238C156.942 404.021 156.285 413.586 156.285 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 413.586C148.689 413.586 158.067 415.274 163.318 410.397C168.57 405.521 167.819 396.144 167.819 396.144C167.819 396.144 158.442 394.456 153.191 399.238C147.939 404.021 148.689 413.586 148.689 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 413.586C201.297 413.586 191.919 415.274 186.668 410.397C181.417 405.521 182.073 396.144 182.073 396.144C182.073 396.144 191.451 394.456 196.702 399.238C201.953 404.021 201.297 413.586 201.297 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 413.586C193.701 413.586 203.079 415.274 208.33 410.397C213.581 405.521 212.831 396.144 212.831 396.144C212.831 396.144 203.454 394.456 198.202 399.238C192.951 404.021 193.701 413.586 193.701 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 413.586C246.309 413.586 236.931 415.274 231.68 410.397C226.428 405.521 227.085 396.144 227.085 396.144C227.085 396.144 236.462 394.456 241.714 399.238C246.965 404.021 246.309 413.586 246.309 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 413.586C238.713 413.586 248.09 415.274 253.342 410.397C258.593 405.521 257.843 396.144 257.843 396.144C257.843 396.144 248.465 394.456 243.214 399.238C237.963 404.021 238.713 413.586 238.713 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 413.586C291.322 413.586 281.945 415.274 276.693 410.397C271.442 405.521 272.099 396.144 272.099 396.144C272.099 396.144 281.476 394.456 286.727 399.238C291.979 404.021 291.322 413.586 291.322 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 413.586C283.726 413.586 293.104 415.274 298.355 410.397C303.607 405.521 302.857 396.144 302.857 396.144C302.857 396.144 293.479 394.456 288.228 399.238C282.976 404.021 283.726 413.586 283.726 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 413.586C336.334 413.586 326.957 415.274 321.705 410.397C316.454 405.521 317.11 396.144 317.11 396.144C317.11 396.144 326.488 394.456 331.739 399.238C336.99 404.021 336.334 413.586 336.334 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 413.586C328.738 413.586 338.116 415.274 343.367 410.397C348.618 405.521 347.868 396.144 347.868 396.144C347.868 396.144 338.491 394.456 333.239 399.238C327.988 404.021 328.738 413.586 328.738 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 413.586C381.346 413.586 371.968 415.274 366.717 410.397C361.466 405.521 362.122 396.144 362.122 396.144C362.122 396.144 371.499 394.456 376.751 399.238C382.002 404.021 381.346 413.586 381.346 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 413.586C373.75 413.586 383.127 415.274 388.379 410.397C393.63 405.521 392.88 396.144 392.88 396.144C392.88 396.144 383.503 394.456 378.251 399.238C373 404.021 373.75 413.586 373.75 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 413.586C426.358 413.586 416.98 415.274 411.729 410.397C406.477 405.521 407.134 396.144 407.134 396.144C407.134 396.144 416.511 394.456 421.763 399.238C427.014 404.021 426.358 413.586 426.358 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 413.586C418.762 413.586 428.139 415.274 433.391 410.397C438.642 405.521 437.892 396.144 437.892 396.144C437.892 396.144 428.514 394.456 423.263 399.238C418.011 404.021 418.762 413.586 418.762 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 413.586C471.369 413.586 461.992 415.274 456.74 410.397C451.489 405.521 452.145 396.144 452.145 396.144C452.145 396.144 461.523 394.456 466.774 399.238C472.026 404.021 471.369 413.586 471.369 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 413.586C463.773 413.586 473.151 415.274 478.402 410.397C483.654 405.521 482.903 396.144 482.903 396.144C482.903 396.144 473.526 394.456 468.275 399.238C463.023 404.021 463.773 413.586 463.773 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 413.586C516.381 413.586 507.003 415.274 501.752 410.397C496.501 405.521 497.157 396.144 497.157 396.144C497.157 396.144 506.535 394.456 511.786 399.238C517.037 404.021 516.381 413.586 516.381 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 413.586C508.785 413.586 518.163 415.274 523.414 410.397C528.665 405.521 527.915 396.144 527.915 396.144C527.915 396.144 518.538 394.456 513.286 399.238C508.035 404.021 508.785 413.586 508.785 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 413.586C561.393 413.586 552.015 415.274 546.764 410.397C541.512 405.521 542.169 396.144 542.169 396.144C542.169 396.144 551.546 394.456 556.798 399.238C562.049 404.021 561.393 413.586 561.393 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 413.586C553.797 413.586 563.174 415.274 568.426 410.397C573.677 405.521 572.927 396.144 572.927 396.144C572.927 396.144 563.549 394.456 558.298 399.238C553.047 404.021 553.797 413.586 553.797 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 413.586C606.404 413.586 597.027 415.274 591.776 410.397C586.524 405.521 587.181 396.144 587.181 396.144C587.181 396.144 596.558 394.456 601.809 399.238C607.061 404.021 606.404 413.586 606.404 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 413.586C598.809 413.586 608.186 415.274 613.437 410.397C618.689 405.521 617.939 396.144 617.939 396.144C617.939 396.144 608.561 394.456 603.31 399.238C598.058 404.021 598.809 413.586 598.809 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 413.586C651.416 413.586 642.039 415.274 636.787 410.397C631.536 405.521 632.192 396.144 632.192 396.144C632.192 396.144 641.57 394.456 646.821 399.238C652.073 404.021 651.416 413.586 651.416 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 413.586C643.82 413.586 653.198 415.274 658.449 410.397C663.701 405.521 662.95 396.144 662.95 396.144C662.95 396.144 653.573 394.456 648.321 399.238C643.07 404.021 643.82 413.586 643.82 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 413.586C696.428 413.586 687.05 415.274 681.799 410.397C676.548 405.521 677.204 396.144 677.204 396.144C677.204 396.144 686.581 394.456 691.833 399.238C697.084 404.021 696.428 413.586 696.428 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 413.586C688.832 413.586 698.209 415.274 703.461 410.397C708.712 405.521 707.962 396.144 707.962 396.144C707.962 396.144 698.585 394.456 693.333 399.238C688.082 404.021 688.832 413.586 688.832 413.586Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 446.407C21.2501 446.407 11.8726 448.095 6.62122 443.219C1.36983 438.342 2.02626 428.965 2.02626 428.965C2.02626 428.965 11.4037 427.277 16.6551 432.059C21.9065 436.842 21.2501 446.407 21.2501 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 446.407C13.6542 446.407 23.0317 448.095 28.2831 443.219C33.5345 438.342 32.7843 428.965 32.7843 428.965C32.7843 428.965 23.4068 427.277 18.1554 432.059C12.904 436.842 13.6542 446.407 13.6542 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 446.407C66.2618 446.407 56.8843 448.095 51.6329 443.219C46.3816 438.342 47.038 428.965 47.038 428.965C47.038 428.965 56.4155 427.277 61.6668 432.059C66.9182 436.842 66.2618 446.407 66.2618 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 446.407C58.666 446.407 68.0434 448.095 73.2948 443.219C78.5462 438.342 77.796 428.965 77.796 428.965C77.796 428.965 68.4185 427.277 63.1671 432.059C57.9158 436.842 58.666 446.407 58.666 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 446.407C111.274 446.407 101.896 448.095 96.6447 443.219C91.3933 438.342 92.0497 428.965 92.0497 428.965C92.0497 428.965 101.427 427.277 106.679 432.059C111.93 436.842 111.274 446.407 111.274 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 446.407C103.678 446.407 113.055 448.095 118.307 443.219C123.558 438.342 122.808 428.965 122.808 428.965C122.808 428.965 113.43 427.277 108.179 432.059C102.927 436.842 103.678 446.407 103.678 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 446.407C156.285 446.407 146.908 448.095 141.656 443.219C136.405 438.342 137.061 428.965 137.061 428.965C137.061 428.965 146.439 427.277 151.69 432.059C156.942 436.842 156.285 446.407 156.285 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 446.407C148.689 446.407 158.067 448.095 163.318 443.219C168.57 438.342 167.819 428.965 167.819 428.965C167.819 428.965 158.442 427.277 153.191 432.059C147.939 436.842 148.689 446.407 148.689 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 446.407C201.297 446.407 191.919 448.095 186.668 443.219C181.417 438.342 182.073 428.965 182.073 428.965C182.073 428.965 191.451 427.277 196.702 432.059C201.953 436.842 201.297 446.407 201.297 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 446.407C193.701 446.407 203.079 448.095 208.33 443.219C213.581 438.342 212.831 428.965 212.831 428.965C212.831 428.965 203.454 427.277 198.202 432.059C192.951 436.842 193.701 446.407 193.701 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 446.407C246.309 446.407 236.931 448.095 231.68 443.219C226.428 438.342 227.085 428.965 227.085 428.965C227.085 428.965 236.462 427.277 241.714 432.059C246.965 436.842 246.309 446.407 246.309 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 446.407C238.713 446.407 248.09 448.095 253.342 443.219C258.593 438.342 257.843 428.965 257.843 428.965C257.843 428.965 248.465 427.277 243.214 432.059C237.963 436.842 238.713 446.407 238.713 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 446.407C291.322 446.407 281.945 448.095 276.693 443.219C271.442 438.342 272.099 428.965 272.099 428.965C272.099 428.965 281.476 427.277 286.727 432.059C291.979 436.842 291.322 446.407 291.322 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 446.407C283.726 446.407 293.104 448.095 298.355 443.219C303.607 438.342 302.857 428.965 302.857 428.965C302.857 428.965 293.479 427.277 288.228 432.059C282.976 436.842 283.726 446.407 283.726 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 446.407C336.334 446.407 326.957 448.095 321.705 443.219C316.454 438.342 317.11 428.965 317.11 428.965C317.11 428.965 326.488 427.277 331.739 432.059C336.99 436.842 336.334 446.407 336.334 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 446.407C328.738 446.407 338.116 448.095 343.367 443.219C348.618 438.342 347.868 428.965 347.868 428.965C347.868 428.965 338.491 427.277 333.239 432.059C327.988 436.842 328.738 446.407 328.738 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 446.407C381.346 446.407 371.968 448.095 366.717 443.219C361.466 438.342 362.122 428.965 362.122 428.965C362.122 428.965 371.499 427.277 376.751 432.059C382.002 436.842 381.346 446.407 381.346 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 446.407C373.75 446.407 383.127 448.095 388.379 443.219C393.63 438.342 392.88 428.965 392.88 428.965C392.88 428.965 383.503 427.277 378.251 432.059C373 436.842 373.75 446.407 373.75 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 446.407C426.358 446.407 416.98 448.095 411.729 443.219C406.477 438.342 407.134 428.965 407.134 428.965C407.134 428.965 416.511 427.277 421.763 432.059C427.014 436.842 426.358 446.407 426.358 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 446.407C418.762 446.407 428.139 448.095 433.391 443.219C438.642 438.342 437.892 428.965 437.892 428.965C437.892 428.965 428.514 427.277 423.263 432.059C418.011 436.842 418.762 446.407 418.762 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 446.407C471.369 446.407 461.992 448.095 456.74 443.219C451.489 438.342 452.145 428.965 452.145 428.965C452.145 428.965 461.523 427.277 466.774 432.059C472.026 436.842 471.369 446.407 471.369 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 446.407C463.773 446.407 473.151 448.095 478.402 443.219C483.654 438.342 482.903 428.965 482.903 428.965C482.903 428.965 473.526 427.277 468.275 432.059C463.023 436.842 463.773 446.407 463.773 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 446.407C516.381 446.407 507.003 448.095 501.752 443.219C496.501 438.342 497.157 428.965 497.157 428.965C497.157 428.965 506.535 427.277 511.786 432.059C517.037 436.842 516.381 446.407 516.381 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 446.407C508.785 446.407 518.163 448.095 523.414 443.219C528.665 438.342 527.915 428.965 527.915 428.965C527.915 428.965 518.538 427.277 513.286 432.059C508.035 436.842 508.785 446.407 508.785 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 446.407C561.393 446.407 552.015 448.095 546.764 443.219C541.512 438.342 542.169 428.965 542.169 428.965C542.169 428.965 551.546 427.277 556.798 432.059C562.049 436.842 561.393 446.407 561.393 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 446.407C553.797 446.407 563.174 448.095 568.426 443.219C573.677 438.342 572.927 428.965 572.927 428.965C572.927 428.965 563.549 427.277 558.298 432.059C553.047 436.842 553.797 446.407 553.797 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 446.407C606.404 446.407 597.027 448.095 591.776 443.219C586.524 438.342 587.181 428.965 587.181 428.965C587.181 428.965 596.558 427.277 601.809 432.059C607.061 436.842 606.404 446.407 606.404 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 446.407C598.809 446.407 608.186 448.095 613.437 443.219C618.689 438.342 617.939 428.965 617.939 428.965C617.939 428.965 608.561 427.277 603.31 432.059C598.058 436.842 598.809 446.407 598.809 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 446.407C651.416 446.407 642.039 448.095 636.787 443.219C631.536 438.342 632.192 428.965 632.192 428.965C632.192 428.965 641.57 427.277 646.821 432.059C652.073 436.842 651.416 446.407 651.416 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 446.407C643.82 446.407 653.198 448.095 658.449 443.219C663.701 438.342 662.95 428.965 662.95 428.965C662.95 428.965 653.573 427.277 648.321 432.059C643.07 436.842 643.82 446.407 643.82 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 446.407C696.428 446.407 687.05 448.095 681.799 443.219C676.548 438.342 677.204 428.965 677.204 428.965C677.204 428.965 686.581 427.277 691.833 432.059C697.084 436.842 696.428 446.407 696.428 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 446.407C688.832 446.407 698.209 448.095 703.461 443.219C708.712 438.342 707.962 428.965 707.962 428.965C707.962 428.965 698.585 427.277 693.333 432.059C688.082 436.842 688.832 446.407 688.832 446.407Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 479.227C21.2501 479.227 11.8726 480.915 6.62122 476.039C1.36983 471.163 2.02626 461.785 2.02626 461.785C2.02626 461.785 11.4037 460.097 16.6551 464.88C21.9065 469.662 21.2501 479.227 21.2501 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 479.227C13.6542 479.227 23.0317 480.915 28.2831 476.039C33.5345 471.163 32.7843 461.785 32.7843 461.785C32.7843 461.785 23.4068 460.097 18.1554 464.88C12.904 469.662 13.6542 479.227 13.6542 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 479.227C66.2618 479.227 56.8843 480.915 51.6329 476.039C46.3816 471.163 47.038 461.785 47.038 461.785C47.038 461.785 56.4155 460.097 61.6668 464.88C66.9182 469.662 66.2618 479.227 66.2618 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 479.227C58.666 479.227 68.0434 480.915 73.2948 476.039C78.5462 471.163 77.796 461.785 77.796 461.785C77.796 461.785 68.4185 460.097 63.1671 464.88C57.9158 469.662 58.666 479.227 58.666 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 479.227C111.274 479.227 101.896 480.915 96.6447 476.039C91.3933 471.163 92.0497 461.785 92.0497 461.785C92.0497 461.785 101.427 460.097 106.679 464.88C111.93 469.662 111.274 479.227 111.274 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 479.227C103.678 479.227 113.055 480.915 118.307 476.039C123.558 471.163 122.808 461.785 122.808 461.785C122.808 461.785 113.43 460.097 108.179 464.88C102.927 469.662 103.678 479.227 103.678 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 479.227C156.285 479.227 146.908 480.915 141.656 476.039C136.405 471.163 137.061 461.785 137.061 461.785C137.061 461.785 146.439 460.097 151.69 464.88C156.942 469.662 156.285 479.227 156.285 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 479.227C148.689 479.227 158.067 480.915 163.318 476.039C168.57 471.163 167.819 461.785 167.819 461.785C167.819 461.785 158.442 460.097 153.191 464.88C147.939 469.662 148.689 479.227 148.689 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 479.227C201.297 479.227 191.919 480.915 186.668 476.039C181.417 471.163 182.073 461.785 182.073 461.785C182.073 461.785 191.451 460.097 196.702 464.88C201.953 469.662 201.297 479.227 201.297 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 479.227C193.701 479.227 203.079 480.915 208.33 476.039C213.581 471.163 212.831 461.785 212.831 461.785C212.831 461.785 203.454 460.097 198.202 464.88C192.951 469.662 193.701 479.227 193.701 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 479.227C246.309 479.227 236.931 480.915 231.68 476.039C226.428 471.163 227.085 461.785 227.085 461.785C227.085 461.785 236.462 460.097 241.714 464.88C246.965 469.662 246.309 479.227 246.309 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 479.227C238.713 479.227 248.09 480.915 253.342 476.039C258.593 471.163 257.843 461.785 257.843 461.785C257.843 461.785 248.465 460.097 243.214 464.88C237.963 469.662 238.713 479.227 238.713 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 479.227C291.322 479.227 281.945 480.915 276.693 476.039C271.442 471.163 272.099 461.785 272.099 461.785C272.099 461.785 281.476 460.097 286.727 464.88C291.979 469.662 291.322 479.227 291.322 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 479.227C283.726 479.227 293.104 480.915 298.355 476.039C303.607 471.163 302.857 461.785 302.857 461.785C302.857 461.785 293.479 460.097 288.228 464.88C282.976 469.662 283.726 479.227 283.726 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 479.227C336.334 479.227 326.957 480.915 321.705 476.039C316.454 471.163 317.11 461.785 317.11 461.785C317.11 461.785 326.488 460.097 331.739 464.88C336.99 469.662 336.334 479.227 336.334 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 479.227C328.738 479.227 338.116 480.915 343.367 476.039C348.618 471.163 347.868 461.785 347.868 461.785C347.868 461.785 338.491 460.097 333.239 464.88C327.988 469.662 328.738 479.227 328.738 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 479.227C381.346 479.227 371.968 480.915 366.717 476.039C361.466 471.163 362.122 461.785 362.122 461.785C362.122 461.785 371.499 460.097 376.751 464.88C382.002 469.662 381.346 479.227 381.346 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 479.227C373.75 479.227 383.127 480.915 388.379 476.039C393.63 471.163 392.88 461.785 392.88 461.785C392.88 461.785 383.503 460.097 378.251 464.88C373 469.662 373.75 479.227 373.75 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 479.227C426.358 479.227 416.98 480.915 411.729 476.039C406.477 471.163 407.134 461.785 407.134 461.785C407.134 461.785 416.511 460.097 421.763 464.88C427.014 469.662 426.358 479.227 426.358 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 479.227C418.762 479.227 428.139 480.915 433.391 476.039C438.642 471.163 437.892 461.785 437.892 461.785C437.892 461.785 428.514 460.097 423.263 464.88C418.011 469.662 418.762 479.227 418.762 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 479.227C471.369 479.227 461.992 480.915 456.74 476.039C451.489 471.163 452.145 461.785 452.145 461.785C452.145 461.785 461.523 460.097 466.774 464.88C472.026 469.662 471.369 479.227 471.369 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 479.227C463.773 479.227 473.151 480.915 478.402 476.039C483.654 471.163 482.903 461.785 482.903 461.785C482.903 461.785 473.526 460.097 468.275 464.88C463.023 469.662 463.773 479.227 463.773 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 479.227C516.381 479.227 507.003 480.915 501.752 476.039C496.501 471.163 497.157 461.785 497.157 461.785C497.157 461.785 506.535 460.097 511.786 464.88C517.037 469.662 516.381 479.227 516.381 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 479.227C508.785 479.227 518.163 480.915 523.414 476.039C528.665 471.163 527.915 461.785 527.915 461.785C527.915 461.785 518.538 460.097 513.286 464.88C508.035 469.662 508.785 479.227 508.785 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 479.227C561.393 479.227 552.015 480.915 546.764 476.039C541.512 471.163 542.169 461.785 542.169 461.785C542.169 461.785 551.546 460.097 556.798 464.88C562.049 469.662 561.393 479.227 561.393 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 479.227C553.797 479.227 563.174 480.915 568.426 476.039C573.677 471.163 572.927 461.785 572.927 461.785C572.927 461.785 563.549 460.097 558.298 464.88C553.047 469.662 553.797 479.227 553.797 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 479.227C606.404 479.227 597.027 480.915 591.776 476.039C586.524 471.163 587.181 461.785 587.181 461.785C587.181 461.785 596.558 460.097 601.809 464.88C607.061 469.662 606.404 479.227 606.404 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 479.227C598.809 479.227 608.186 480.915 613.437 476.039C618.689 471.163 617.939 461.785 617.939 461.785C617.939 461.785 608.561 460.097 603.31 464.88C598.058 469.662 598.809 479.227 598.809 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 479.227C651.416 479.227 642.039 480.915 636.787 476.039C631.536 471.163 632.192 461.785 632.192 461.785C632.192 461.785 641.57 460.097 646.821 464.88C652.073 469.662 651.416 479.227 651.416 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 479.227C643.82 479.227 653.198 480.915 658.449 476.039C663.701 471.163 662.95 461.785 662.95 461.785C662.95 461.785 653.573 460.097 648.321 464.88C643.07 469.662 643.82 479.227 643.82 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 479.227C696.428 479.227 687.05 480.915 681.799 476.039C676.548 471.163 677.204 461.785 677.204 461.785C677.204 461.785 686.581 460.097 691.833 464.88C697.084 469.662 696.428 479.227 696.428 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 479.227C688.832 479.227 698.209 480.915 703.461 476.039C708.712 471.163 707.962 461.785 707.962 461.785C707.962 461.785 698.585 460.097 693.333 464.88C688.082 469.662 688.832 479.227 688.832 479.227Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 512.049C21.2501 512.049 11.8726 513.736 6.62122 508.86C1.36983 503.984 2.02626 494.606 2.02626 494.606C2.02626 494.606 11.4037 492.918 16.6551 497.701C21.9065 502.483 21.2501 512.049 21.2501 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 512.049C13.6542 512.049 23.0317 513.736 28.2831 508.86C33.5345 503.984 32.7843 494.606 32.7843 494.606C32.7843 494.606 23.4068 492.918 18.1554 497.701C12.904 502.483 13.6542 512.049 13.6542 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 512.049C66.2618 512.049 56.8843 513.736 51.6329 508.86C46.3816 503.984 47.038 494.606 47.038 494.606C47.038 494.606 56.4155 492.918 61.6668 497.701C66.9182 502.483 66.2618 512.049 66.2618 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 512.049C58.666 512.049 68.0434 513.736 73.2948 508.86C78.5462 503.984 77.796 494.606 77.796 494.606C77.796 494.606 68.4185 492.918 63.1671 497.701C57.9158 502.483 58.666 512.049 58.666 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 512.049C111.274 512.049 101.896 513.736 96.6447 508.86C91.3933 503.984 92.0497 494.606 92.0497 494.606C92.0497 494.606 101.427 492.918 106.679 497.701C111.93 502.483 111.274 512.049 111.274 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 512.049C103.678 512.049 113.055 513.736 118.307 508.86C123.558 503.984 122.808 494.606 122.808 494.606C122.808 494.606 113.43 492.918 108.179 497.701C102.927 502.483 103.678 512.049 103.678 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 512.049C156.285 512.049 146.908 513.736 141.656 508.86C136.405 503.984 137.061 494.606 137.061 494.606C137.061 494.606 146.439 492.918 151.69 497.701C156.942 502.483 156.285 512.049 156.285 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 512.049C148.689 512.049 158.067 513.736 163.318 508.86C168.57 503.984 167.819 494.606 167.819 494.606C167.819 494.606 158.442 492.918 153.191 497.701C147.939 502.483 148.689 512.049 148.689 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 512.049C201.297 512.049 191.919 513.736 186.668 508.86C181.417 503.984 182.073 494.606 182.073 494.606C182.073 494.606 191.451 492.918 196.702 497.701C201.953 502.483 201.297 512.049 201.297 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 512.049C193.701 512.049 203.079 513.736 208.33 508.86C213.581 503.984 212.831 494.606 212.831 494.606C212.831 494.606 203.454 492.918 198.202 497.701C192.951 502.483 193.701 512.049 193.701 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 512.049C246.309 512.049 236.931 513.736 231.68 508.86C226.428 503.984 227.085 494.606 227.085 494.606C227.085 494.606 236.462 492.918 241.714 497.701C246.965 502.483 246.309 512.049 246.309 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 512.049C238.713 512.049 248.09 513.736 253.342 508.86C258.593 503.984 257.843 494.606 257.843 494.606C257.843 494.606 248.465 492.918 243.214 497.701C237.963 502.483 238.713 512.049 238.713 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 512.049C291.322 512.049 281.945 513.736 276.693 508.86C271.442 503.984 272.099 494.606 272.099 494.606C272.099 494.606 281.476 492.918 286.727 497.701C291.979 502.483 291.322 512.049 291.322 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 512.049C283.726 512.049 293.104 513.736 298.355 508.86C303.607 503.984 302.857 494.606 302.857 494.606C302.857 494.606 293.479 492.918 288.228 497.701C282.976 502.483 283.726 512.049 283.726 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 512.049C336.334 512.049 326.957 513.736 321.705 508.86C316.454 503.984 317.11 494.606 317.11 494.606C317.11 494.606 326.488 492.918 331.739 497.701C336.99 502.483 336.334 512.049 336.334 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 512.049C328.738 512.049 338.116 513.736 343.367 508.86C348.618 503.984 347.868 494.606 347.868 494.606C347.868 494.606 338.491 492.918 333.239 497.701C327.988 502.483 328.738 512.049 328.738 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 512.049C381.346 512.049 371.968 513.736 366.717 508.86C361.466 503.984 362.122 494.606 362.122 494.606C362.122 494.606 371.499 492.918 376.751 497.701C382.002 502.483 381.346 512.049 381.346 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 512.049C373.75 512.049 383.127 513.736 388.379 508.86C393.63 503.984 392.88 494.606 392.88 494.606C392.88 494.606 383.503 492.918 378.251 497.701C373 502.483 373.75 512.049 373.75 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 512.049C426.358 512.049 416.98 513.736 411.729 508.86C406.477 503.984 407.134 494.606 407.134 494.606C407.134 494.606 416.511 492.918 421.763 497.701C427.014 502.483 426.358 512.049 426.358 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 512.049C418.762 512.049 428.139 513.736 433.391 508.86C438.642 503.984 437.892 494.606 437.892 494.606C437.892 494.606 428.514 492.918 423.263 497.701C418.011 502.483 418.762 512.049 418.762 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 512.049C471.369 512.049 461.992 513.736 456.74 508.86C451.489 503.984 452.145 494.606 452.145 494.606C452.145 494.606 461.523 492.918 466.774 497.701C472.026 502.483 471.369 512.049 471.369 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 512.049C463.773 512.049 473.151 513.736 478.402 508.86C483.654 503.984 482.903 494.606 482.903 494.606C482.903 494.606 473.526 492.918 468.275 497.701C463.023 502.483 463.773 512.049 463.773 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 512.049C516.381 512.049 507.003 513.736 501.752 508.86C496.501 503.984 497.157 494.606 497.157 494.606C497.157 494.606 506.535 492.918 511.786 497.701C517.037 502.483 516.381 512.049 516.381 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 512.049C508.785 512.049 518.163 513.736 523.414 508.86C528.665 503.984 527.915 494.606 527.915 494.606C527.915 494.606 518.538 492.918 513.286 497.701C508.035 502.483 508.785 512.049 508.785 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 512.049C561.393 512.049 552.015 513.736 546.764 508.86C541.512 503.984 542.169 494.606 542.169 494.606C542.169 494.606 551.546 492.918 556.798 497.701C562.049 502.483 561.393 512.049 561.393 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 512.049C553.797 512.049 563.174 513.736 568.426 508.86C573.677 503.984 572.927 494.606 572.927 494.606C572.927 494.606 563.549 492.918 558.298 497.701C553.047 502.483 553.797 512.049 553.797 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 512.049C606.404 512.049 597.027 513.736 591.776 508.86C586.524 503.984 587.181 494.606 587.181 494.606C587.181 494.606 596.558 492.918 601.809 497.701C607.061 502.483 606.404 512.049 606.404 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 512.049C598.809 512.049 608.186 513.736 613.437 508.86C618.689 503.984 617.939 494.606 617.939 494.606C617.939 494.606 608.561 492.918 603.31 497.701C598.058 502.483 598.809 512.049 598.809 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 512.049C651.416 512.049 642.039 513.736 636.787 508.86C631.536 503.984 632.192 494.606 632.192 494.606C632.192 494.606 641.57 492.918 646.821 497.701C652.073 502.483 651.416 512.049 651.416 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 512.049C643.82 512.049 653.198 513.736 658.449 508.86C663.701 503.984 662.95 494.606 662.95 494.606C662.95 494.606 653.573 492.918 648.321 497.701C643.07 502.483 643.82 512.049 643.82 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 512.049C696.428 512.049 687.05 513.736 681.799 508.86C676.548 503.984 677.204 494.606 677.204 494.606C677.204 494.606 686.581 492.918 691.833 497.701C697.084 502.483 696.428 512.049 696.428 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 512.049C688.832 512.049 698.209 513.736 703.461 508.86C708.712 503.984 707.962 494.606 707.962 494.606C707.962 494.606 698.585 492.918 693.333 497.701C688.082 502.483 688.832 512.049 688.832 512.049Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 544.87C21.2501 544.87 11.8726 546.558 6.62122 541.681C1.36983 536.805 2.02626 527.428 2.02626 527.428C2.02626 527.428 11.4037 525.74 16.6551 530.522C21.9065 535.305 21.2501 544.87 21.2501 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 544.87C13.6542 544.87 23.0317 546.558 28.2831 541.681C33.5345 536.805 32.7843 527.428 32.7843 527.428C32.7843 527.428 23.4068 525.74 18.1554 530.522C12.904 535.305 13.6542 544.87 13.6542 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 544.87C66.2618 544.87 56.8843 546.558 51.6329 541.681C46.3816 536.805 47.038 527.428 47.038 527.428C47.038 527.428 56.4155 525.74 61.6668 530.522C66.9182 535.305 66.2618 544.87 66.2618 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 544.87C58.666 544.87 68.0434 546.558 73.2948 541.681C78.5462 536.805 77.796 527.428 77.796 527.428C77.796 527.428 68.4185 525.74 63.1671 530.522C57.9158 535.305 58.666 544.87 58.666 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 544.87C111.274 544.87 101.896 546.558 96.6447 541.681C91.3933 536.805 92.0497 527.428 92.0497 527.428C92.0497 527.428 101.427 525.74 106.679 530.522C111.93 535.305 111.274 544.87 111.274 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 544.87C103.678 544.87 113.055 546.558 118.307 541.681C123.558 536.805 122.808 527.428 122.808 527.428C122.808 527.428 113.43 525.74 108.179 530.522C102.927 535.305 103.678 544.87 103.678 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 544.87C156.285 544.87 146.908 546.558 141.656 541.681C136.405 536.805 137.061 527.428 137.061 527.428C137.061 527.428 146.439 525.74 151.69 530.522C156.942 535.305 156.285 544.87 156.285 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 544.87C148.689 544.87 158.067 546.558 163.318 541.681C168.57 536.805 167.819 527.428 167.819 527.428C167.819 527.428 158.442 525.74 153.191 530.522C147.939 535.305 148.689 544.87 148.689 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 544.87C201.297 544.87 191.919 546.558 186.668 541.681C181.417 536.805 182.073 527.428 182.073 527.428C182.073 527.428 191.451 525.74 196.702 530.522C201.953 535.305 201.297 544.87 201.297 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 544.87C193.701 544.87 203.079 546.558 208.33 541.681C213.581 536.805 212.831 527.428 212.831 527.428C212.831 527.428 203.454 525.74 198.202 530.522C192.951 535.305 193.701 544.87 193.701 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 544.87C246.309 544.87 236.931 546.558 231.68 541.681C226.428 536.805 227.085 527.428 227.085 527.428C227.085 527.428 236.462 525.74 241.714 530.522C246.965 535.305 246.309 544.87 246.309 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 544.87C238.713 544.87 248.09 546.558 253.342 541.681C258.593 536.805 257.843 527.428 257.843 527.428C257.843 527.428 248.465 525.74 243.214 530.522C237.963 535.305 238.713 544.87 238.713 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 544.87C291.322 544.87 281.945 546.558 276.693 541.681C271.442 536.805 272.099 527.428 272.099 527.428C272.099 527.428 281.476 525.74 286.727 530.522C291.979 535.305 291.322 544.87 291.322 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 544.87C283.726 544.87 293.104 546.558 298.355 541.681C303.607 536.805 302.857 527.428 302.857 527.428C302.857 527.428 293.479 525.74 288.228 530.522C282.976 535.305 283.726 544.87 283.726 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 544.87C336.334 544.87 326.957 546.558 321.705 541.681C316.454 536.805 317.11 527.428 317.11 527.428C317.11 527.428 326.488 525.74 331.739 530.522C336.99 535.305 336.334 544.87 336.334 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 544.87C328.738 544.87 338.116 546.558 343.367 541.681C348.618 536.805 347.868 527.428 347.868 527.428C347.868 527.428 338.491 525.74 333.239 530.522C327.988 535.305 328.738 544.87 328.738 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 544.87C381.346 544.87 371.968 546.558 366.717 541.681C361.466 536.805 362.122 527.428 362.122 527.428C362.122 527.428 371.499 525.74 376.751 530.522C382.002 535.305 381.346 544.87 381.346 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 544.87C373.75 544.87 383.127 546.558 388.379 541.681C393.63 536.805 392.88 527.428 392.88 527.428C392.88 527.428 383.503 525.74 378.251 530.522C373 535.305 373.75 544.87 373.75 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 544.87C426.358 544.87 416.98 546.558 411.729 541.681C406.477 536.805 407.134 527.428 407.134 527.428C407.134 527.428 416.511 525.74 421.763 530.522C427.014 535.305 426.358 544.87 426.358 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 544.87C418.762 544.87 428.139 546.558 433.391 541.681C438.642 536.805 437.892 527.428 437.892 527.428C437.892 527.428 428.514 525.74 423.263 530.522C418.011 535.305 418.762 544.87 418.762 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 544.87C471.369 544.87 461.992 546.558 456.74 541.681C451.489 536.805 452.145 527.428 452.145 527.428C452.145 527.428 461.523 525.74 466.774 530.522C472.026 535.305 471.369 544.87 471.369 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 544.87C463.773 544.87 473.151 546.558 478.402 541.681C483.654 536.805 482.903 527.428 482.903 527.428C482.903 527.428 473.526 525.74 468.275 530.522C463.023 535.305 463.773 544.87 463.773 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 544.87C516.381 544.87 507.003 546.558 501.752 541.681C496.501 536.805 497.157 527.428 497.157 527.428C497.157 527.428 506.535 525.74 511.786 530.522C517.037 535.305 516.381 544.87 516.381 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 544.87C508.785 544.87 518.163 546.558 523.414 541.681C528.665 536.805 527.915 527.428 527.915 527.428C527.915 527.428 518.538 525.74 513.286 530.522C508.035 535.305 508.785 544.87 508.785 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 544.87C561.393 544.87 552.015 546.558 546.764 541.681C541.512 536.805 542.169 527.428 542.169 527.428C542.169 527.428 551.546 525.74 556.798 530.522C562.049 535.305 561.393 544.87 561.393 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 544.87C553.797 544.87 563.174 546.558 568.426 541.681C573.677 536.805 572.927 527.428 572.927 527.428C572.927 527.428 563.549 525.74 558.298 530.522C553.047 535.305 553.797 544.87 553.797 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 544.87C606.404 544.87 597.027 546.558 591.776 541.681C586.524 536.805 587.181 527.428 587.181 527.428C587.181 527.428 596.558 525.74 601.809 530.522C607.061 535.305 606.404 544.87 606.404 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 544.87C598.809 544.87 608.186 546.558 613.437 541.681C618.689 536.805 617.939 527.428 617.939 527.428C617.939 527.428 608.561 525.74 603.31 530.522C598.058 535.305 598.809 544.87 598.809 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 544.87C651.416 544.87 642.039 546.558 636.787 541.681C631.536 536.805 632.192 527.428 632.192 527.428C632.192 527.428 641.57 525.74 646.821 530.522C652.073 535.305 651.416 544.87 651.416 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 544.87C643.82 544.87 653.198 546.558 658.449 541.681C663.701 536.805 662.95 527.428 662.95 527.428C662.95 527.428 653.573 525.74 648.321 530.522C643.07 535.305 643.82 544.87 643.82 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 544.87C696.428 544.87 687.05 546.558 681.799 541.681C676.548 536.805 677.204 527.428 677.204 527.428C677.204 527.428 686.581 525.74 691.833 530.522C697.084 535.305 696.428 544.87 696.428 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 544.87C688.832 544.87 698.209 546.558 703.461 541.681C708.712 536.805 707.962 527.428 707.962 527.428C707.962 527.428 698.585 525.74 693.333 530.522C688.082 535.305 688.832 544.87 688.832 544.87Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 577.691C21.2501 577.691 11.8726 579.379 6.62122 574.503C1.36983 569.626 2.02626 560.249 2.02626 560.249C2.02626 560.249 11.4037 558.561 16.6551 563.344C21.9065 568.126 21.2501 577.691 21.2501 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 577.691C13.6542 577.691 23.0317 579.379 28.2831 574.503C33.5345 569.626 32.7843 560.249 32.7843 560.249C32.7843 560.249 23.4068 558.561 18.1554 563.344C12.904 568.126 13.6542 577.691 13.6542 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 577.691C66.2618 577.691 56.8843 579.379 51.6329 574.503C46.3816 569.626 47.038 560.249 47.038 560.249C47.038 560.249 56.4155 558.561 61.6668 563.344C66.9182 568.126 66.2618 577.691 66.2618 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 577.691C58.666 577.691 68.0434 579.379 73.2948 574.503C78.5462 569.626 77.796 560.249 77.796 560.249C77.796 560.249 68.4185 558.561 63.1671 563.344C57.9158 568.126 58.666 577.691 58.666 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 577.691C111.274 577.691 101.896 579.379 96.6447 574.503C91.3933 569.626 92.0497 560.249 92.0497 560.249C92.0497 560.249 101.427 558.561 106.679 563.344C111.93 568.126 111.274 577.691 111.274 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 577.691C103.678 577.691 113.055 579.379 118.307 574.503C123.558 569.626 122.808 560.249 122.808 560.249C122.808 560.249 113.43 558.561 108.179 563.344C102.927 568.126 103.678 577.691 103.678 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 577.691C156.285 577.691 146.908 579.379 141.656 574.503C136.405 569.626 137.061 560.249 137.061 560.249C137.061 560.249 146.439 558.561 151.69 563.344C156.942 568.126 156.285 577.691 156.285 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 577.691C148.689 577.691 158.067 579.379 163.318 574.503C168.57 569.626 167.819 560.249 167.819 560.249C167.819 560.249 158.442 558.561 153.191 563.344C147.939 568.126 148.689 577.691 148.689 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 577.691C201.297 577.691 191.919 579.379 186.668 574.503C181.417 569.626 182.073 560.249 182.073 560.249C182.073 560.249 191.451 558.561 196.702 563.344C201.953 568.126 201.297 577.691 201.297 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 577.691C193.701 577.691 203.079 579.379 208.33 574.503C213.581 569.626 212.831 560.249 212.831 560.249C212.831 560.249 203.454 558.561 198.202 563.344C192.951 568.126 193.701 577.691 193.701 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 577.691C246.309 577.691 236.931 579.379 231.68 574.503C226.428 569.626 227.085 560.249 227.085 560.249C227.085 560.249 236.462 558.561 241.714 563.344C246.965 568.126 246.309 577.691 246.309 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 577.691C238.713 577.691 248.09 579.379 253.342 574.503C258.593 569.626 257.843 560.249 257.843 560.249C257.843 560.249 248.465 558.561 243.214 563.344C237.963 568.126 238.713 577.691 238.713 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 577.691C291.322 577.691 281.945 579.379 276.693 574.503C271.442 569.626 272.099 560.249 272.099 560.249C272.099 560.249 281.476 558.561 286.727 563.344C291.979 568.126 291.322 577.691 291.322 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 577.691C283.726 577.691 293.104 579.379 298.355 574.503C303.607 569.626 302.857 560.249 302.857 560.249C302.857 560.249 293.479 558.561 288.228 563.344C282.976 568.126 283.726 577.691 283.726 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 577.691C336.334 577.691 326.957 579.379 321.705 574.503C316.454 569.626 317.11 560.249 317.11 560.249C317.11 560.249 326.488 558.561 331.739 563.344C336.99 568.126 336.334 577.691 336.334 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 577.691C328.738 577.691 338.116 579.379 343.367 574.503C348.618 569.626 347.868 560.249 347.868 560.249C347.868 560.249 338.491 558.561 333.239 563.344C327.988 568.126 328.738 577.691 328.738 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 577.691C381.346 577.691 371.968 579.379 366.717 574.503C361.466 569.626 362.122 560.249 362.122 560.249C362.122 560.249 371.499 558.561 376.751 563.344C382.002 568.126 381.346 577.691 381.346 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 577.691C373.75 577.691 383.127 579.379 388.379 574.503C393.63 569.626 392.88 560.249 392.88 560.249C392.88 560.249 383.503 558.561 378.251 563.344C373 568.126 373.75 577.691 373.75 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 577.691C426.358 577.691 416.98 579.379 411.729 574.503C406.477 569.626 407.134 560.249 407.134 560.249C407.134 560.249 416.511 558.561 421.763 563.344C427.014 568.126 426.358 577.691 426.358 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 577.691C418.762 577.691 428.139 579.379 433.391 574.503C438.642 569.626 437.892 560.249 437.892 560.249C437.892 560.249 428.514 558.561 423.263 563.344C418.011 568.126 418.762 577.691 418.762 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 577.691C471.369 577.691 461.992 579.379 456.74 574.503C451.489 569.626 452.145 560.249 452.145 560.249C452.145 560.249 461.523 558.561 466.774 563.344C472.026 568.126 471.369 577.691 471.369 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 577.691C463.773 577.691 473.151 579.379 478.402 574.503C483.654 569.626 482.903 560.249 482.903 560.249C482.903 560.249 473.526 558.561 468.275 563.344C463.023 568.126 463.773 577.691 463.773 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 577.691C516.381 577.691 507.003 579.379 501.752 574.503C496.501 569.626 497.157 560.249 497.157 560.249C497.157 560.249 506.535 558.561 511.786 563.344C517.037 568.126 516.381 577.691 516.381 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 577.691C508.785 577.691 518.163 579.379 523.414 574.503C528.665 569.626 527.915 560.249 527.915 560.249C527.915 560.249 518.538 558.561 513.286 563.344C508.035 568.126 508.785 577.691 508.785 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 577.691C561.393 577.691 552.015 579.379 546.764 574.503C541.512 569.626 542.169 560.249 542.169 560.249C542.169 560.249 551.546 558.561 556.798 563.344C562.049 568.126 561.393 577.691 561.393 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 577.691C553.797 577.691 563.174 579.379 568.426 574.503C573.677 569.626 572.927 560.249 572.927 560.249C572.927 560.249 563.549 558.561 558.298 563.344C553.047 568.126 553.797 577.691 553.797 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 577.691C606.404 577.691 597.027 579.379 591.776 574.503C586.524 569.626 587.181 560.249 587.181 560.249C587.181 560.249 596.558 558.561 601.809 563.344C607.061 568.126 606.404 577.691 606.404 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 577.691C598.809 577.691 608.186 579.379 613.437 574.503C618.689 569.626 617.939 560.249 617.939 560.249C617.939 560.249 608.561 558.561 603.31 563.344C598.058 568.126 598.809 577.691 598.809 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 577.691C651.416 577.691 642.039 579.379 636.787 574.503C631.536 569.626 632.192 560.249 632.192 560.249C632.192 560.249 641.57 558.561 646.821 563.344C652.073 568.126 651.416 577.691 651.416 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 577.691C643.82 577.691 653.198 579.379 658.449 574.503C663.701 569.626 662.95 560.249 662.95 560.249C662.95 560.249 653.573 558.561 648.321 563.344C643.07 568.126 643.82 577.691 643.82 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 577.691C696.428 577.691 687.05 579.379 681.799 574.503C676.548 569.626 677.204 560.249 677.204 560.249C677.204 560.249 686.581 558.561 691.833 563.344C697.084 568.126 696.428 577.691 696.428 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 577.691C688.832 577.691 698.209 579.379 703.461 574.503C708.712 569.626 707.962 560.249 707.962 560.249C707.962 560.249 698.585 558.561 693.333 563.344C688.082 568.126 688.832 577.691 688.832 577.691Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 610.512C21.2501 610.512 11.8726 612.2 6.62122 607.324C1.36983 602.448 2.02626 593.07 2.02626 593.07C2.02626 593.07 11.4037 591.382 16.6551 596.165C21.9065 600.947 21.2501 610.512 21.2501 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 610.512C13.6542 610.512 23.0317 612.2 28.2831 607.324C33.5345 602.448 32.7843 593.07 32.7843 593.07C32.7843 593.07 23.4068 591.382 18.1554 596.165C12.904 600.947 13.6542 610.512 13.6542 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 610.512C66.2618 610.512 56.8843 612.2 51.6329 607.324C46.3816 602.448 47.038 593.07 47.038 593.07C47.038 593.07 56.4155 591.382 61.6668 596.165C66.9182 600.947 66.2618 610.512 66.2618 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 610.512C58.666 610.512 68.0434 612.2 73.2948 607.324C78.5462 602.448 77.796 593.07 77.796 593.07C77.796 593.07 68.4185 591.382 63.1671 596.165C57.9158 600.947 58.666 610.512 58.666 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 610.512C111.274 610.512 101.896 612.2 96.6447 607.324C91.3933 602.448 92.0497 593.07 92.0497 593.07C92.0497 593.07 101.427 591.382 106.679 596.165C111.93 600.947 111.274 610.512 111.274 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 610.512C103.678 610.512 113.055 612.2 118.307 607.324C123.558 602.448 122.808 593.07 122.808 593.07C122.808 593.07 113.43 591.382 108.179 596.165C102.927 600.947 103.678 610.512 103.678 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 610.512C156.285 610.512 146.908 612.2 141.656 607.324C136.405 602.448 137.061 593.07 137.061 593.07C137.061 593.07 146.439 591.382 151.69 596.165C156.942 600.947 156.285 610.512 156.285 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 610.512C148.689 610.512 158.067 612.2 163.318 607.324C168.57 602.448 167.819 593.07 167.819 593.07C167.819 593.07 158.442 591.382 153.191 596.165C147.939 600.947 148.689 610.512 148.689 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 610.512C201.297 610.512 191.919 612.2 186.668 607.324C181.417 602.448 182.073 593.07 182.073 593.07C182.073 593.07 191.451 591.382 196.702 596.165C201.953 600.947 201.297 610.512 201.297 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 610.512C193.701 610.512 203.079 612.2 208.33 607.324C213.581 602.448 212.831 593.07 212.831 593.07C212.831 593.07 203.454 591.382 198.202 596.165C192.951 600.947 193.701 610.512 193.701 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 610.512C246.309 610.512 236.931 612.2 231.68 607.324C226.428 602.448 227.085 593.07 227.085 593.07C227.085 593.07 236.462 591.382 241.714 596.165C246.965 600.947 246.309 610.512 246.309 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 610.512C238.713 610.512 248.09 612.2 253.342 607.324C258.593 602.448 257.843 593.07 257.843 593.07C257.843 593.07 248.465 591.382 243.214 596.165C237.963 600.947 238.713 610.512 238.713 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 610.512C291.322 610.512 281.945 612.2 276.693 607.324C271.442 602.448 272.099 593.07 272.099 593.07C272.099 593.07 281.476 591.382 286.727 596.165C291.979 600.947 291.322 610.512 291.322 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 610.512C283.726 610.512 293.104 612.2 298.355 607.324C303.607 602.448 302.857 593.07 302.857 593.07C302.857 593.07 293.479 591.382 288.228 596.165C282.976 600.947 283.726 610.512 283.726 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 610.512C336.334 610.512 326.957 612.2 321.705 607.324C316.454 602.448 317.11 593.07 317.11 593.07C317.11 593.07 326.488 591.382 331.739 596.165C336.99 600.947 336.334 610.512 336.334 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 610.512C328.738 610.512 338.116 612.2 343.367 607.324C348.618 602.448 347.868 593.07 347.868 593.07C347.868 593.07 338.491 591.382 333.239 596.165C327.988 600.947 328.738 610.512 328.738 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 610.512C381.346 610.512 371.968 612.2 366.717 607.324C361.466 602.448 362.122 593.07 362.122 593.07C362.122 593.07 371.499 591.382 376.751 596.165C382.002 600.947 381.346 610.512 381.346 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 610.512C373.75 610.512 383.127 612.2 388.379 607.324C393.63 602.448 392.88 593.07 392.88 593.07C392.88 593.07 383.503 591.382 378.251 596.165C373 600.947 373.75 610.512 373.75 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 610.512C426.358 610.512 416.98 612.2 411.729 607.324C406.477 602.448 407.134 593.07 407.134 593.07C407.134 593.07 416.511 591.382 421.763 596.165C427.014 600.947 426.358 610.512 426.358 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 610.512C418.762 610.512 428.139 612.2 433.391 607.324C438.642 602.448 437.892 593.07 437.892 593.07C437.892 593.07 428.514 591.382 423.263 596.165C418.011 600.947 418.762 610.512 418.762 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 610.512C471.369 610.512 461.992 612.2 456.74 607.324C451.489 602.448 452.145 593.07 452.145 593.07C452.145 593.07 461.523 591.382 466.774 596.165C472.026 600.947 471.369 610.512 471.369 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 610.512C463.773 610.512 473.151 612.2 478.402 607.324C483.654 602.448 482.903 593.07 482.903 593.07C482.903 593.07 473.526 591.382 468.275 596.165C463.023 600.947 463.773 610.512 463.773 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 610.512C516.381 610.512 507.003 612.2 501.752 607.324C496.501 602.448 497.157 593.07 497.157 593.07C497.157 593.07 506.535 591.382 511.786 596.165C517.037 600.947 516.381 610.512 516.381 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 610.512C508.785 610.512 518.163 612.2 523.414 607.324C528.665 602.448 527.915 593.07 527.915 593.07C527.915 593.07 518.538 591.382 513.286 596.165C508.035 600.947 508.785 610.512 508.785 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 610.512C561.393 610.512 552.015 612.2 546.764 607.324C541.512 602.448 542.169 593.07 542.169 593.07C542.169 593.07 551.546 591.382 556.798 596.165C562.049 600.947 561.393 610.512 561.393 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 610.512C553.797 610.512 563.174 612.2 568.426 607.324C573.677 602.448 572.927 593.07 572.927 593.07C572.927 593.07 563.549 591.382 558.298 596.165C553.047 600.947 553.797 610.512 553.797 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 610.512C606.404 610.512 597.027 612.2 591.776 607.324C586.524 602.448 587.181 593.07 587.181 593.07C587.181 593.07 596.558 591.382 601.809 596.165C607.061 600.947 606.404 610.512 606.404 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 610.512C598.809 610.512 608.186 612.2 613.437 607.324C618.689 602.448 617.939 593.07 617.939 593.07C617.939 593.07 608.561 591.382 603.31 596.165C598.058 600.947 598.809 610.512 598.809 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 610.512C651.416 610.512 642.039 612.2 636.787 607.324C631.536 602.448 632.192 593.07 632.192 593.07C632.192 593.07 641.57 591.382 646.821 596.165C652.073 600.947 651.416 610.512 651.416 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 610.512C643.82 610.512 653.198 612.2 658.449 607.324C663.701 602.448 662.95 593.07 662.95 593.07C662.95 593.07 653.573 591.382 648.321 596.165C643.07 600.947 643.82 610.512 643.82 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 610.512C696.428 610.512 687.05 612.2 681.799 607.324C676.548 602.448 677.204 593.07 677.204 593.07C677.204 593.07 686.581 591.382 691.833 596.165C697.084 600.947 696.428 610.512 696.428 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 610.512C688.832 610.512 698.209 612.2 703.461 607.324C708.712 602.448 707.962 593.07 707.962 593.07C707.962 593.07 698.585 591.382 693.333 596.165C688.082 600.947 688.832 610.512 688.832 610.512Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 643.334C21.2501 643.334 11.8726 645.022 6.62122 640.145C1.36983 635.269 2.02626 625.892 2.02626 625.892C2.02626 625.892 11.4037 624.204 16.6551 628.986C21.9065 633.769 21.2501 643.334 21.2501 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 643.334C13.6542 643.334 23.0317 645.022 28.2831 640.145C33.5345 635.269 32.7843 625.892 32.7843 625.892C32.7843 625.892 23.4068 624.204 18.1554 628.986C12.904 633.769 13.6542 643.334 13.6542 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 643.334C66.2618 643.334 56.8843 645.022 51.6329 640.145C46.3816 635.269 47.038 625.892 47.038 625.892C47.038 625.892 56.4155 624.204 61.6668 628.986C66.9182 633.769 66.2618 643.334 66.2618 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 643.334C58.666 643.334 68.0434 645.022 73.2948 640.145C78.5462 635.269 77.796 625.892 77.796 625.892C77.796 625.892 68.4185 624.204 63.1671 628.986C57.9158 633.769 58.666 643.334 58.666 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 643.334C111.274 643.334 101.896 645.022 96.6447 640.145C91.3933 635.269 92.0497 625.892 92.0497 625.892C92.0497 625.892 101.427 624.204 106.679 628.986C111.93 633.769 111.274 643.334 111.274 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 643.334C103.678 643.334 113.055 645.022 118.307 640.145C123.558 635.269 122.808 625.892 122.808 625.892C122.808 625.892 113.43 624.204 108.179 628.986C102.927 633.769 103.678 643.334 103.678 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 643.334C156.285 643.334 146.908 645.022 141.656 640.145C136.405 635.269 137.061 625.892 137.061 625.892C137.061 625.892 146.439 624.204 151.69 628.986C156.942 633.769 156.285 643.334 156.285 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 643.334C148.689 643.334 158.067 645.022 163.318 640.145C168.57 635.269 167.819 625.892 167.819 625.892C167.819 625.892 158.442 624.204 153.191 628.986C147.939 633.769 148.689 643.334 148.689 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 643.334C201.297 643.334 191.919 645.022 186.668 640.145C181.417 635.269 182.073 625.892 182.073 625.892C182.073 625.892 191.451 624.204 196.702 628.986C201.953 633.769 201.297 643.334 201.297 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 643.334C193.701 643.334 203.079 645.022 208.33 640.145C213.581 635.269 212.831 625.892 212.831 625.892C212.831 625.892 203.454 624.204 198.202 628.986C192.951 633.769 193.701 643.334 193.701 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 643.334C246.309 643.334 236.931 645.022 231.68 640.145C226.428 635.269 227.085 625.892 227.085 625.892C227.085 625.892 236.462 624.204 241.714 628.986C246.965 633.769 246.309 643.334 246.309 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 643.334C238.713 643.334 248.09 645.022 253.342 640.145C258.593 635.269 257.843 625.892 257.843 625.892C257.843 625.892 248.465 624.204 243.214 628.986C237.963 633.769 238.713 643.334 238.713 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 643.334C291.322 643.334 281.945 645.022 276.693 640.145C271.442 635.269 272.099 625.892 272.099 625.892C272.099 625.892 281.476 624.204 286.727 628.986C291.979 633.769 291.322 643.334 291.322 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 643.334C283.726 643.334 293.104 645.022 298.355 640.145C303.607 635.269 302.857 625.892 302.857 625.892C302.857 625.892 293.479 624.204 288.228 628.986C282.976 633.769 283.726 643.334 283.726 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 643.334C336.334 643.334 326.957 645.022 321.705 640.145C316.454 635.269 317.11 625.892 317.11 625.892C317.11 625.892 326.488 624.204 331.739 628.986C336.99 633.769 336.334 643.334 336.334 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 643.334C328.738 643.334 338.116 645.022 343.367 640.145C348.618 635.269 347.868 625.892 347.868 625.892C347.868 625.892 338.491 624.204 333.239 628.986C327.988 633.769 328.738 643.334 328.738 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 643.334C381.346 643.334 371.968 645.022 366.717 640.145C361.466 635.269 362.122 625.892 362.122 625.892C362.122 625.892 371.499 624.204 376.751 628.986C382.002 633.769 381.346 643.334 381.346 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 643.334C373.75 643.334 383.127 645.022 388.379 640.145C393.63 635.269 392.88 625.892 392.88 625.892C392.88 625.892 383.503 624.204 378.251 628.986C373 633.769 373.75 643.334 373.75 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 643.334C426.358 643.334 416.98 645.022 411.729 640.145C406.477 635.269 407.134 625.892 407.134 625.892C407.134 625.892 416.511 624.204 421.763 628.986C427.014 633.769 426.358 643.334 426.358 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 643.334C418.762 643.334 428.139 645.022 433.391 640.145C438.642 635.269 437.892 625.892 437.892 625.892C437.892 625.892 428.514 624.204 423.263 628.986C418.011 633.769 418.762 643.334 418.762 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 643.334C471.369 643.334 461.992 645.022 456.74 640.145C451.489 635.269 452.145 625.892 452.145 625.892C452.145 625.892 461.523 624.204 466.774 628.986C472.026 633.769 471.369 643.334 471.369 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 643.334C463.773 643.334 473.151 645.022 478.402 640.145C483.654 635.269 482.903 625.892 482.903 625.892C482.903 625.892 473.526 624.204 468.275 628.986C463.023 633.769 463.773 643.334 463.773 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 643.334C516.381 643.334 507.003 645.022 501.752 640.145C496.501 635.269 497.157 625.892 497.157 625.892C497.157 625.892 506.535 624.204 511.786 628.986C517.037 633.769 516.381 643.334 516.381 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 643.334C508.785 643.334 518.163 645.022 523.414 640.145C528.665 635.269 527.915 625.892 527.915 625.892C527.915 625.892 518.538 624.204 513.286 628.986C508.035 633.769 508.785 643.334 508.785 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 643.334C561.393 643.334 552.015 645.022 546.764 640.145C541.512 635.269 542.169 625.892 542.169 625.892C542.169 625.892 551.546 624.204 556.798 628.986C562.049 633.769 561.393 643.334 561.393 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 643.334C553.797 643.334 563.174 645.022 568.426 640.145C573.677 635.269 572.927 625.892 572.927 625.892C572.927 625.892 563.549 624.204 558.298 628.986C553.047 633.769 553.797 643.334 553.797 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 643.334C606.404 643.334 597.027 645.022 591.776 640.145C586.524 635.269 587.181 625.892 587.181 625.892C587.181 625.892 596.558 624.204 601.809 628.986C607.061 633.769 606.404 643.334 606.404 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 643.334C598.809 643.334 608.186 645.022 613.437 640.145C618.689 635.269 617.939 625.892 617.939 625.892C617.939 625.892 608.561 624.204 603.31 628.986C598.058 633.769 598.809 643.334 598.809 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 643.334C651.416 643.334 642.039 645.022 636.787 640.145C631.536 635.269 632.192 625.892 632.192 625.892C632.192 625.892 641.57 624.204 646.821 628.986C652.073 633.769 651.416 643.334 651.416 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 643.334C643.82 643.334 653.198 645.022 658.449 640.145C663.701 635.269 662.95 625.892 662.95 625.892C662.95 625.892 653.573 624.204 648.321 628.986C643.07 633.769 643.82 643.334 643.82 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 643.334C696.428 643.334 687.05 645.022 681.799 640.145C676.548 635.269 677.204 625.892 677.204 625.892C677.204 625.892 686.581 624.204 691.833 628.986C697.084 633.769 696.428 643.334 696.428 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 643.334C688.832 643.334 698.209 645.022 703.461 640.145C708.712 635.269 707.962 625.892 707.962 625.892C707.962 625.892 698.585 624.204 693.333 628.986C688.082 633.769 688.832 643.334 688.832 643.334Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 676.155C21.2501 676.155 11.8726 677.843 6.62122 672.967C1.36983 668.09 2.02626 658.713 2.02626 658.713C2.02626 658.713 11.4037 657.025 16.6551 661.807C21.9065 666.59 21.2501 676.155 21.2501 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 676.155C13.6542 676.155 23.0317 677.843 28.2831 672.967C33.5345 668.09 32.7843 658.713 32.7843 658.713C32.7843 658.713 23.4068 657.025 18.1554 661.807C12.904 666.59 13.6542 676.155 13.6542 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 676.155C66.2618 676.155 56.8843 677.843 51.6329 672.967C46.3816 668.09 47.038 658.713 47.038 658.713C47.038 658.713 56.4155 657.025 61.6668 661.807C66.9182 666.59 66.2618 676.155 66.2618 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 676.155C58.666 676.155 68.0434 677.843 73.2948 672.967C78.5462 668.09 77.796 658.713 77.796 658.713C77.796 658.713 68.4185 657.025 63.1671 661.807C57.9158 666.59 58.666 676.155 58.666 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 676.155C111.274 676.155 101.896 677.843 96.6447 672.967C91.3933 668.09 92.0497 658.713 92.0497 658.713C92.0497 658.713 101.427 657.025 106.679 661.807C111.93 666.59 111.274 676.155 111.274 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 676.155C103.678 676.155 113.055 677.843 118.307 672.967C123.558 668.09 122.808 658.713 122.808 658.713C122.808 658.713 113.43 657.025 108.179 661.807C102.927 666.59 103.678 676.155 103.678 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 676.155C156.285 676.155 146.908 677.843 141.656 672.967C136.405 668.09 137.061 658.713 137.061 658.713C137.061 658.713 146.439 657.025 151.69 661.807C156.942 666.59 156.285 676.155 156.285 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 676.155C148.689 676.155 158.067 677.843 163.318 672.967C168.57 668.09 167.819 658.713 167.819 658.713C167.819 658.713 158.442 657.025 153.191 661.807C147.939 666.59 148.689 676.155 148.689 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 676.155C201.297 676.155 191.919 677.843 186.668 672.967C181.417 668.09 182.073 658.713 182.073 658.713C182.073 658.713 191.451 657.025 196.702 661.807C201.953 666.59 201.297 676.155 201.297 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 676.155C193.701 676.155 203.079 677.843 208.33 672.967C213.581 668.09 212.831 658.713 212.831 658.713C212.831 658.713 203.454 657.025 198.202 661.807C192.951 666.59 193.701 676.155 193.701 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 676.155C246.309 676.155 236.931 677.843 231.68 672.967C226.428 668.09 227.085 658.713 227.085 658.713C227.085 658.713 236.462 657.025 241.714 661.807C246.965 666.59 246.309 676.155 246.309 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 676.155C238.713 676.155 248.09 677.843 253.342 672.967C258.593 668.09 257.843 658.713 257.843 658.713C257.843 658.713 248.465 657.025 243.214 661.807C237.963 666.59 238.713 676.155 238.713 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 676.155C291.322 676.155 281.945 677.843 276.693 672.967C271.442 668.09 272.099 658.713 272.099 658.713C272.099 658.713 281.476 657.025 286.727 661.807C291.979 666.59 291.322 676.155 291.322 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 676.155C283.726 676.155 293.104 677.843 298.355 672.967C303.607 668.09 302.857 658.713 302.857 658.713C302.857 658.713 293.479 657.025 288.228 661.807C282.976 666.59 283.726 676.155 283.726 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 676.155C336.334 676.155 326.957 677.843 321.705 672.967C316.454 668.09 317.11 658.713 317.11 658.713C317.11 658.713 326.488 657.025 331.739 661.807C336.99 666.59 336.334 676.155 336.334 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 676.155C328.738 676.155 338.116 677.843 343.367 672.967C348.618 668.09 347.868 658.713 347.868 658.713C347.868 658.713 338.491 657.025 333.239 661.807C327.988 666.59 328.738 676.155 328.738 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 676.155C381.346 676.155 371.968 677.843 366.717 672.967C361.466 668.09 362.122 658.713 362.122 658.713C362.122 658.713 371.499 657.025 376.751 661.807C382.002 666.59 381.346 676.155 381.346 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 676.155C373.75 676.155 383.127 677.843 388.379 672.967C393.63 668.09 392.88 658.713 392.88 658.713C392.88 658.713 383.503 657.025 378.251 661.807C373 666.59 373.75 676.155 373.75 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 676.155C426.358 676.155 416.98 677.843 411.729 672.967C406.477 668.09 407.134 658.713 407.134 658.713C407.134 658.713 416.511 657.025 421.763 661.807C427.014 666.59 426.358 676.155 426.358 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 676.155C418.762 676.155 428.139 677.843 433.391 672.967C438.642 668.09 437.892 658.713 437.892 658.713C437.892 658.713 428.514 657.025 423.263 661.807C418.011 666.59 418.762 676.155 418.762 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 676.155C471.369 676.155 461.992 677.843 456.74 672.967C451.489 668.09 452.145 658.713 452.145 658.713C452.145 658.713 461.523 657.025 466.774 661.807C472.026 666.59 471.369 676.155 471.369 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 676.155C463.773 676.155 473.151 677.843 478.402 672.967C483.654 668.09 482.903 658.713 482.903 658.713C482.903 658.713 473.526 657.025 468.275 661.807C463.023 666.59 463.773 676.155 463.773 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 676.155C516.381 676.155 507.003 677.843 501.752 672.967C496.501 668.09 497.157 658.713 497.157 658.713C497.157 658.713 506.535 657.025 511.786 661.807C517.037 666.59 516.381 676.155 516.381 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 676.155C508.785 676.155 518.163 677.843 523.414 672.967C528.665 668.09 527.915 658.713 527.915 658.713C527.915 658.713 518.538 657.025 513.286 661.807C508.035 666.59 508.785 676.155 508.785 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 676.155C561.393 676.155 552.015 677.843 546.764 672.967C541.512 668.09 542.169 658.713 542.169 658.713C542.169 658.713 551.546 657.025 556.798 661.807C562.049 666.59 561.393 676.155 561.393 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 676.155C553.797 676.155 563.174 677.843 568.426 672.967C573.677 668.09 572.927 658.713 572.927 658.713C572.927 658.713 563.549 657.025 558.298 661.807C553.047 666.59 553.797 676.155 553.797 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 676.155C606.404 676.155 597.027 677.843 591.776 672.967C586.524 668.09 587.181 658.713 587.181 658.713C587.181 658.713 596.558 657.025 601.809 661.807C607.061 666.59 606.404 676.155 606.404 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 676.155C598.809 676.155 608.186 677.843 613.437 672.967C618.689 668.09 617.939 658.713 617.939 658.713C617.939 658.713 608.561 657.025 603.31 661.807C598.058 666.59 598.809 676.155 598.809 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 676.155C651.416 676.155 642.039 677.843 636.787 672.967C631.536 668.09 632.192 658.713 632.192 658.713C632.192 658.713 641.57 657.025 646.821 661.807C652.073 666.59 651.416 676.155 651.416 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 676.155C643.82 676.155 653.198 677.843 658.449 672.967C663.701 668.09 662.95 658.713 662.95 658.713C662.95 658.713 653.573 657.025 648.321 661.807C643.07 666.59 643.82 676.155 643.82 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 676.155C696.428 676.155 687.05 677.843 681.799 672.967C676.548 668.09 677.204 658.713 677.204 658.713C677.204 658.713 686.581 657.025 691.833 661.807C697.084 666.59 696.428 676.155 696.428 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 676.155C688.832 676.155 698.209 677.843 703.461 672.967C708.712 668.09 707.962 658.713 707.962 658.713C707.962 658.713 698.585 657.025 693.333 661.807C688.082 666.59 688.832 676.155 688.832 676.155Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 708.976C21.2501 708.976 11.8726 710.664 6.62122 705.788C1.36983 700.912 2.02626 691.534 2.02626 691.534C2.02626 691.534 11.4037 689.846 16.6551 694.629C21.9065 699.411 21.2501 708.976 21.2501 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 708.976C13.6542 708.976 23.0317 710.664 28.2831 705.788C33.5345 700.912 32.7843 691.534 32.7843 691.534C32.7843 691.534 23.4068 689.846 18.1554 694.629C12.904 699.411 13.6542 708.976 13.6542 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 708.976C66.2618 708.976 56.8843 710.664 51.6329 705.788C46.3816 700.912 47.038 691.534 47.038 691.534C47.038 691.534 56.4155 689.846 61.6668 694.629C66.9182 699.411 66.2618 708.976 66.2618 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 708.976C58.666 708.976 68.0434 710.664 73.2948 705.788C78.5462 700.912 77.796 691.534 77.796 691.534C77.796 691.534 68.4185 689.846 63.1671 694.629C57.9158 699.411 58.666 708.976 58.666 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 708.976C111.274 708.976 101.896 710.664 96.6447 705.788C91.3933 700.912 92.0497 691.534 92.0497 691.534C92.0497 691.534 101.427 689.846 106.679 694.629C111.93 699.411 111.274 708.976 111.274 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 708.976C103.678 708.976 113.055 710.664 118.307 705.788C123.558 700.912 122.808 691.534 122.808 691.534C122.808 691.534 113.43 689.846 108.179 694.629C102.927 699.411 103.678 708.976 103.678 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 708.976C156.285 708.976 146.908 710.664 141.656 705.788C136.405 700.912 137.061 691.534 137.061 691.534C137.061 691.534 146.439 689.846 151.69 694.629C156.942 699.411 156.285 708.976 156.285 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 708.976C148.689 708.976 158.067 710.664 163.318 705.788C168.57 700.912 167.819 691.534 167.819 691.534C167.819 691.534 158.442 689.846 153.191 694.629C147.939 699.411 148.689 708.976 148.689 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 708.976C201.297 708.976 191.919 710.664 186.668 705.788C181.417 700.912 182.073 691.534 182.073 691.534C182.073 691.534 191.451 689.846 196.702 694.629C201.953 699.411 201.297 708.976 201.297 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 708.976C193.701 708.976 203.079 710.664 208.33 705.788C213.581 700.912 212.831 691.534 212.831 691.534C212.831 691.534 203.454 689.846 198.202 694.629C192.951 699.411 193.701 708.976 193.701 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 708.976C246.309 708.976 236.931 710.664 231.68 705.788C226.428 700.912 227.085 691.534 227.085 691.534C227.085 691.534 236.462 689.846 241.714 694.629C246.965 699.411 246.309 708.976 246.309 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 708.976C238.713 708.976 248.09 710.664 253.342 705.788C258.593 700.912 257.843 691.534 257.843 691.534C257.843 691.534 248.465 689.846 243.214 694.629C237.963 699.411 238.713 708.976 238.713 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 708.976C291.322 708.976 281.945 710.664 276.693 705.788C271.442 700.912 272.099 691.534 272.099 691.534C272.099 691.534 281.476 689.846 286.727 694.629C291.979 699.411 291.322 708.976 291.322 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 708.976C283.726 708.976 293.104 710.664 298.355 705.788C303.607 700.912 302.857 691.534 302.857 691.534C302.857 691.534 293.479 689.846 288.228 694.629C282.976 699.411 283.726 708.976 283.726 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 708.976C336.334 708.976 326.957 710.664 321.705 705.788C316.454 700.912 317.11 691.534 317.11 691.534C317.11 691.534 326.488 689.846 331.739 694.629C336.99 699.411 336.334 708.976 336.334 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 708.976C328.738 708.976 338.116 710.664 343.367 705.788C348.618 700.912 347.868 691.534 347.868 691.534C347.868 691.534 338.491 689.846 333.239 694.629C327.988 699.411 328.738 708.976 328.738 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 708.976C381.346 708.976 371.968 710.664 366.717 705.788C361.466 700.912 362.122 691.534 362.122 691.534C362.122 691.534 371.499 689.846 376.751 694.629C382.002 699.411 381.346 708.976 381.346 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 708.976C373.75 708.976 383.127 710.664 388.379 705.788C393.63 700.912 392.88 691.534 392.88 691.534C392.88 691.534 383.503 689.846 378.251 694.629C373 699.411 373.75 708.976 373.75 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 708.976C426.358 708.976 416.98 710.664 411.729 705.788C406.477 700.912 407.134 691.534 407.134 691.534C407.134 691.534 416.511 689.846 421.763 694.629C427.014 699.411 426.358 708.976 426.358 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 708.976C418.762 708.976 428.139 710.664 433.391 705.788C438.642 700.912 437.892 691.534 437.892 691.534C437.892 691.534 428.514 689.846 423.263 694.629C418.011 699.411 418.762 708.976 418.762 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 708.976C471.369 708.976 461.992 710.664 456.74 705.788C451.489 700.912 452.145 691.534 452.145 691.534C452.145 691.534 461.523 689.846 466.774 694.629C472.026 699.411 471.369 708.976 471.369 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 708.976C463.773 708.976 473.151 710.664 478.402 705.788C483.654 700.912 482.903 691.534 482.903 691.534C482.903 691.534 473.526 689.846 468.275 694.629C463.023 699.411 463.773 708.976 463.773 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 708.976C516.381 708.976 507.003 710.664 501.752 705.788C496.501 700.912 497.157 691.534 497.157 691.534C497.157 691.534 506.535 689.846 511.786 694.629C517.037 699.411 516.381 708.976 516.381 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 708.976C508.785 708.976 518.163 710.664 523.414 705.788C528.665 700.912 527.915 691.534 527.915 691.534C527.915 691.534 518.538 689.846 513.286 694.629C508.035 699.411 508.785 708.976 508.785 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 708.976C561.393 708.976 552.015 710.664 546.764 705.788C541.512 700.912 542.169 691.534 542.169 691.534C542.169 691.534 551.546 689.846 556.798 694.629C562.049 699.411 561.393 708.976 561.393 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 708.976C553.797 708.976 563.174 710.664 568.426 705.788C573.677 700.912 572.927 691.534 572.927 691.534C572.927 691.534 563.549 689.846 558.298 694.629C553.047 699.411 553.797 708.976 553.797 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 708.976C606.404 708.976 597.027 710.664 591.776 705.788C586.524 700.912 587.181 691.534 587.181 691.534C587.181 691.534 596.558 689.846 601.809 694.629C607.061 699.411 606.404 708.976 606.404 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 708.976C598.809 708.976 608.186 710.664 613.437 705.788C618.689 700.912 617.939 691.534 617.939 691.534C617.939 691.534 608.561 689.846 603.31 694.629C598.058 699.411 598.809 708.976 598.809 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 708.976C651.416 708.976 642.039 710.664 636.787 705.788C631.536 700.912 632.192 691.534 632.192 691.534C632.192 691.534 641.57 689.846 646.821 694.629C652.073 699.411 651.416 708.976 651.416 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 708.976C643.82 708.976 653.198 710.664 658.449 705.788C663.701 700.912 662.95 691.534 662.95 691.534C662.95 691.534 653.573 689.846 648.321 694.629C643.07 699.411 643.82 708.976 643.82 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 708.976C696.428 708.976 687.05 710.664 681.799 705.788C676.548 700.912 677.204 691.534 677.204 691.534C677.204 691.534 686.581 689.846 691.833 694.629C697.084 699.411 696.428 708.976 696.428 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 708.976C688.832 708.976 698.209 710.664 703.461 705.788C708.712 700.912 707.962 691.534 707.962 691.534C707.962 691.534 698.585 689.846 693.333 694.629C688.082 699.411 688.832 708.976 688.832 708.976Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 741.798C21.2501 741.798 11.8726 743.485 6.62122 738.609C1.36983 733.733 2.02626 724.355 2.02626 724.355C2.02626 724.355 11.4037 722.667 16.6551 727.45C21.9065 732.233 21.2501 741.798 21.2501 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 741.798C13.6542 741.798 23.0317 743.485 28.2831 738.609C33.5345 733.733 32.7843 724.355 32.7843 724.355C32.7843 724.355 23.4068 722.667 18.1554 727.45C12.904 732.233 13.6542 741.798 13.6542 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 741.798C66.2618 741.798 56.8843 743.485 51.6329 738.609C46.3816 733.733 47.038 724.355 47.038 724.355C47.038 724.355 56.4155 722.667 61.6668 727.45C66.9182 732.233 66.2618 741.798 66.2618 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 741.798C58.666 741.798 68.0434 743.485 73.2948 738.609C78.5462 733.733 77.796 724.355 77.796 724.355C77.796 724.355 68.4185 722.667 63.1671 727.45C57.9158 732.233 58.666 741.798 58.666 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 741.798C111.274 741.798 101.896 743.485 96.6447 738.609C91.3933 733.733 92.0497 724.355 92.0497 724.355C92.0497 724.355 101.427 722.667 106.679 727.45C111.93 732.233 111.274 741.798 111.274 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 741.798C103.678 741.798 113.055 743.485 118.307 738.609C123.558 733.733 122.808 724.355 122.808 724.355C122.808 724.355 113.43 722.667 108.179 727.45C102.927 732.233 103.678 741.798 103.678 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 741.798C156.285 741.798 146.908 743.485 141.656 738.609C136.405 733.733 137.061 724.355 137.061 724.355C137.061 724.355 146.439 722.667 151.69 727.45C156.942 732.233 156.285 741.798 156.285 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 741.798C148.689 741.798 158.067 743.485 163.318 738.609C168.57 733.733 167.819 724.355 167.819 724.355C167.819 724.355 158.442 722.667 153.191 727.45C147.939 732.233 148.689 741.798 148.689 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 741.798C201.297 741.798 191.919 743.485 186.668 738.609C181.417 733.733 182.073 724.355 182.073 724.355C182.073 724.355 191.451 722.667 196.702 727.45C201.953 732.233 201.297 741.798 201.297 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 741.798C193.701 741.798 203.079 743.485 208.33 738.609C213.581 733.733 212.831 724.355 212.831 724.355C212.831 724.355 203.454 722.667 198.202 727.45C192.951 732.233 193.701 741.798 193.701 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 741.798C246.309 741.798 236.931 743.485 231.68 738.609C226.428 733.733 227.085 724.355 227.085 724.355C227.085 724.355 236.462 722.667 241.714 727.45C246.965 732.233 246.309 741.798 246.309 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 741.798C238.713 741.798 248.09 743.485 253.342 738.609C258.593 733.733 257.843 724.355 257.843 724.355C257.843 724.355 248.465 722.667 243.214 727.45C237.963 732.233 238.713 741.798 238.713 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 741.798C291.322 741.798 281.945 743.485 276.693 738.609C271.442 733.733 272.099 724.355 272.099 724.355C272.099 724.355 281.476 722.667 286.727 727.45C291.979 732.233 291.322 741.798 291.322 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 741.798C283.726 741.798 293.104 743.485 298.355 738.609C303.607 733.733 302.857 724.355 302.857 724.355C302.857 724.355 293.479 722.667 288.228 727.45C282.976 732.233 283.726 741.798 283.726 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 741.798C336.334 741.798 326.957 743.485 321.705 738.609C316.454 733.733 317.11 724.355 317.11 724.355C317.11 724.355 326.488 722.667 331.739 727.45C336.99 732.233 336.334 741.798 336.334 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 741.798C328.738 741.798 338.116 743.485 343.367 738.609C348.618 733.733 347.868 724.355 347.868 724.355C347.868 724.355 338.491 722.667 333.239 727.45C327.988 732.233 328.738 741.798 328.738 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 741.798C381.346 741.798 371.968 743.485 366.717 738.609C361.466 733.733 362.122 724.355 362.122 724.355C362.122 724.355 371.499 722.667 376.751 727.45C382.002 732.233 381.346 741.798 381.346 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 741.798C373.75 741.798 383.127 743.485 388.379 738.609C393.63 733.733 392.88 724.355 392.88 724.355C392.88 724.355 383.503 722.667 378.251 727.45C373 732.233 373.75 741.798 373.75 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 741.798C426.358 741.798 416.98 743.485 411.729 738.609C406.477 733.733 407.134 724.355 407.134 724.355C407.134 724.355 416.511 722.667 421.763 727.45C427.014 732.233 426.358 741.798 426.358 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 741.798C418.762 741.798 428.139 743.485 433.391 738.609C438.642 733.733 437.892 724.355 437.892 724.355C437.892 724.355 428.514 722.667 423.263 727.45C418.011 732.233 418.762 741.798 418.762 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 741.798C471.369 741.798 461.992 743.485 456.74 738.609C451.489 733.733 452.145 724.355 452.145 724.355C452.145 724.355 461.523 722.667 466.774 727.45C472.026 732.233 471.369 741.798 471.369 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 741.798C463.773 741.798 473.151 743.485 478.402 738.609C483.654 733.733 482.903 724.355 482.903 724.355C482.903 724.355 473.526 722.667 468.275 727.45C463.023 732.233 463.773 741.798 463.773 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 741.798C516.381 741.798 507.003 743.485 501.752 738.609C496.501 733.733 497.157 724.355 497.157 724.355C497.157 724.355 506.535 722.667 511.786 727.45C517.037 732.233 516.381 741.798 516.381 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 741.798C508.785 741.798 518.163 743.485 523.414 738.609C528.665 733.733 527.915 724.355 527.915 724.355C527.915 724.355 518.538 722.667 513.286 727.45C508.035 732.233 508.785 741.798 508.785 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 741.798C561.393 741.798 552.015 743.485 546.764 738.609C541.512 733.733 542.169 724.355 542.169 724.355C542.169 724.355 551.546 722.667 556.798 727.45C562.049 732.233 561.393 741.798 561.393 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 741.798C553.797 741.798 563.174 743.485 568.426 738.609C573.677 733.733 572.927 724.355 572.927 724.355C572.927 724.355 563.549 722.667 558.298 727.45C553.047 732.233 553.797 741.798 553.797 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 741.798C606.404 741.798 597.027 743.485 591.776 738.609C586.524 733.733 587.181 724.355 587.181 724.355C587.181 724.355 596.558 722.667 601.809 727.45C607.061 732.233 606.404 741.798 606.404 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 741.798C598.809 741.798 608.186 743.485 613.437 738.609C618.689 733.733 617.939 724.355 617.939 724.355C617.939 724.355 608.561 722.667 603.31 727.45C598.058 732.233 598.809 741.798 598.809 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 741.798C651.416 741.798 642.039 743.485 636.787 738.609C631.536 733.733 632.192 724.355 632.192 724.355C632.192 724.355 641.57 722.667 646.821 727.45C652.073 732.233 651.416 741.798 651.416 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 741.798C643.82 741.798 653.198 743.485 658.449 738.609C663.701 733.733 662.95 724.355 662.95 724.355C662.95 724.355 653.573 722.667 648.321 727.45C643.07 732.233 643.82 741.798 643.82 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 741.798C696.428 741.798 687.05 743.485 681.799 738.609C676.548 733.733 677.204 724.355 677.204 724.355C677.204 724.355 686.581 722.667 691.833 727.45C697.084 732.233 696.428 741.798 696.428 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 741.798C688.832 741.798 698.209 743.485 703.461 738.609C708.712 733.733 707.962 724.355 707.962 724.355C707.962 724.355 698.585 722.667 693.333 727.45C688.082 732.233 688.832 741.798 688.832 741.798Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 774.618C21.2501 774.618 11.8726 776.306 6.62122 771.43C1.36983 766.553 2.02626 757.176 2.02626 757.176C2.02626 757.176 11.4037 755.488 16.6551 760.27C21.9065 765.053 21.2501 774.618 21.2501 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 774.618C13.6542 774.618 23.0317 776.306 28.2831 771.43C33.5345 766.553 32.7843 757.176 32.7843 757.176C32.7843 757.176 23.4068 755.488 18.1554 760.27C12.904 765.053 13.6542 774.618 13.6542 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 774.618C66.2618 774.618 56.8843 776.306 51.6329 771.43C46.3816 766.553 47.038 757.176 47.038 757.176C47.038 757.176 56.4155 755.488 61.6668 760.27C66.9182 765.053 66.2618 774.618 66.2618 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 774.618C58.666 774.618 68.0434 776.306 73.2948 771.43C78.5462 766.553 77.796 757.176 77.796 757.176C77.796 757.176 68.4185 755.488 63.1671 760.27C57.9158 765.053 58.666 774.618 58.666 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 774.618C111.274 774.618 101.896 776.306 96.6447 771.43C91.3933 766.553 92.0497 757.176 92.0497 757.176C92.0497 757.176 101.427 755.488 106.679 760.27C111.93 765.053 111.274 774.618 111.274 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 774.618C103.678 774.618 113.055 776.306 118.307 771.43C123.558 766.553 122.808 757.176 122.808 757.176C122.808 757.176 113.43 755.488 108.179 760.27C102.927 765.053 103.678 774.618 103.678 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 774.618C156.285 774.618 146.908 776.306 141.656 771.43C136.405 766.553 137.061 757.176 137.061 757.176C137.061 757.176 146.439 755.488 151.69 760.27C156.942 765.053 156.285 774.618 156.285 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 774.618C148.689 774.618 158.067 776.306 163.318 771.43C168.57 766.553 167.819 757.176 167.819 757.176C167.819 757.176 158.442 755.488 153.191 760.27C147.939 765.053 148.689 774.618 148.689 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 774.618C201.297 774.618 191.919 776.306 186.668 771.43C181.417 766.553 182.073 757.176 182.073 757.176C182.073 757.176 191.451 755.488 196.702 760.27C201.953 765.053 201.297 774.618 201.297 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 774.618C193.701 774.618 203.079 776.306 208.33 771.43C213.581 766.553 212.831 757.176 212.831 757.176C212.831 757.176 203.454 755.488 198.202 760.27C192.951 765.053 193.701 774.618 193.701 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 774.618C246.309 774.618 236.931 776.306 231.68 771.43C226.428 766.553 227.085 757.176 227.085 757.176C227.085 757.176 236.462 755.488 241.714 760.27C246.965 765.053 246.309 774.618 246.309 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 774.618C238.713 774.618 248.09 776.306 253.342 771.43C258.593 766.553 257.843 757.176 257.843 757.176C257.843 757.176 248.465 755.488 243.214 760.27C237.963 765.053 238.713 774.618 238.713 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 774.618C291.322 774.618 281.945 776.306 276.693 771.43C271.442 766.553 272.099 757.176 272.099 757.176C272.099 757.176 281.476 755.488 286.727 760.27C291.979 765.053 291.322 774.618 291.322 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 774.618C283.726 774.618 293.104 776.306 298.355 771.43C303.607 766.553 302.857 757.176 302.857 757.176C302.857 757.176 293.479 755.488 288.228 760.27C282.976 765.053 283.726 774.618 283.726 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 774.618C336.334 774.618 326.957 776.306 321.705 771.43C316.454 766.553 317.11 757.176 317.11 757.176C317.11 757.176 326.488 755.488 331.739 760.27C336.99 765.053 336.334 774.618 336.334 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 774.618C328.738 774.618 338.116 776.306 343.367 771.43C348.618 766.553 347.868 757.176 347.868 757.176C347.868 757.176 338.491 755.488 333.239 760.27C327.988 765.053 328.738 774.618 328.738 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 774.618C381.346 774.618 371.968 776.306 366.717 771.43C361.466 766.553 362.122 757.176 362.122 757.176C362.122 757.176 371.499 755.488 376.751 760.27C382.002 765.053 381.346 774.618 381.346 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 774.618C373.75 774.618 383.127 776.306 388.379 771.43C393.63 766.553 392.88 757.176 392.88 757.176C392.88 757.176 383.503 755.488 378.251 760.27C373 765.053 373.75 774.618 373.75 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 774.618C426.358 774.618 416.98 776.306 411.729 771.43C406.477 766.553 407.134 757.176 407.134 757.176C407.134 757.176 416.511 755.488 421.763 760.27C427.014 765.053 426.358 774.618 426.358 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 774.618C418.762 774.618 428.139 776.306 433.391 771.43C438.642 766.553 437.892 757.176 437.892 757.176C437.892 757.176 428.514 755.488 423.263 760.27C418.011 765.053 418.762 774.618 418.762 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 774.618C471.369 774.618 461.992 776.306 456.74 771.43C451.489 766.553 452.145 757.176 452.145 757.176C452.145 757.176 461.523 755.488 466.774 760.27C472.026 765.053 471.369 774.618 471.369 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 774.618C463.773 774.618 473.151 776.306 478.402 771.43C483.654 766.553 482.903 757.176 482.903 757.176C482.903 757.176 473.526 755.488 468.275 760.27C463.023 765.053 463.773 774.618 463.773 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 774.618C516.381 774.618 507.003 776.306 501.752 771.43C496.501 766.553 497.157 757.176 497.157 757.176C497.157 757.176 506.535 755.488 511.786 760.27C517.037 765.053 516.381 774.618 516.381 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 774.618C508.785 774.618 518.163 776.306 523.414 771.43C528.665 766.553 527.915 757.176 527.915 757.176C527.915 757.176 518.538 755.488 513.286 760.27C508.035 765.053 508.785 774.618 508.785 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 774.618C561.393 774.618 552.015 776.306 546.764 771.43C541.512 766.553 542.169 757.176 542.169 757.176C542.169 757.176 551.546 755.488 556.798 760.27C562.049 765.053 561.393 774.618 561.393 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 774.618C553.797 774.618 563.174 776.306 568.426 771.43C573.677 766.553 572.927 757.176 572.927 757.176C572.927 757.176 563.549 755.488 558.298 760.27C553.047 765.053 553.797 774.618 553.797 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 774.618C606.404 774.618 597.027 776.306 591.776 771.43C586.524 766.553 587.181 757.176 587.181 757.176C587.181 757.176 596.558 755.488 601.809 760.27C607.061 765.053 606.404 774.618 606.404 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 774.618C598.809 774.618 608.186 776.306 613.437 771.43C618.689 766.553 617.939 757.176 617.939 757.176C617.939 757.176 608.561 755.488 603.31 760.27C598.058 765.053 598.809 774.618 598.809 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 774.618C651.416 774.618 642.039 776.306 636.787 771.43C631.536 766.553 632.192 757.176 632.192 757.176C632.192 757.176 641.57 755.488 646.821 760.27C652.073 765.053 651.416 774.618 651.416 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 774.618C643.82 774.618 653.198 776.306 658.449 771.43C663.701 766.553 662.95 757.176 662.95 757.176C662.95 757.176 653.573 755.488 648.321 760.27C643.07 765.053 643.82 774.618 643.82 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 774.618C696.428 774.618 687.05 776.306 681.799 771.43C676.548 766.553 677.204 757.176 677.204 757.176C677.204 757.176 686.581 755.488 691.833 760.27C697.084 765.053 696.428 774.618 696.428 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 774.618C688.832 774.618 698.209 776.306 703.461 771.43C708.712 766.553 707.962 757.176 707.962 757.176C707.962 757.176 698.585 755.488 693.333 760.27C688.082 765.053 688.832 774.618 688.832 774.618Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 807.439C21.2501 807.439 11.8726 809.127 6.62122 804.251C1.36983 799.375 2.02626 789.997 2.02626 789.997C2.02626 789.997 11.4037 788.309 16.6551 793.092C21.9065 797.874 21.2501 807.439 21.2501 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 807.439C13.6542 807.439 23.0317 809.127 28.2831 804.251C33.5345 799.375 32.7843 789.997 32.7843 789.997C32.7843 789.997 23.4068 788.309 18.1554 793.092C12.904 797.874 13.6542 807.439 13.6542 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 807.439C66.2618 807.439 56.8843 809.127 51.6329 804.251C46.3816 799.375 47.038 789.997 47.038 789.997C47.038 789.997 56.4155 788.309 61.6668 793.092C66.9182 797.874 66.2618 807.439 66.2618 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 807.439C58.666 807.439 68.0434 809.127 73.2948 804.251C78.5462 799.375 77.796 789.997 77.796 789.997C77.796 789.997 68.4185 788.309 63.1671 793.092C57.9158 797.874 58.666 807.439 58.666 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 807.439C111.274 807.439 101.896 809.127 96.6447 804.251C91.3933 799.375 92.0497 789.997 92.0497 789.997C92.0497 789.997 101.427 788.309 106.679 793.092C111.93 797.874 111.274 807.439 111.274 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 807.439C103.678 807.439 113.055 809.127 118.307 804.251C123.558 799.375 122.808 789.997 122.808 789.997C122.808 789.997 113.43 788.309 108.179 793.092C102.927 797.874 103.678 807.439 103.678 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 807.439C156.285 807.439 146.908 809.127 141.656 804.251C136.405 799.375 137.061 789.997 137.061 789.997C137.061 789.997 146.439 788.309 151.69 793.092C156.942 797.874 156.285 807.439 156.285 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 807.439C148.689 807.439 158.067 809.127 163.318 804.251C168.57 799.375 167.819 789.997 167.819 789.997C167.819 789.997 158.442 788.309 153.191 793.092C147.939 797.874 148.689 807.439 148.689 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 807.439C201.297 807.439 191.919 809.127 186.668 804.251C181.417 799.375 182.073 789.997 182.073 789.997C182.073 789.997 191.451 788.309 196.702 793.092C201.953 797.874 201.297 807.439 201.297 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 807.439C193.701 807.439 203.079 809.127 208.33 804.251C213.581 799.375 212.831 789.997 212.831 789.997C212.831 789.997 203.454 788.309 198.202 793.092C192.951 797.874 193.701 807.439 193.701 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 807.439C246.309 807.439 236.931 809.127 231.68 804.251C226.428 799.375 227.085 789.997 227.085 789.997C227.085 789.997 236.462 788.309 241.714 793.092C246.965 797.874 246.309 807.439 246.309 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 807.439C238.713 807.439 248.09 809.127 253.342 804.251C258.593 799.375 257.843 789.997 257.843 789.997C257.843 789.997 248.465 788.309 243.214 793.092C237.963 797.874 238.713 807.439 238.713 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 807.439C291.322 807.439 281.945 809.127 276.693 804.251C271.442 799.375 272.099 789.997 272.099 789.997C272.099 789.997 281.476 788.309 286.727 793.092C291.979 797.874 291.322 807.439 291.322 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 807.439C283.726 807.439 293.104 809.127 298.355 804.251C303.607 799.375 302.857 789.997 302.857 789.997C302.857 789.997 293.479 788.309 288.228 793.092C282.976 797.874 283.726 807.439 283.726 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 807.439C336.334 807.439 326.957 809.127 321.705 804.251C316.454 799.375 317.11 789.997 317.11 789.997C317.11 789.997 326.488 788.309 331.739 793.092C336.99 797.874 336.334 807.439 336.334 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 807.439C328.738 807.439 338.116 809.127 343.367 804.251C348.618 799.375 347.868 789.997 347.868 789.997C347.868 789.997 338.491 788.309 333.239 793.092C327.988 797.874 328.738 807.439 328.738 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 807.439C381.346 807.439 371.968 809.127 366.717 804.251C361.466 799.375 362.122 789.997 362.122 789.997C362.122 789.997 371.499 788.309 376.751 793.092C382.002 797.874 381.346 807.439 381.346 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 807.439C373.75 807.439 383.127 809.127 388.379 804.251C393.63 799.375 392.88 789.997 392.88 789.997C392.88 789.997 383.503 788.309 378.251 793.092C373 797.874 373.75 807.439 373.75 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 807.439C426.358 807.439 416.98 809.127 411.729 804.251C406.477 799.375 407.134 789.997 407.134 789.997C407.134 789.997 416.511 788.309 421.763 793.092C427.014 797.874 426.358 807.439 426.358 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 807.439C418.762 807.439 428.139 809.127 433.391 804.251C438.642 799.375 437.892 789.997 437.892 789.997C437.892 789.997 428.514 788.309 423.263 793.092C418.011 797.874 418.762 807.439 418.762 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 807.439C471.369 807.439 461.992 809.127 456.74 804.251C451.489 799.375 452.145 789.997 452.145 789.997C452.145 789.997 461.523 788.309 466.774 793.092C472.026 797.874 471.369 807.439 471.369 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 807.439C463.773 807.439 473.151 809.127 478.402 804.251C483.654 799.375 482.903 789.997 482.903 789.997C482.903 789.997 473.526 788.309 468.275 793.092C463.023 797.874 463.773 807.439 463.773 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 807.439C516.381 807.439 507.003 809.127 501.752 804.251C496.501 799.375 497.157 789.997 497.157 789.997C497.157 789.997 506.535 788.309 511.786 793.092C517.037 797.874 516.381 807.439 516.381 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 807.439C508.785 807.439 518.163 809.127 523.414 804.251C528.665 799.375 527.915 789.997 527.915 789.997C527.915 789.997 518.538 788.309 513.286 793.092C508.035 797.874 508.785 807.439 508.785 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 807.439C561.393 807.439 552.015 809.127 546.764 804.251C541.512 799.375 542.169 789.997 542.169 789.997C542.169 789.997 551.546 788.309 556.798 793.092C562.049 797.874 561.393 807.439 561.393 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 807.439C553.797 807.439 563.174 809.127 568.426 804.251C573.677 799.375 572.927 789.997 572.927 789.997C572.927 789.997 563.549 788.309 558.298 793.092C553.047 797.874 553.797 807.439 553.797 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 807.439C606.404 807.439 597.027 809.127 591.776 804.251C586.524 799.375 587.181 789.997 587.181 789.997C587.181 789.997 596.558 788.309 601.809 793.092C607.061 797.874 606.404 807.439 606.404 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 807.439C598.809 807.439 608.186 809.127 613.437 804.251C618.689 799.375 617.939 789.997 617.939 789.997C617.939 789.997 608.561 788.309 603.31 793.092C598.058 797.874 598.809 807.439 598.809 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 807.439C651.416 807.439 642.039 809.127 636.787 804.251C631.536 799.375 632.192 789.997 632.192 789.997C632.192 789.997 641.57 788.309 646.821 793.092C652.073 797.874 651.416 807.439 651.416 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 807.439C643.82 807.439 653.198 809.127 658.449 804.251C663.701 799.375 662.95 789.997 662.95 789.997C662.95 789.997 653.573 788.309 648.321 793.092C643.07 797.874 643.82 807.439 643.82 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 807.439C696.428 807.439 687.05 809.127 681.799 804.251C676.548 799.375 677.204 789.997 677.204 789.997C677.204 789.997 686.581 788.309 691.833 793.092C697.084 797.874 696.428 807.439 696.428 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 807.439C688.832 807.439 698.209 809.127 703.461 804.251C708.712 799.375 707.962 789.997 707.962 789.997C707.962 789.997 698.585 788.309 693.333 793.092C688.082 797.874 688.832 807.439 688.832 807.439Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 840.26C21.2501 840.26 11.8726 841.948 6.62122 837.072C1.36983 832.196 2.02626 822.818 2.02626 822.818C2.02626 822.818 11.4037 821.13 16.6551 825.913C21.9065 830.695 21.2501 840.26 21.2501 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 840.26C13.6542 840.26 23.0317 841.948 28.2831 837.072C33.5345 832.196 32.7843 822.818 32.7843 822.818C32.7843 822.818 23.4068 821.13 18.1554 825.913C12.904 830.695 13.6542 840.26 13.6542 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 840.26C66.2618 840.26 56.8843 841.948 51.6329 837.072C46.3816 832.196 47.038 822.818 47.038 822.818C47.038 822.818 56.4155 821.13 61.6668 825.913C66.9182 830.695 66.2618 840.26 66.2618 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 840.26C58.666 840.26 68.0434 841.948 73.2948 837.072C78.5462 832.196 77.796 822.818 77.796 822.818C77.796 822.818 68.4185 821.13 63.1671 825.913C57.9158 830.695 58.666 840.26 58.666 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 840.26C111.274 840.26 101.896 841.948 96.6447 837.072C91.3933 832.196 92.0497 822.818 92.0497 822.818C92.0497 822.818 101.427 821.13 106.679 825.913C111.93 830.695 111.274 840.26 111.274 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 840.26C103.678 840.26 113.055 841.948 118.307 837.072C123.558 832.196 122.808 822.818 122.808 822.818C122.808 822.818 113.43 821.13 108.179 825.913C102.927 830.695 103.678 840.26 103.678 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 840.26C156.285 840.26 146.908 841.948 141.656 837.072C136.405 832.196 137.061 822.818 137.061 822.818C137.061 822.818 146.439 821.13 151.69 825.913C156.942 830.695 156.285 840.26 156.285 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 840.26C148.689 840.26 158.067 841.948 163.318 837.072C168.57 832.196 167.819 822.818 167.819 822.818C167.819 822.818 158.442 821.13 153.191 825.913C147.939 830.695 148.689 840.26 148.689 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 840.26C201.297 840.26 191.919 841.948 186.668 837.072C181.417 832.196 182.073 822.818 182.073 822.818C182.073 822.818 191.451 821.13 196.702 825.913C201.953 830.695 201.297 840.26 201.297 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 840.26C193.701 840.26 203.079 841.948 208.33 837.072C213.581 832.196 212.831 822.818 212.831 822.818C212.831 822.818 203.454 821.13 198.202 825.913C192.951 830.695 193.701 840.26 193.701 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 840.26C246.309 840.26 236.931 841.948 231.68 837.072C226.428 832.196 227.085 822.818 227.085 822.818C227.085 822.818 236.462 821.13 241.714 825.913C246.965 830.695 246.309 840.26 246.309 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 840.26C238.713 840.26 248.09 841.948 253.342 837.072C258.593 832.196 257.843 822.818 257.843 822.818C257.843 822.818 248.465 821.13 243.214 825.913C237.963 830.695 238.713 840.26 238.713 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 840.26C291.322 840.26 281.945 841.948 276.693 837.072C271.442 832.196 272.099 822.818 272.099 822.818C272.099 822.818 281.476 821.13 286.727 825.913C291.979 830.695 291.322 840.26 291.322 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 840.26C283.726 840.26 293.104 841.948 298.355 837.072C303.607 832.196 302.857 822.818 302.857 822.818C302.857 822.818 293.479 821.13 288.228 825.913C282.976 830.695 283.726 840.26 283.726 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 840.26C336.334 840.26 326.957 841.948 321.705 837.072C316.454 832.196 317.11 822.818 317.11 822.818C317.11 822.818 326.488 821.13 331.739 825.913C336.99 830.695 336.334 840.26 336.334 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 840.26C328.738 840.26 338.116 841.948 343.367 837.072C348.618 832.196 347.868 822.818 347.868 822.818C347.868 822.818 338.491 821.13 333.239 825.913C327.988 830.695 328.738 840.26 328.738 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 840.26C381.346 840.26 371.968 841.948 366.717 837.072C361.466 832.196 362.122 822.818 362.122 822.818C362.122 822.818 371.499 821.13 376.751 825.913C382.002 830.695 381.346 840.26 381.346 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 840.26C373.75 840.26 383.127 841.948 388.379 837.072C393.63 832.196 392.88 822.818 392.88 822.818C392.88 822.818 383.503 821.13 378.251 825.913C373 830.695 373.75 840.26 373.75 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 840.26C426.358 840.26 416.98 841.948 411.729 837.072C406.477 832.196 407.134 822.818 407.134 822.818C407.134 822.818 416.511 821.13 421.763 825.913C427.014 830.695 426.358 840.26 426.358 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 840.26C418.762 840.26 428.139 841.948 433.391 837.072C438.642 832.196 437.892 822.818 437.892 822.818C437.892 822.818 428.514 821.13 423.263 825.913C418.011 830.695 418.762 840.26 418.762 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 840.26C471.369 840.26 461.992 841.948 456.74 837.072C451.489 832.196 452.145 822.818 452.145 822.818C452.145 822.818 461.523 821.13 466.774 825.913C472.026 830.695 471.369 840.26 471.369 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 840.26C463.773 840.26 473.151 841.948 478.402 837.072C483.654 832.196 482.903 822.818 482.903 822.818C482.903 822.818 473.526 821.13 468.275 825.913C463.023 830.695 463.773 840.26 463.773 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 840.26C516.381 840.26 507.003 841.948 501.752 837.072C496.501 832.196 497.157 822.818 497.157 822.818C497.157 822.818 506.535 821.13 511.786 825.913C517.037 830.695 516.381 840.26 516.381 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 840.26C508.785 840.26 518.163 841.948 523.414 837.072C528.665 832.196 527.915 822.818 527.915 822.818C527.915 822.818 518.538 821.13 513.286 825.913C508.035 830.695 508.785 840.26 508.785 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 840.26C561.393 840.26 552.015 841.948 546.764 837.072C541.512 832.196 542.169 822.818 542.169 822.818C542.169 822.818 551.546 821.13 556.798 825.913C562.049 830.695 561.393 840.26 561.393 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 840.26C553.797 840.26 563.174 841.948 568.426 837.072C573.677 832.196 572.927 822.818 572.927 822.818C572.927 822.818 563.549 821.13 558.298 825.913C553.047 830.695 553.797 840.26 553.797 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 840.26C606.404 840.26 597.027 841.948 591.776 837.072C586.524 832.196 587.181 822.818 587.181 822.818C587.181 822.818 596.558 821.13 601.809 825.913C607.061 830.695 606.404 840.26 606.404 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 840.26C598.809 840.26 608.186 841.948 613.437 837.072C618.689 832.196 617.939 822.818 617.939 822.818C617.939 822.818 608.561 821.13 603.31 825.913C598.058 830.695 598.809 840.26 598.809 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 840.26C651.416 840.26 642.039 841.948 636.787 837.072C631.536 832.196 632.192 822.818 632.192 822.818C632.192 822.818 641.57 821.13 646.821 825.913C652.073 830.695 651.416 840.26 651.416 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 840.26C643.82 840.26 653.198 841.948 658.449 837.072C663.701 832.196 662.95 822.818 662.95 822.818C662.95 822.818 653.573 821.13 648.321 825.913C643.07 830.695 643.82 840.26 643.82 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 840.26C696.428 840.26 687.05 841.948 681.799 837.072C676.548 832.196 677.204 822.818 677.204 822.818C677.204 822.818 686.581 821.13 691.833 825.913C697.084 830.695 696.428 840.26 696.428 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 840.26C688.832 840.26 698.209 841.948 703.461 837.072C708.712 832.196 707.962 822.818 707.962 822.818C707.962 822.818 698.585 821.13 693.333 825.913C688.082 830.695 688.832 840.26 688.832 840.26Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 873.082C21.2501 873.082 11.8726 874.77 6.62122 869.893C1.36983 865.017 2.02626 855.64 2.02626 855.64C2.02626 855.64 11.4037 853.952 16.6551 858.734C21.9065 863.517 21.2501 873.082 21.2501 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 873.082C13.6542 873.082 23.0317 874.77 28.2831 869.893C33.5345 865.017 32.7843 855.64 32.7843 855.64C32.7843 855.64 23.4068 853.952 18.1554 858.734C12.904 863.517 13.6542 873.082 13.6542 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 873.082C66.2618 873.082 56.8843 874.77 51.6329 869.893C46.3816 865.017 47.038 855.64 47.038 855.64C47.038 855.64 56.4155 853.952 61.6668 858.734C66.9182 863.517 66.2618 873.082 66.2618 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 873.082C58.666 873.082 68.0434 874.77 73.2948 869.893C78.5462 865.017 77.796 855.64 77.796 855.64C77.796 855.64 68.4185 853.952 63.1671 858.734C57.9158 863.517 58.666 873.082 58.666 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 873.082C111.274 873.082 101.896 874.77 96.6447 869.893C91.3933 865.017 92.0497 855.64 92.0497 855.64C92.0497 855.64 101.427 853.952 106.679 858.734C111.93 863.517 111.274 873.082 111.274 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 873.082C103.678 873.082 113.055 874.77 118.307 869.893C123.558 865.017 122.808 855.64 122.808 855.64C122.808 855.64 113.43 853.952 108.179 858.734C102.927 863.517 103.678 873.082 103.678 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 873.082C156.285 873.082 146.908 874.77 141.656 869.893C136.405 865.017 137.061 855.64 137.061 855.64C137.061 855.64 146.439 853.952 151.69 858.734C156.942 863.517 156.285 873.082 156.285 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 873.082C148.689 873.082 158.067 874.77 163.318 869.893C168.57 865.017 167.819 855.64 167.819 855.64C167.819 855.64 158.442 853.952 153.191 858.734C147.939 863.517 148.689 873.082 148.689 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 873.082C201.297 873.082 191.919 874.77 186.668 869.893C181.417 865.017 182.073 855.64 182.073 855.64C182.073 855.64 191.451 853.952 196.702 858.734C201.953 863.517 201.297 873.082 201.297 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 873.082C193.701 873.082 203.079 874.77 208.33 869.893C213.581 865.017 212.831 855.64 212.831 855.64C212.831 855.64 203.454 853.952 198.202 858.734C192.951 863.517 193.701 873.082 193.701 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 873.082C246.309 873.082 236.931 874.77 231.68 869.893C226.428 865.017 227.085 855.64 227.085 855.64C227.085 855.64 236.462 853.952 241.714 858.734C246.965 863.517 246.309 873.082 246.309 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 873.082C238.713 873.082 248.09 874.77 253.342 869.893C258.593 865.017 257.843 855.64 257.843 855.64C257.843 855.64 248.465 853.952 243.214 858.734C237.963 863.517 238.713 873.082 238.713 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 873.082C291.322 873.082 281.945 874.77 276.693 869.893C271.442 865.017 272.099 855.64 272.099 855.64C272.099 855.64 281.476 853.952 286.727 858.734C291.979 863.517 291.322 873.082 291.322 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 873.082C283.726 873.082 293.104 874.77 298.355 869.893C303.607 865.017 302.857 855.64 302.857 855.64C302.857 855.64 293.479 853.952 288.228 858.734C282.976 863.517 283.726 873.082 283.726 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 873.082C336.334 873.082 326.957 874.77 321.705 869.893C316.454 865.017 317.11 855.64 317.11 855.64C317.11 855.64 326.488 853.952 331.739 858.734C336.99 863.517 336.334 873.082 336.334 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 873.082C328.738 873.082 338.116 874.77 343.367 869.893C348.618 865.017 347.868 855.64 347.868 855.64C347.868 855.64 338.491 853.952 333.239 858.734C327.988 863.517 328.738 873.082 328.738 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 873.082C381.346 873.082 371.968 874.77 366.717 869.893C361.466 865.017 362.122 855.64 362.122 855.64C362.122 855.64 371.499 853.952 376.751 858.734C382.002 863.517 381.346 873.082 381.346 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 873.082C373.75 873.082 383.127 874.77 388.379 869.893C393.63 865.017 392.88 855.64 392.88 855.64C392.88 855.64 383.503 853.952 378.251 858.734C373 863.517 373.75 873.082 373.75 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 873.082C426.358 873.082 416.98 874.77 411.729 869.893C406.477 865.017 407.134 855.64 407.134 855.64C407.134 855.64 416.511 853.952 421.763 858.734C427.014 863.517 426.358 873.082 426.358 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 873.082C418.762 873.082 428.139 874.77 433.391 869.893C438.642 865.017 437.892 855.64 437.892 855.64C437.892 855.64 428.514 853.952 423.263 858.734C418.011 863.517 418.762 873.082 418.762 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 873.082C471.369 873.082 461.992 874.77 456.74 869.893C451.489 865.017 452.145 855.64 452.145 855.64C452.145 855.64 461.523 853.952 466.774 858.734C472.026 863.517 471.369 873.082 471.369 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 873.082C463.773 873.082 473.151 874.77 478.402 869.893C483.654 865.017 482.903 855.64 482.903 855.64C482.903 855.64 473.526 853.952 468.275 858.734C463.023 863.517 463.773 873.082 463.773 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 873.082C516.381 873.082 507.003 874.77 501.752 869.893C496.501 865.017 497.157 855.64 497.157 855.64C497.157 855.64 506.535 853.952 511.786 858.734C517.037 863.517 516.381 873.082 516.381 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 873.082C508.785 873.082 518.163 874.77 523.414 869.893C528.665 865.017 527.915 855.64 527.915 855.64C527.915 855.64 518.538 853.952 513.286 858.734C508.035 863.517 508.785 873.082 508.785 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 873.082C561.393 873.082 552.015 874.77 546.764 869.893C541.512 865.017 542.169 855.64 542.169 855.64C542.169 855.64 551.546 853.952 556.798 858.734C562.049 863.517 561.393 873.082 561.393 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 873.082C553.797 873.082 563.174 874.77 568.426 869.893C573.677 865.017 572.927 855.64 572.927 855.64C572.927 855.64 563.549 853.952 558.298 858.734C553.047 863.517 553.797 873.082 553.797 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 873.082C606.404 873.082 597.027 874.77 591.776 869.893C586.524 865.017 587.181 855.64 587.181 855.64C587.181 855.64 596.558 853.952 601.809 858.734C607.061 863.517 606.404 873.082 606.404 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 873.082C598.809 873.082 608.186 874.77 613.437 869.893C618.689 865.017 617.939 855.64 617.939 855.64C617.939 855.64 608.561 853.952 603.31 858.734C598.058 863.517 598.809 873.082 598.809 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 873.082C651.416 873.082 642.039 874.77 636.787 869.893C631.536 865.017 632.192 855.64 632.192 855.64C632.192 855.64 641.57 853.952 646.821 858.734C652.073 863.517 651.416 873.082 651.416 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 873.082C643.82 873.082 653.198 874.77 658.449 869.893C663.701 865.017 662.95 855.64 662.95 855.64C662.95 855.64 653.573 853.952 648.321 858.734C643.07 863.517 643.82 873.082 643.82 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 873.082C696.428 873.082 687.05 874.77 681.799 869.893C676.548 865.017 677.204 855.64 677.204 855.64C677.204 855.64 686.581 853.952 691.833 858.734C697.084 863.517 696.428 873.082 696.428 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 873.082C688.832 873.082 698.209 874.77 703.461 869.893C708.712 865.017 707.962 855.64 707.962 855.64C707.962 855.64 698.585 853.952 693.333 858.734C688.082 863.517 688.832 873.082 688.832 873.082Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 905.903C21.2501 905.903 11.8726 907.591 6.62122 902.715C1.36983 897.838 2.02626 888.461 2.02626 888.461C2.02626 888.461 11.4037 886.773 16.6551 891.555C21.9065 896.338 21.2501 905.903 21.2501 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 905.903C13.6542 905.903 23.0317 907.591 28.2831 902.715C33.5345 897.838 32.7843 888.461 32.7843 888.461C32.7843 888.461 23.4068 886.773 18.1554 891.555C12.904 896.338 13.6542 905.903 13.6542 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 905.903C66.2618 905.903 56.8843 907.591 51.6329 902.715C46.3816 897.838 47.038 888.461 47.038 888.461C47.038 888.461 56.4155 886.773 61.6668 891.555C66.9182 896.338 66.2618 905.903 66.2618 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 905.903C58.666 905.903 68.0434 907.591 73.2948 902.715C78.5462 897.838 77.796 888.461 77.796 888.461C77.796 888.461 68.4185 886.773 63.1671 891.555C57.9158 896.338 58.666 905.903 58.666 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 905.903C111.274 905.903 101.896 907.591 96.6447 902.715C91.3933 897.838 92.0497 888.461 92.0497 888.461C92.0497 888.461 101.427 886.773 106.679 891.555C111.93 896.338 111.274 905.903 111.274 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 905.903C103.678 905.903 113.055 907.591 118.307 902.715C123.558 897.838 122.808 888.461 122.808 888.461C122.808 888.461 113.43 886.773 108.179 891.555C102.927 896.338 103.678 905.903 103.678 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 905.903C156.285 905.903 146.908 907.591 141.656 902.715C136.405 897.838 137.061 888.461 137.061 888.461C137.061 888.461 146.439 886.773 151.69 891.555C156.942 896.338 156.285 905.903 156.285 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 905.903C148.689 905.903 158.067 907.591 163.318 902.715C168.57 897.838 167.819 888.461 167.819 888.461C167.819 888.461 158.442 886.773 153.191 891.555C147.939 896.338 148.689 905.903 148.689 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 905.903C201.297 905.903 191.919 907.591 186.668 902.715C181.417 897.838 182.073 888.461 182.073 888.461C182.073 888.461 191.451 886.773 196.702 891.555C201.953 896.338 201.297 905.903 201.297 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 905.903C193.701 905.903 203.079 907.591 208.33 902.715C213.581 897.838 212.831 888.461 212.831 888.461C212.831 888.461 203.454 886.773 198.202 891.555C192.951 896.338 193.701 905.903 193.701 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 905.903C246.309 905.903 236.931 907.591 231.68 902.715C226.428 897.838 227.085 888.461 227.085 888.461C227.085 888.461 236.462 886.773 241.714 891.555C246.965 896.338 246.309 905.903 246.309 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 905.903C238.713 905.903 248.09 907.591 253.342 902.715C258.593 897.838 257.843 888.461 257.843 888.461C257.843 888.461 248.465 886.773 243.214 891.555C237.963 896.338 238.713 905.903 238.713 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 905.903C291.322 905.903 281.945 907.591 276.693 902.715C271.442 897.838 272.099 888.461 272.099 888.461C272.099 888.461 281.476 886.773 286.727 891.555C291.979 896.338 291.322 905.903 291.322 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 905.903C283.726 905.903 293.104 907.591 298.355 902.715C303.607 897.838 302.857 888.461 302.857 888.461C302.857 888.461 293.479 886.773 288.228 891.555C282.976 896.338 283.726 905.903 283.726 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 905.903C336.334 905.903 326.957 907.591 321.705 902.715C316.454 897.838 317.11 888.461 317.11 888.461C317.11 888.461 326.488 886.773 331.739 891.555C336.99 896.338 336.334 905.903 336.334 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 905.903C328.738 905.903 338.116 907.591 343.367 902.715C348.618 897.838 347.868 888.461 347.868 888.461C347.868 888.461 338.491 886.773 333.239 891.555C327.988 896.338 328.738 905.903 328.738 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 905.903C381.346 905.903 371.968 907.591 366.717 902.715C361.466 897.838 362.122 888.461 362.122 888.461C362.122 888.461 371.499 886.773 376.751 891.555C382.002 896.338 381.346 905.903 381.346 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 905.903C373.75 905.903 383.127 907.591 388.379 902.715C393.63 897.838 392.88 888.461 392.88 888.461C392.88 888.461 383.503 886.773 378.251 891.555C373 896.338 373.75 905.903 373.75 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 905.903C426.358 905.903 416.98 907.591 411.729 902.715C406.477 897.838 407.134 888.461 407.134 888.461C407.134 888.461 416.511 886.773 421.763 891.555C427.014 896.338 426.358 905.903 426.358 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 905.903C418.762 905.903 428.139 907.591 433.391 902.715C438.642 897.838 437.892 888.461 437.892 888.461C437.892 888.461 428.514 886.773 423.263 891.555C418.011 896.338 418.762 905.903 418.762 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 905.903C471.369 905.903 461.992 907.591 456.74 902.715C451.489 897.838 452.145 888.461 452.145 888.461C452.145 888.461 461.523 886.773 466.774 891.555C472.026 896.338 471.369 905.903 471.369 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 905.903C463.773 905.903 473.151 907.591 478.402 902.715C483.654 897.838 482.903 888.461 482.903 888.461C482.903 888.461 473.526 886.773 468.275 891.555C463.023 896.338 463.773 905.903 463.773 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 905.903C516.381 905.903 507.003 907.591 501.752 902.715C496.501 897.838 497.157 888.461 497.157 888.461C497.157 888.461 506.535 886.773 511.786 891.555C517.037 896.338 516.381 905.903 516.381 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 905.903C508.785 905.903 518.163 907.591 523.414 902.715C528.665 897.838 527.915 888.461 527.915 888.461C527.915 888.461 518.538 886.773 513.286 891.555C508.035 896.338 508.785 905.903 508.785 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 905.903C561.393 905.903 552.015 907.591 546.764 902.715C541.512 897.838 542.169 888.461 542.169 888.461C542.169 888.461 551.546 886.773 556.798 891.555C562.049 896.338 561.393 905.903 561.393 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 905.903C553.797 905.903 563.174 907.591 568.426 902.715C573.677 897.838 572.927 888.461 572.927 888.461C572.927 888.461 563.549 886.773 558.298 891.555C553.047 896.338 553.797 905.903 553.797 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 905.903C606.404 905.903 597.027 907.591 591.776 902.715C586.524 897.838 587.181 888.461 587.181 888.461C587.181 888.461 596.558 886.773 601.809 891.555C607.061 896.338 606.404 905.903 606.404 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 905.903C598.809 905.903 608.186 907.591 613.437 902.715C618.689 897.838 617.939 888.461 617.939 888.461C617.939 888.461 608.561 886.773 603.31 891.555C598.058 896.338 598.809 905.903 598.809 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 905.903C651.416 905.903 642.039 907.591 636.787 902.715C631.536 897.838 632.192 888.461 632.192 888.461C632.192 888.461 641.57 886.773 646.821 891.555C652.073 896.338 651.416 905.903 651.416 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 905.903C643.82 905.903 653.198 907.591 658.449 902.715C663.701 897.838 662.95 888.461 662.95 888.461C662.95 888.461 653.573 886.773 648.321 891.555C643.07 896.338 643.82 905.903 643.82 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 905.903C696.428 905.903 687.05 907.591 681.799 902.715C676.548 897.838 677.204 888.461 677.204 888.461C677.204 888.461 686.581 886.773 691.833 891.555C697.084 896.338 696.428 905.903 696.428 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 905.903C688.832 905.903 698.209 907.591 703.461 902.715C708.712 897.838 707.962 888.461 707.962 888.461C707.962 888.461 698.585 886.773 693.333 891.555C688.082 896.338 688.832 905.903 688.832 905.903Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 938.724C21.2501 938.724 11.8726 940.412 6.62122 935.536C1.36983 930.66 2.02626 921.282 2.02626 921.282C2.02626 921.282 11.4037 919.594 16.6551 924.377C21.9065 929.159 21.2501 938.724 21.2501 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 938.724C13.6542 938.724 23.0317 940.412 28.2831 935.536C33.5345 930.66 32.7843 921.282 32.7843 921.282C32.7843 921.282 23.4068 919.594 18.1554 924.377C12.904 929.159 13.6542 938.724 13.6542 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 938.724C66.2618 938.724 56.8843 940.412 51.6329 935.536C46.3816 930.66 47.038 921.282 47.038 921.282C47.038 921.282 56.4155 919.594 61.6668 924.377C66.9182 929.159 66.2618 938.724 66.2618 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 938.724C58.666 938.724 68.0434 940.412 73.2948 935.536C78.5462 930.66 77.796 921.282 77.796 921.282C77.796 921.282 68.4185 919.594 63.1671 924.377C57.9158 929.159 58.666 938.724 58.666 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 938.724C111.274 938.724 101.896 940.412 96.6447 935.536C91.3933 930.66 92.0497 921.282 92.0497 921.282C92.0497 921.282 101.427 919.594 106.679 924.377C111.93 929.159 111.274 938.724 111.274 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 938.724C103.678 938.724 113.055 940.412 118.307 935.536C123.558 930.66 122.808 921.282 122.808 921.282C122.808 921.282 113.43 919.594 108.179 924.377C102.927 929.159 103.678 938.724 103.678 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 938.724C156.285 938.724 146.908 940.412 141.656 935.536C136.405 930.66 137.061 921.282 137.061 921.282C137.061 921.282 146.439 919.594 151.69 924.377C156.942 929.159 156.285 938.724 156.285 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 938.724C148.689 938.724 158.067 940.412 163.318 935.536C168.57 930.66 167.819 921.282 167.819 921.282C167.819 921.282 158.442 919.594 153.191 924.377C147.939 929.159 148.689 938.724 148.689 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 938.724C201.297 938.724 191.919 940.412 186.668 935.536C181.417 930.66 182.073 921.282 182.073 921.282C182.073 921.282 191.451 919.594 196.702 924.377C201.953 929.159 201.297 938.724 201.297 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 938.724C193.701 938.724 203.079 940.412 208.33 935.536C213.581 930.66 212.831 921.282 212.831 921.282C212.831 921.282 203.454 919.594 198.202 924.377C192.951 929.159 193.701 938.724 193.701 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 938.724C246.309 938.724 236.931 940.412 231.68 935.536C226.428 930.66 227.085 921.282 227.085 921.282C227.085 921.282 236.462 919.594 241.714 924.377C246.965 929.159 246.309 938.724 246.309 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 938.724C238.713 938.724 248.09 940.412 253.342 935.536C258.593 930.66 257.843 921.282 257.843 921.282C257.843 921.282 248.465 919.594 243.214 924.377C237.963 929.159 238.713 938.724 238.713 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 938.724C291.322 938.724 281.945 940.412 276.693 935.536C271.442 930.66 272.099 921.282 272.099 921.282C272.099 921.282 281.476 919.594 286.727 924.377C291.979 929.159 291.322 938.724 291.322 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 938.724C283.726 938.724 293.104 940.412 298.355 935.536C303.607 930.66 302.857 921.282 302.857 921.282C302.857 921.282 293.479 919.594 288.228 924.377C282.976 929.159 283.726 938.724 283.726 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 938.724C336.334 938.724 326.957 940.412 321.705 935.536C316.454 930.66 317.11 921.282 317.11 921.282C317.11 921.282 326.488 919.594 331.739 924.377C336.99 929.159 336.334 938.724 336.334 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 938.724C328.738 938.724 338.116 940.412 343.367 935.536C348.618 930.66 347.868 921.282 347.868 921.282C347.868 921.282 338.491 919.594 333.239 924.377C327.988 929.159 328.738 938.724 328.738 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 938.724C381.346 938.724 371.968 940.412 366.717 935.536C361.466 930.66 362.122 921.282 362.122 921.282C362.122 921.282 371.499 919.594 376.751 924.377C382.002 929.159 381.346 938.724 381.346 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 938.724C373.75 938.724 383.127 940.412 388.379 935.536C393.63 930.66 392.88 921.282 392.88 921.282C392.88 921.282 383.503 919.594 378.251 924.377C373 929.159 373.75 938.724 373.75 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 938.724C426.358 938.724 416.98 940.412 411.729 935.536C406.477 930.66 407.134 921.282 407.134 921.282C407.134 921.282 416.511 919.594 421.763 924.377C427.014 929.159 426.358 938.724 426.358 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 938.724C418.762 938.724 428.139 940.412 433.391 935.536C438.642 930.66 437.892 921.282 437.892 921.282C437.892 921.282 428.514 919.594 423.263 924.377C418.011 929.159 418.762 938.724 418.762 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 938.724C471.369 938.724 461.992 940.412 456.74 935.536C451.489 930.66 452.145 921.282 452.145 921.282C452.145 921.282 461.523 919.594 466.774 924.377C472.026 929.159 471.369 938.724 471.369 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 938.724C463.773 938.724 473.151 940.412 478.402 935.536C483.654 930.66 482.903 921.282 482.903 921.282C482.903 921.282 473.526 919.594 468.275 924.377C463.023 929.159 463.773 938.724 463.773 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 938.724C516.381 938.724 507.003 940.412 501.752 935.536C496.501 930.66 497.157 921.282 497.157 921.282C497.157 921.282 506.535 919.594 511.786 924.377C517.037 929.159 516.381 938.724 516.381 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 938.724C508.785 938.724 518.163 940.412 523.414 935.536C528.665 930.66 527.915 921.282 527.915 921.282C527.915 921.282 518.538 919.594 513.286 924.377C508.035 929.159 508.785 938.724 508.785 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 938.724C561.393 938.724 552.015 940.412 546.764 935.536C541.512 930.66 542.169 921.282 542.169 921.282C542.169 921.282 551.546 919.594 556.798 924.377C562.049 929.159 561.393 938.724 561.393 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 938.724C553.797 938.724 563.174 940.412 568.426 935.536C573.677 930.66 572.927 921.282 572.927 921.282C572.927 921.282 563.549 919.594 558.298 924.377C553.047 929.159 553.797 938.724 553.797 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 938.724C606.404 938.724 597.027 940.412 591.776 935.536C586.524 930.66 587.181 921.282 587.181 921.282C587.181 921.282 596.558 919.594 601.809 924.377C607.061 929.159 606.404 938.724 606.404 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 938.724C598.809 938.724 608.186 940.412 613.437 935.536C618.689 930.66 617.939 921.282 617.939 921.282C617.939 921.282 608.561 919.594 603.31 924.377C598.058 929.159 598.809 938.724 598.809 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 938.724C651.416 938.724 642.039 940.412 636.787 935.536C631.536 930.66 632.192 921.282 632.192 921.282C632.192 921.282 641.57 919.594 646.821 924.377C652.073 929.159 651.416 938.724 651.416 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 938.724C643.82 938.724 653.198 940.412 658.449 935.536C663.701 930.66 662.95 921.282 662.95 921.282C662.95 921.282 653.573 919.594 648.321 924.377C643.07 929.159 643.82 938.724 643.82 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 938.724C696.428 938.724 687.05 940.412 681.799 935.536C676.548 930.66 677.204 921.282 677.204 921.282C677.204 921.282 686.581 919.594 691.833 924.377C697.084 929.159 696.428 938.724 696.428 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 938.724C688.832 938.724 698.209 940.412 703.461 935.536C708.712 930.66 707.962 921.282 707.962 921.282C707.962 921.282 698.585 919.594 693.333 924.377C688.082 929.159 688.832 938.724 688.832 938.724Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 971.546C21.2501 971.546 11.8726 973.234 6.62122 968.357C1.36983 963.481 2.02626 954.103 2.02626 954.103C2.02626 954.103 11.4037 952.416 16.6551 957.198C21.9065 961.981 21.2501 971.546 21.2501 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 971.546C13.6542 971.546 23.0317 973.234 28.2831 968.357C33.5345 963.481 32.7843 954.103 32.7843 954.103C32.7843 954.103 23.4068 952.416 18.1554 957.198C12.904 961.981 13.6542 971.546 13.6542 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 971.546C66.2618 971.546 56.8843 973.234 51.6329 968.357C46.3816 963.481 47.038 954.103 47.038 954.103C47.038 954.103 56.4155 952.416 61.6668 957.198C66.9182 961.981 66.2618 971.546 66.2618 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 971.546C58.666 971.546 68.0434 973.234 73.2948 968.357C78.5462 963.481 77.796 954.103 77.796 954.103C77.796 954.103 68.4185 952.416 63.1671 957.198C57.9158 961.981 58.666 971.546 58.666 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 971.546C111.274 971.546 101.896 973.234 96.6447 968.357C91.3933 963.481 92.0497 954.103 92.0497 954.103C92.0497 954.103 101.427 952.416 106.679 957.198C111.93 961.981 111.274 971.546 111.274 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 971.546C103.678 971.546 113.055 973.234 118.307 968.357C123.558 963.481 122.808 954.103 122.808 954.103C122.808 954.103 113.43 952.416 108.179 957.198C102.927 961.981 103.678 971.546 103.678 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 971.546C156.285 971.546 146.908 973.234 141.656 968.357C136.405 963.481 137.061 954.103 137.061 954.103C137.061 954.103 146.439 952.416 151.69 957.198C156.942 961.981 156.285 971.546 156.285 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 971.546C148.689 971.546 158.067 973.234 163.318 968.357C168.57 963.481 167.819 954.103 167.819 954.103C167.819 954.103 158.442 952.416 153.191 957.198C147.939 961.981 148.689 971.546 148.689 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 971.546C201.297 971.546 191.919 973.234 186.668 968.357C181.417 963.481 182.073 954.103 182.073 954.103C182.073 954.103 191.451 952.416 196.702 957.198C201.953 961.981 201.297 971.546 201.297 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 971.546C193.701 971.546 203.079 973.234 208.33 968.357C213.581 963.481 212.831 954.103 212.831 954.103C212.831 954.103 203.454 952.416 198.202 957.198C192.951 961.981 193.701 971.546 193.701 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 971.546C246.309 971.546 236.931 973.234 231.68 968.357C226.428 963.481 227.085 954.103 227.085 954.103C227.085 954.103 236.462 952.416 241.714 957.198C246.965 961.981 246.309 971.546 246.309 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 971.546C238.713 971.546 248.09 973.234 253.342 968.357C258.593 963.481 257.843 954.103 257.843 954.103C257.843 954.103 248.465 952.416 243.214 957.198C237.963 961.981 238.713 971.546 238.713 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 971.546C291.322 971.546 281.945 973.234 276.693 968.357C271.442 963.481 272.099 954.103 272.099 954.103C272.099 954.103 281.476 952.416 286.727 957.198C291.979 961.981 291.322 971.546 291.322 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 971.546C283.726 971.546 293.104 973.234 298.355 968.357C303.607 963.481 302.857 954.103 302.857 954.103C302.857 954.103 293.479 952.416 288.228 957.198C282.976 961.981 283.726 971.546 283.726 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 971.546C336.334 971.546 326.957 973.234 321.705 968.357C316.454 963.481 317.11 954.103 317.11 954.103C317.11 954.103 326.488 952.416 331.739 957.198C336.99 961.981 336.334 971.546 336.334 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 971.546C328.738 971.546 338.116 973.234 343.367 968.357C348.618 963.481 347.868 954.103 347.868 954.103C347.868 954.103 338.491 952.416 333.239 957.198C327.988 961.981 328.738 971.546 328.738 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 971.546C381.346 971.546 371.968 973.234 366.717 968.357C361.466 963.481 362.122 954.103 362.122 954.103C362.122 954.103 371.499 952.416 376.751 957.198C382.002 961.981 381.346 971.546 381.346 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 971.546C373.75 971.546 383.127 973.234 388.379 968.357C393.63 963.481 392.88 954.103 392.88 954.103C392.88 954.103 383.503 952.416 378.251 957.198C373 961.981 373.75 971.546 373.75 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 971.546C426.358 971.546 416.98 973.234 411.729 968.357C406.477 963.481 407.134 954.103 407.134 954.103C407.134 954.103 416.511 952.416 421.763 957.198C427.014 961.981 426.358 971.546 426.358 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 971.546C418.762 971.546 428.139 973.234 433.391 968.357C438.642 963.481 437.892 954.103 437.892 954.103C437.892 954.103 428.514 952.416 423.263 957.198C418.011 961.981 418.762 971.546 418.762 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 971.546C471.369 971.546 461.992 973.234 456.74 968.357C451.489 963.481 452.145 954.103 452.145 954.103C452.145 954.103 461.523 952.416 466.774 957.198C472.026 961.981 471.369 971.546 471.369 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 971.546C463.773 971.546 473.151 973.234 478.402 968.357C483.654 963.481 482.903 954.103 482.903 954.103C482.903 954.103 473.526 952.416 468.275 957.198C463.023 961.981 463.773 971.546 463.773 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 971.546C516.381 971.546 507.003 973.234 501.752 968.357C496.501 963.481 497.157 954.103 497.157 954.103C497.157 954.103 506.535 952.416 511.786 957.198C517.037 961.981 516.381 971.546 516.381 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 971.546C508.785 971.546 518.163 973.234 523.414 968.357C528.665 963.481 527.915 954.103 527.915 954.103C527.915 954.103 518.538 952.416 513.286 957.198C508.035 961.981 508.785 971.546 508.785 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 971.546C561.393 971.546 552.015 973.234 546.764 968.357C541.512 963.481 542.169 954.103 542.169 954.103C542.169 954.103 551.546 952.416 556.798 957.198C562.049 961.981 561.393 971.546 561.393 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 971.546C553.797 971.546 563.174 973.234 568.426 968.357C573.677 963.481 572.927 954.103 572.927 954.103C572.927 954.103 563.549 952.416 558.298 957.198C553.047 961.981 553.797 971.546 553.797 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 971.546C606.404 971.546 597.027 973.234 591.776 968.357C586.524 963.481 587.181 954.103 587.181 954.103C587.181 954.103 596.558 952.416 601.809 957.198C607.061 961.981 606.404 971.546 606.404 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 971.546C598.809 971.546 608.186 973.234 613.437 968.357C618.689 963.481 617.939 954.103 617.939 954.103C617.939 954.103 608.561 952.416 603.31 957.198C598.058 961.981 598.809 971.546 598.809 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 971.546C651.416 971.546 642.039 973.234 636.787 968.357C631.536 963.481 632.192 954.103 632.192 954.103C632.192 954.103 641.57 952.416 646.821 957.198C652.073 961.981 651.416 971.546 651.416 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 971.546C643.82 971.546 653.198 973.234 658.449 968.357C663.701 963.481 662.95 954.103 662.95 954.103C662.95 954.103 653.573 952.416 648.321 957.198C643.07 961.981 643.82 971.546 643.82 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 971.546C696.428 971.546 687.05 973.234 681.799 968.357C676.548 963.481 677.204 954.103 677.204 954.103C677.204 954.103 686.581 952.416 691.833 957.198C697.084 961.981 696.428 971.546 696.428 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 971.546C688.832 971.546 698.209 973.234 703.461 968.357C708.712 963.481 707.962 954.103 707.962 954.103C707.962 954.103 698.585 952.416 693.333 957.198C688.082 961.981 688.832 971.546 688.832 971.546Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 1004.37C21.2501 1004.37 11.8726 1006.05 6.62122 1001.18C1.36983 996.302 2.02626 986.925 2.02626 986.925C2.02626 986.925 11.4037 985.237 16.6551 990.019C21.9065 994.802 21.2501 1004.37 21.2501 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 1004.37C13.6542 1004.37 23.0317 1006.05 28.2831 1001.18C33.5345 996.302 32.7843 986.925 32.7843 986.925C32.7843 986.925 23.4068 985.237 18.1554 990.019C12.904 994.802 13.6542 1004.37 13.6542 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 1004.37C66.2618 1004.37 56.8843 1006.05 51.6329 1001.18C46.3816 996.302 47.038 986.925 47.038 986.925C47.038 986.925 56.4155 985.237 61.6668 990.019C66.9182 994.802 66.2618 1004.37 66.2618 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 1004.37C58.666 1004.37 68.0434 1006.05 73.2948 1001.18C78.5462 996.302 77.796 986.925 77.796 986.925C77.796 986.925 68.4185 985.237 63.1671 990.019C57.9158 994.802 58.666 1004.37 58.666 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 1004.37C111.274 1004.37 101.896 1006.05 96.6447 1001.18C91.3933 996.302 92.0497 986.925 92.0497 986.925C92.0497 986.925 101.427 985.237 106.679 990.019C111.93 994.802 111.274 1004.37 111.274 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 1004.37C103.678 1004.37 113.055 1006.05 118.307 1001.18C123.558 996.302 122.808 986.925 122.808 986.925C122.808 986.925 113.43 985.237 108.179 990.019C102.927 994.802 103.678 1004.37 103.678 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 1004.37C156.285 1004.37 146.908 1006.05 141.656 1001.18C136.405 996.302 137.061 986.925 137.061 986.925C137.061 986.925 146.439 985.237 151.69 990.019C156.942 994.802 156.285 1004.37 156.285 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 1004.37C148.689 1004.37 158.067 1006.05 163.318 1001.18C168.57 996.302 167.819 986.925 167.819 986.925C167.819 986.925 158.442 985.237 153.191 990.019C147.939 994.802 148.689 1004.37 148.689 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 1004.37C201.297 1004.37 191.919 1006.05 186.668 1001.18C181.417 996.302 182.073 986.925 182.073 986.925C182.073 986.925 191.451 985.237 196.702 990.019C201.953 994.802 201.297 1004.37 201.297 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 1004.37C193.701 1004.37 203.079 1006.05 208.33 1001.18C213.581 996.302 212.831 986.925 212.831 986.925C212.831 986.925 203.454 985.237 198.202 990.019C192.951 994.802 193.701 1004.37 193.701 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 1004.37C246.309 1004.37 236.931 1006.05 231.68 1001.18C226.428 996.302 227.085 986.925 227.085 986.925C227.085 986.925 236.462 985.237 241.714 990.019C246.965 994.802 246.309 1004.37 246.309 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 1004.37C238.713 1004.37 248.09 1006.05 253.342 1001.18C258.593 996.302 257.843 986.925 257.843 986.925C257.843 986.925 248.465 985.237 243.214 990.019C237.963 994.802 238.713 1004.37 238.713 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 1004.37C291.322 1004.37 281.945 1006.05 276.693 1001.18C271.442 996.302 272.099 986.925 272.099 986.925C272.099 986.925 281.476 985.237 286.727 990.019C291.979 994.802 291.322 1004.37 291.322 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 1004.37C283.726 1004.37 293.104 1006.05 298.355 1001.18C303.607 996.302 302.857 986.925 302.857 986.925C302.857 986.925 293.479 985.237 288.228 990.019C282.976 994.802 283.726 1004.37 283.726 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 1004.37C336.334 1004.37 326.957 1006.05 321.705 1001.18C316.454 996.302 317.11 986.925 317.11 986.925C317.11 986.925 326.488 985.237 331.739 990.019C336.99 994.802 336.334 1004.37 336.334 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 1004.37C328.738 1004.37 338.116 1006.05 343.367 1001.18C348.618 996.302 347.868 986.925 347.868 986.925C347.868 986.925 338.491 985.237 333.239 990.019C327.988 994.802 328.738 1004.37 328.738 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 1004.37C381.346 1004.37 371.968 1006.05 366.717 1001.18C361.466 996.302 362.122 986.925 362.122 986.925C362.122 986.925 371.499 985.237 376.751 990.019C382.002 994.802 381.346 1004.37 381.346 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 1004.37C373.75 1004.37 383.127 1006.05 388.379 1001.18C393.63 996.302 392.88 986.925 392.88 986.925C392.88 986.925 383.503 985.237 378.251 990.019C373 994.802 373.75 1004.37 373.75 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 1004.37C426.358 1004.37 416.98 1006.05 411.729 1001.18C406.477 996.302 407.134 986.925 407.134 986.925C407.134 986.925 416.511 985.237 421.763 990.019C427.014 994.802 426.358 1004.37 426.358 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 1004.37C418.762 1004.37 428.139 1006.05 433.391 1001.18C438.642 996.302 437.892 986.925 437.892 986.925C437.892 986.925 428.514 985.237 423.263 990.019C418.011 994.802 418.762 1004.37 418.762 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 1004.37C471.369 1004.37 461.992 1006.05 456.74 1001.18C451.489 996.302 452.145 986.925 452.145 986.925C452.145 986.925 461.523 985.237 466.774 990.019C472.026 994.802 471.369 1004.37 471.369 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 1004.37C463.773 1004.37 473.151 1006.05 478.402 1001.18C483.654 996.302 482.903 986.925 482.903 986.925C482.903 986.925 473.526 985.237 468.275 990.019C463.023 994.802 463.773 1004.37 463.773 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 1004.37C516.381 1004.37 507.003 1006.05 501.752 1001.18C496.501 996.302 497.157 986.925 497.157 986.925C497.157 986.925 506.535 985.237 511.786 990.019C517.037 994.802 516.381 1004.37 516.381 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 1004.37C508.785 1004.37 518.163 1006.05 523.414 1001.18C528.665 996.302 527.915 986.925 527.915 986.925C527.915 986.925 518.538 985.237 513.286 990.019C508.035 994.802 508.785 1004.37 508.785 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 1004.37C561.393 1004.37 552.015 1006.05 546.764 1001.18C541.512 996.302 542.169 986.925 542.169 986.925C542.169 986.925 551.546 985.237 556.798 990.019C562.049 994.802 561.393 1004.37 561.393 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 1004.37C553.797 1004.37 563.174 1006.05 568.426 1001.18C573.677 996.302 572.927 986.925 572.927 986.925C572.927 986.925 563.549 985.237 558.298 990.019C553.047 994.802 553.797 1004.37 553.797 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 1004.37C606.404 1004.37 597.027 1006.05 591.776 1001.18C586.524 996.302 587.181 986.925 587.181 986.925C587.181 986.925 596.558 985.237 601.809 990.019C607.061 994.802 606.404 1004.37 606.404 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 1004.37C598.809 1004.37 608.186 1006.05 613.437 1001.18C618.689 996.302 617.939 986.925 617.939 986.925C617.939 986.925 608.561 985.237 603.31 990.019C598.058 994.802 598.809 1004.37 598.809 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 1004.37C651.416 1004.37 642.039 1006.05 636.787 1001.18C631.536 996.302 632.192 986.925 632.192 986.925C632.192 986.925 641.57 985.237 646.821 990.019C652.073 994.802 651.416 1004.37 651.416 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 1004.37C643.82 1004.37 653.198 1006.05 658.449 1001.18C663.701 996.302 662.95 986.925 662.95 986.925C662.95 986.925 653.573 985.237 648.321 990.019C643.07 994.802 643.82 1004.37 643.82 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 1004.37C696.428 1004.37 687.05 1006.05 681.799 1001.18C676.548 996.302 677.204 986.925 677.204 986.925C677.204 986.925 686.581 985.237 691.833 990.019C697.084 994.802 696.428 1004.37 696.428 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 1004.37C688.832 1004.37 698.209 1006.05 703.461 1001.18C708.712 996.302 707.962 986.925 707.962 986.925C707.962 986.925 698.585 985.237 693.333 990.019C688.082 994.802 688.832 1004.37 688.832 1004.37Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M21.2501 1037.19C21.2501 1037.19 11.8726 1038.88 6.62122 1034C1.36983 1029.12 2.02626 1019.75 2.02626 1019.75C2.02626 1019.75 11.4037 1018.06 16.6551 1022.84C21.9065 1027.62 21.2501 1037.19 21.2501 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M13.6542 1037.19C13.6542 1037.19 23.0317 1038.88 28.2831 1034C33.5345 1029.12 32.7843 1019.75 32.7843 1019.75C32.7843 1019.75 23.4068 1018.06 18.1554 1022.84C12.904 1027.62 13.6542 1037.19 13.6542 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M66.2618 1037.19C66.2618 1037.19 56.8843 1038.88 51.6329 1034C46.3816 1029.12 47.038 1019.75 47.038 1019.75C47.038 1019.75 56.4155 1018.06 61.6668 1022.84C66.9182 1027.62 66.2618 1037.19 66.2618 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M58.666 1037.19C58.666 1037.19 68.0434 1038.88 73.2948 1034C78.5462 1029.12 77.796 1019.75 77.796 1019.75C77.796 1019.75 68.4185 1018.06 63.1671 1022.84C57.9158 1027.62 58.666 1037.19 58.666 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M111.274 1037.19C111.274 1037.19 101.896 1038.88 96.6447 1034C91.3933 1029.12 92.0497 1019.75 92.0497 1019.75C92.0497 1019.75 101.427 1018.06 106.679 1022.84C111.93 1027.62 111.274 1037.19 111.274 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M103.678 1037.19C103.678 1037.19 113.055 1038.88 118.307 1034C123.558 1029.12 122.808 1019.75 122.808 1019.75C122.808 1019.75 113.43 1018.06 108.179 1022.84C102.927 1027.62 103.678 1037.19 103.678 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M156.285 1037.19C156.285 1037.19 146.908 1038.88 141.656 1034C136.405 1029.12 137.061 1019.75 137.061 1019.75C137.061 1019.75 146.439 1018.06 151.69 1022.84C156.942 1027.62 156.285 1037.19 156.285 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M148.689 1037.19C148.689 1037.19 158.067 1038.88 163.318 1034C168.57 1029.12 167.819 1019.75 167.819 1019.75C167.819 1019.75 158.442 1018.06 153.191 1022.84C147.939 1027.62 148.689 1037.19 148.689 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M201.297 1037.19C201.297 1037.19 191.919 1038.88 186.668 1034C181.417 1029.12 182.073 1019.75 182.073 1019.75C182.073 1019.75 191.451 1018.06 196.702 1022.84C201.953 1027.62 201.297 1037.19 201.297 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M193.701 1037.19C193.701 1037.19 203.079 1038.88 208.33 1034C213.581 1029.12 212.831 1019.75 212.831 1019.75C212.831 1019.75 203.454 1018.06 198.202 1022.84C192.951 1027.62 193.701 1037.19 193.701 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M246.309 1037.19C246.309 1037.19 236.931 1038.88 231.68 1034C226.428 1029.12 227.085 1019.75 227.085 1019.75C227.085 1019.75 236.462 1018.06 241.714 1022.84C246.965 1027.62 246.309 1037.19 246.309 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M238.713 1037.19C238.713 1037.19 248.09 1038.88 253.342 1034C258.593 1029.12 257.843 1019.75 257.843 1019.75C257.843 1019.75 248.465 1018.06 243.214 1022.84C237.963 1027.62 238.713 1037.19 238.713 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M291.322 1037.19C291.322 1037.19 281.945 1038.88 276.693 1034C271.442 1029.12 272.099 1019.75 272.099 1019.75C272.099 1019.75 281.476 1018.06 286.727 1022.84C291.979 1027.62 291.322 1037.19 291.322 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M283.726 1037.19C283.726 1037.19 293.104 1038.88 298.355 1034C303.607 1029.12 302.857 1019.75 302.857 1019.75C302.857 1019.75 293.479 1018.06 288.228 1022.84C282.976 1027.62 283.726 1037.19 283.726 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M336.334 1037.19C336.334 1037.19 326.957 1038.88 321.705 1034C316.454 1029.12 317.11 1019.75 317.11 1019.75C317.11 1019.75 326.488 1018.06 331.739 1022.84C336.99 1027.62 336.334 1037.19 336.334 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M328.738 1037.19C328.738 1037.19 338.116 1038.88 343.367 1034C348.618 1029.12 347.868 1019.75 347.868 1019.75C347.868 1019.75 338.491 1018.06 333.239 1022.84C327.988 1027.62 328.738 1037.19 328.738 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M381.346 1037.19C381.346 1037.19 371.968 1038.88 366.717 1034C361.466 1029.12 362.122 1019.75 362.122 1019.75C362.122 1019.75 371.499 1018.06 376.751 1022.84C382.002 1027.62 381.346 1037.19 381.346 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M373.75 1037.19C373.75 1037.19 383.127 1038.88 388.379 1034C393.63 1029.12 392.88 1019.75 392.88 1019.75C392.88 1019.75 383.503 1018.06 378.251 1022.84C373 1027.62 373.75 1037.19 373.75 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M426.358 1037.19C426.358 1037.19 416.98 1038.88 411.729 1034C406.477 1029.12 407.134 1019.75 407.134 1019.75C407.134 1019.75 416.511 1018.06 421.763 1022.84C427.014 1027.62 426.358 1037.19 426.358 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M418.762 1037.19C418.762 1037.19 428.139 1038.88 433.391 1034C438.642 1029.12 437.892 1019.75 437.892 1019.75C437.892 1019.75 428.514 1018.06 423.263 1022.84C418.011 1027.62 418.762 1037.19 418.762 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M471.369 1037.19C471.369 1037.19 461.992 1038.88 456.74 1034C451.489 1029.12 452.145 1019.75 452.145 1019.75C452.145 1019.75 461.523 1018.06 466.774 1022.84C472.026 1027.62 471.369 1037.19 471.369 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M463.773 1037.19C463.773 1037.19 473.151 1038.88 478.402 1034C483.654 1029.12 482.903 1019.75 482.903 1019.75C482.903 1019.75 473.526 1018.06 468.275 1022.84C463.023 1027.62 463.773 1037.19 463.773 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M516.381 1037.19C516.381 1037.19 507.003 1038.88 501.752 1034C496.501 1029.12 497.157 1019.75 497.157 1019.75C497.157 1019.75 506.535 1018.06 511.786 1022.84C517.037 1027.62 516.381 1037.19 516.381 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M508.785 1037.19C508.785 1037.19 518.163 1038.88 523.414 1034C528.665 1029.12 527.915 1019.75 527.915 1019.75C527.915 1019.75 518.538 1018.06 513.286 1022.84C508.035 1027.62 508.785 1037.19 508.785 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M561.393 1037.19C561.393 1037.19 552.015 1038.88 546.764 1034C541.512 1029.12 542.169 1019.75 542.169 1019.75C542.169 1019.75 551.546 1018.06 556.798 1022.84C562.049 1027.62 561.393 1037.19 561.393 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M553.797 1037.19C553.797 1037.19 563.174 1038.88 568.426 1034C573.677 1029.12 572.927 1019.75 572.927 1019.75C572.927 1019.75 563.549 1018.06 558.298 1022.84C553.047 1027.62 553.797 1037.19 553.797 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M606.404 1037.19C606.404 1037.19 597.027 1038.88 591.776 1034C586.524 1029.12 587.181 1019.75 587.181 1019.75C587.181 1019.75 596.558 1018.06 601.809 1022.84C607.061 1027.62 606.404 1037.19 606.404 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M598.809 1037.19C598.809 1037.19 608.186 1038.88 613.437 1034C618.689 1029.12 617.939 1019.75 617.939 1019.75C617.939 1019.75 608.561 1018.06 603.31 1022.84C598.058 1027.62 598.809 1037.19 598.809 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M651.416 1037.19C651.416 1037.19 642.039 1038.88 636.787 1034C631.536 1029.12 632.192 1019.75 632.192 1019.75C632.192 1019.75 641.57 1018.06 646.821 1022.84C652.073 1027.62 651.416 1037.19 651.416 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M643.82 1037.19C643.82 1037.19 653.198 1038.88 658.449 1034C663.701 1029.12 662.95 1019.75 662.95 1019.75C662.95 1019.75 653.573 1018.06 648.321 1022.84C643.07 1027.62 643.82 1037.19 643.82 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M696.428 1037.19C696.428 1037.19 687.05 1038.88 681.799 1034C676.548 1029.12 677.204 1019.75 677.204 1019.75C677.204 1019.75 686.581 1018.06 691.833 1022.84C697.084 1027.62 696.428 1037.19 696.428 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
						<path
							d="M688.832 1037.19C688.832 1037.19 698.209 1038.88 703.461 1034C708.712 1029.12 707.962 1019.75 707.962 1019.75C707.962 1019.75 698.585 1018.06 693.333 1022.84C688.082 1027.62 688.832 1037.19 688.832 1037.19Z"
							stroke="#E5E5E5"
							strokeWidth="4"
						/>
					</g>
				</svg>
			</div>
		</>
	)
}

export default Dialer
